import { Injectable } from '@angular/core';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import { Globals } from '../globals/globals';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from '../database/database.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StorageService } from '../storage/storage.service';
import { SampleBoardsService } from '../helpers/sample-boards.service';
import { PlanService } from '../plan/plan.service';
import { LanguageService } from '../language/language.service';
import { skip } from 'rxjs/operators';
import { AnalyticsService } from '../analytics/analytics.service';
import { NotificationService } from '../notification/notification.service';
import { UserServiceV2 } from '../v2/user/user.service';
import { ActivitiesDataService } from './activities-data/activities-data.service';
// tslint:disable:max-line-length

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  private activityBoardsBS = new BehaviorSubject<ActivityBoard[]>([]);
  activityBoardsObs = this.activityBoardsBS.asObservable();

  private activityBoardsSharedSpaceBS = new BehaviorSubject<ActivityBoard[]>([]);
  activityBoardsSharedSpaceObs = this.activityBoardsSharedSpaceBS.asObservable();

  activityBoards: ActivityBoard[] = [];
  activityBoardsSharedSpace: ActivityBoard[] = [];
  sharedSpacesNotificationCount = 0;
  parentBoard: ActivityBoard = new ActivityBoard();
  activityBoardsSub: Subscription;
  activityBoardsSharedSpaceSub: Subscription;
  selectedActivityBoard: ActivityBoard;
  rootBoard: ActivityBoard;
  sharedSpace: ActivityBoard;
  activityBoardsChangedSub: Subscription;

  constructor(private app: Globals, public databaseService: DatabaseService, public firestore: AngularFirestore,
    public storageService: StorageService, public sampleBoardsService: SampleBoardsService, private analyticsService: AnalyticsService,
    private storage: StorageService, private planService: PlanService, private languageService: LanguageService,
    private notificationService: NotificationService, private userService: UserServiceV2, private activitiesDataService: ActivitiesDataService) {}

  initParentBoard(): ActivityBoard {
    this.parentBoard = this.activityBoards.find(o => o.title === 'root');
    this.rootBoard = this.parentBoard;
    return this.parentBoard;
  }

  loadActivityBoards(): Promise<Array<ActivityBoard>> {
    return new Promise(async (resolve, reject) => {
      this.clearParentBoard();
      this.userService.getUserUid().then(uid => {
        // console.log('[activities.service] uid => ', uid);
        this.activitiesDataService.loadActivitiesBoards(this.app.lang, uid).then(async (boards) => {
          // console.log('[activities.service] boards => ', boards);
          await this.activityBoardsChanged(boards);
          this.watchActivitiesInSharedSpace(uid);
          resolve(boards);
        }).catch(async sampleBoards => {
          // console.log('[activities.service] boards => ', sampleBoards);
          await this.activityBoardsChanged(sampleBoards);
          this.watchActivitiesInSharedSpace(uid);
          reject(sampleBoards);
        }).finally(() => {
          if (this.activityBoardsChangedSub) { this.activityBoardsChangedSub.unsubscribe(); }
          this.activityBoardsChangedSub = this.activitiesDataService.activitiesBoardsChangedBS.subscribe(boards => {
            this.activityBoardsChanged(boards);
          })
        });
      });
    })
  }

  /**
   * Clears the parent board
  */
  clearParentBoard() {
    this.parentBoard = new ActivityBoard();
  }

  watchActivitiesInSharedSpace(uid: string) {
    if (this.activityBoardsSharedSpaceSub || (uid === '')) { return; }
    this.activityBoardsSharedSpaceSub = this.firestore.collection<ActivityBoard>(`shared-spaces/activities/activity-boards`,
      ref => ref.where('membersUid', 'array-contains', uid)).valueChanges().subscribe(boards => {
        this.activityBoardsSharedSpace = boards;
        // console.time('metatag-shared-spaces');
        // this.updateSharedSpacesNotificationCounter();
        // this.refreshSharedSpaceCategoriesNewTag();
        // console.timeEnd('metatag-shared-spaces');
        this.activityBoardsSharedSpaceBS.next(boards);
      });
  }

  /**
   * Updates the share space notification counter
   */
  async updateSharedSpacesNotificationCounter() {
    return new Promise((resolve, reject) => {
      let count = 0;
      for (const board of this.activityBoardsSharedSpace) {
        if (board.shared && (board.isSharedSpace || board.isActivity)
          && !(board.viewedBy && board.viewedBy.includes(this.app.user.uid))) {
          count++;
        }
      }
      this.sharedSpacesNotificationCount = count;
    });
  }

  /**
   * Refreshes the new tag on shared spaces catagories. This is useful to indicate when a new activitiy is
   * created inside a category or even in subcategories.
   */
  async refreshSharedSpaceCategoriesNewTag() {
    return new Promise((resolve, reject) => {
      if (!this.app.user.uid) {
        return;
      }

      this.activityBoardsSharedSpace.filter(board => board.isCategory).forEach(category => {
        category.hasNewContents = false;
      });
      for (const board of this.activityBoardsSharedSpace) {
        if (board.shared && board.isActivity && !(board.viewedBy && board.viewedBy.includes(this.app.user.uid))) {
          let currentBoard = board;
          while (currentBoard && !currentBoard.isSharedSpace) {
            const parentBoard = this.activityBoardsSharedSpace.find(b => b.cards && b.cards.includes(currentBoard._id));
            if (parentBoard) { parentBoard.hasNewContents = true; }
            currentBoard = parentBoard;
          }
        }
      }
    });
  }

  async activityBoardsChanged(activityBoards: ActivityBoard[]) {
    this.activityBoards = activityBoards;
    this.refreshParentBoard();
    this.activityBoardsBS.next(activityBoards);
    // console.log(this.activityBoards);
  }

  refreshActivitiesBoard() {
    this.activityBoardsBS.next(this.activityBoards);
  }

  addCategory(category: string, parentBoard: ActivityBoard): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      const board = new ActivityBoard();
      board.title = category;
      board.isCategory = true;

      return this.databaseService.createActivityBoard(board, parentBoard)
        .then((data) => { resolve(data); })
        .catch((err) => reject(err));
    });
  }

  refreshParentBoard() {
    if (this.parentBoard._id) {
      const parentBoard = this.activityBoards.find(board => board._id === this.parentBoard._id);
      Object.assign(this.parentBoard, parentBoard);
    } else {
      this.initParentBoard();
    }
  }

  setParentBoardById(id: string) {
    const parentBoard = this.activityBoards.find(board => board._id === id);
    if (parentBoard) {
      this.parentBoard = parentBoard;
    }
  }

  setParentBoardByIdAsync(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const parentBoard = this.activityBoards.find(board => board._id === id);
      if (parentBoard) {
        this.parentBoard = parentBoard;
        resolve();
      } else {
        reject();
      }
    });
  }

  getParentBoardId(): string {
    return this.parentBoard._id;
  }

  /**
   * Gets the parent board of a given board. If boardId is not specified, it will return the current parent board.
   * @param boardId Board Id
   * @returns Parent board
   */
  getParentBoard(boardId?: string): ActivityBoard {
    if (boardId) {
      return this.activityBoards.find(board => board.cards && board.cards.includes(boardId));
    } else {
      return this.parentBoard;
    }
  }

  /**
   * Gets all parents linked with of a given board.
   * @param boardId Board Id
   * @returns Parent board
   */
  getBoardParents(boardId: string): ActivityBoard[] {
    return this.activityBoards.filter(board => board.cards && board.cards.includes(boardId));
  }

  getBoardById(boardId: string, deepClone: boolean = false): ActivityBoard {
    const boards = this.getAllBoards();
    const board = boards.find(b => b._id === boardId);
    if (deepClone) {
      return JSON.parse(JSON.stringify(board));
    } else {
      return board;
    }
  }

  getRootBoard() {
    return this.activityBoards.find(board => board.title === 'root');
  }

  getAllBoards() {
    return this.activityBoards.concat(this.activityBoardsSharedSpace);
  }

  getSharedSpaceFromActivity(activity: ActivityBoard) {
    let board = activity;
    while (board && !board.isSharedSpace) {
      const parentBoard = this.activityBoardsSharedSpace.find(b => b.cards && b.cards.includes(board._id));
      board = parentBoard;
    }
    return board;
  }

  getCardsByIds(cardsIds = [], boardsSrc = [], parentBoard = null) {
    let cardsIdInline = '';
    for (const id of cardsIds) {
      cardsIdInline += id + ' ';
    }

    let boards = [];
    if (boardsSrc && boardsSrc.length) {
      boards = boardsSrc;
    } else {
      boards = this.getAllBoards();
    }

    if (parentBoard === null) {
      parentBoard = this.parentBoard;
    }

    const cards = boards.filter(board => cardsIdInline.includes(board._id));

    // const cardsOrdered = [];
    // for (const cardId of cardsIds) {
    //   cards.forEach(card => {
    //     if (card && card._id === cardId) { cardsOrdered.push(card); }
    //   });
    // }

    // return cardsOrdered;

    let cardsOrdered = new Array<ActivityBoard>(cardsIds.length);
    const cardsWithoutOrder = [];
    cardsIds.forEach(cardId => {
      const card = cards.find(c => c._id === cardId);
      if (card !== undefined) {
        if (parentBoard?.cardsOrder) {
          const index = parentBoard.cardsOrder.findIndex(id => id === cardId);
          if (index >= 0 && cardsOrdered[index] === undefined) {
            cardsOrdered[index] = card;
          } else {
            cardsWithoutOrder.push(card);
          }
        } else {
          cardsOrdered.push(card);
        }
      }
    });

    // Remove eventuais posicoes vazias do array
    cardsOrdered = cardsOrdered.filter(card => card !== undefined);

    return cardsOrdered.concat(cardsWithoutOrder);
  }

  addActivitiesToSharedSpace(parentBoard: ActivityBoard, activities: ActivityBoard[], boardRoutes = []) {
    // this.planService.setfreeSlotsAvailable(freeSlotsAvailable);
    // if (this.plan.freeSlotsAvailable - activities.length)

    const batch = this.firestore.firestore.batch();

    for (let activity of activities) {
      const cards = JSON.parse(JSON.stringify(this.getCardsByIds(activity.cards)));
      activity = JSON.parse(JSON.stringify(activity));

      const newCardsIds = [];
      for (const card of cards) {
        card._id = this.firestore.createId();
        card.shared = true;
        card.membersUid = parentBoard.membersUid;
        card.viewedBy = [this.app.user.uid];
        card.createdAt = Date.now();
        card.updatedAt = Date.now();
        card.owner = this.app.user.uid;
        newCardsIds.push(card._id);

        batch.set(this.firestore.firestore.collection(`shared-spaces/activities/activity-boards`).doc(card._id), card, { merge: true });
      }

      activity._id = this.firestore.createId();
      activity.cards = newCardsIds;
      activity.shared = true;
      activity.membersUid = parentBoard.membersUid;
      activity.viewedBy = [this.app.user.uid];
      activity.createdAt = Date.now();
      activity.updatedAt = Date.now();
      activity.owner = this.app.user.uid;
      activity.sharePlay = 0;
      activity.shareSave = 0;
      batch.set(this.firestore.firestore.collection(`shared-spaces/activities/activity-boards`).doc(activity._id), activity, { merge: true });

      batch.update(this.firestore.firestore.collection(`shared-spaces/activities/activity-boards`)
        .doc(parentBoard._id), { cards: this.app.firebaseArrayUnion(activity._id) });

      this.notificationService.newSharedSpaceActivity(activity._id, boardRoutes);
    }

    batch.commit();

    this.analyticsService.logEvent('activities_attach', { ids: activities.map(activity => activity._id), count: activities.map(activity => activity._id).length }, 'count');
  }

  copyActivity(activity: ActivityBoard, parentBoard: ActivityBoard, boardRoutes = []) {
    const batch = this.firestore.firestore.batch();

    const cards = JSON.parse(JSON.stringify(this.getCardsByIds(activity.cards)));
    activity = JSON.parse(JSON.stringify(activity));

    const newCardsIds = [];
    for (const card of cards) {
      card._id = this.firestore.createId();
      card.shared = true;
      card.membersUid = parentBoard.membersUid;
      card.viewedBy = [this.app.user.uid];
      card.createdAt = Date.now();
      card.updatedAt = Date.now();
      card.owner = this.app.user.uid;
      newCardsIds.push(card._id);

      batch.set(this.firestore.firestore.collection(`shared-spaces/activities/activity-boards`).doc(card._id), card, { merge: true });
    }

    activity._id = this.firestore.createId();
    activity.cards = newCardsIds;
    activity.shared = true;
    activity.membersUid = parentBoard.membersUid;
    activity.viewedBy = [this.app.user.uid];
    activity.createdAt = Date.now();
    activity.updatedAt = Date.now();
    activity.owner = this.app.user.uid;
    activity.title = `Cópia de ${activity.title}`;
    batch.set(this.firestore.firestore.collection(`shared-spaces/activities/activity-boards`).doc(activity._id), activity, { merge: true });

    batch.update(this.firestore.firestore.collection(`shared-spaces/activities/activity-boards`)
      .doc(parentBoard._id), { cards: this.app.firebaseArrayUnion(activity._id) });

    batch.commit();

    this.notificationService.newSharedSpaceActivity(activity._id, boardRoutes);
  }

  /**
   * Lock activities that are beyond the free quota limits
   * @param boards Activity boards
   * @returns freeSlotsAvailable Number of slots available
   */
  lockActivitiesBeyondFreeQuota(cards: ActivityBoard[], sharedSpace?: ActivityBoard) {

    // Free slots for user to save/edit activities
    let freeSlotsAvailable = 3;

    if (sharedSpace) { sharedSpace.sharedActivitiesQuotaExceeded = false; }

    // The lock only apply if the plan lock is enabled for the user and if the user doesn't have a
    // subscription (cannot access all activities)
    if (!this.planService.hasFullAccess()) {

      let boards = [];

      const rootBoard = this.activityBoards.find(o => o.title === 'root');
      const rootCards = rootBoard ? this.getCardsByIds(rootBoard.cards) : [];

      for (const board of rootCards) {
        if (board.isCategory) {
          let innerBoards = [];
          this.discoveryBoards(board._id, this.activityBoards, innerBoards);
          innerBoards = innerBoards.filter(b => b.isActivity);
          boards.push(board);
          boards = boards.concat(innerBoards);
        } else {
          boards.push(board);
        }
      }

      let contentRestrictionType = [];
      // Loop through the activities to categorize restriction types
      for (const board of boards) {
        if (board.shared) {
          contentRestrictionType.push('unlockBoard');
        } else if (board.isActivity) {
          // If the activity was created or edited in some ways, it is left accessible only if there is a slot available.
          // Note: When an activity is created, the updateAt field is also set to the creation time.
          if (board.updatedAt) {
            if (freeSlotsAvailable > 0) {
              freeSlotsAvailable--;
              contentRestrictionType.push('unlockBoard');
              //this.unlockBoard(board);
            } else {
              contentRestrictionType.push('lockBoard');
              //this.lockBoard(board);
            }
          } else {
            // Sample board is fixed on freemium plan until free slots limit is reached
            contentRestrictionType.push('freemium-fixBoard');
            //this.setReadOnlyBoard(board);
          }
        } else if (board.isCategory) {
          contentRestrictionType.push('unlockBoard');
          //this.unlockBoard(board);
        } else {
          // Cards are read only
          contentRestrictionType.push('setReadOnlyBoard');
          //this.setReadOnlyBoard(board);
        }
      }

      // Loop to apply the restrictions according to free slots available
      contentRestrictionType.forEach((value, i) => {
        switch (value) {
          case 'lockBoard':
            this.lockBoard(boards[i]);
            break;
          case 'unlockBoard':
            this.unlockBoard(boards[i]);
            break;
          case 'setReadOnlyBoard':
            this.setReadOnlyBoard(boards[i]);
            break;
          case 'freemium-fixBoard':
            if (freeSlotsAvailable <= 0) {
              this.setReadOnlyBoard(boards[i]); // Read only if freemium limit exceeded
            } else {
              this.fixBoard(boards[i]); // Fixed within freemium level
            }
            break;
        }
      });

      if (sharedSpace) {
        let sharedActivitiesQuotaCount = 0;
        let sharedSpaceBoards = [];
        this.discoveryBoards(sharedSpace._id, this.activityBoardsSharedSpace, sharedSpaceBoards);
        sharedSpaceBoards = sharedSpaceBoards.filter(b => b.owner === this.app.user.uid).concat(
          sharedSpaceBoards.filter(b => b.owner !== this.app.user.uid));
        for (const sharedSpaceBoard of sharedSpaceBoards) {
          if (sharedSpaceBoard.owner === this.app.user.uid) {
            if (sharedSpaceBoard.isActivity && sharedActivitiesQuotaCount < 1) {
              sharedActivitiesQuotaCount++;
              this.unlockBoard(sharedSpaceBoard);
              sharedSpace.sharedActivitiesQuotaExceeded = true;
            } else if (sharedSpaceBoard.isActivity && sharedSpace.sharedActivitiesQuotaExceeded) {
              this.lockBoard(sharedSpaceBoard);
            } else if (sharedSpaceBoard.isCategory) {
              this.unlockBoard(sharedSpaceBoard);
            } else {
              this.setReadOnlyBoard(sharedSpaceBoard);
            }
          } else if (sharedSpaceBoard.isSharedSpace || !sharedSpace.sharedActivitiesQuotaExceeded) {
            this.unlockBoard(sharedSpaceBoard);
          } else {
            this.setReadOnlyBoard(sharedSpaceBoard);
          }
        }
      }

      // If freemium slots exceeded
      if (freeSlotsAvailable <= 0 || sharedSpace && sharedSpace.sharedActivitiesQuotaExceeded) {
        /**
         * Organizes the activities in this order: Read only, Unlocked and Locked
         */
        const readOnlyBoardsIds = [];
        const unlockedBoardsIds = [];
        const lockedBoardsIds = [];
        const sharedSpacesIds = [];
        const categoriesIds = [];
        const specialCardsAtStartIds = [];
        const specialCardsAtEndIds = [];

        for (const card of cards) {
          const elm = document.getElementById(card._id);
          if (card.isCategory) {
            categoriesIds.push(card._id);
          } else if (card.isSharedSpace) {
            sharedSpacesIds.push(card._id);
          } else if (elm && elm.classList.contains('app-board-locked')) {
            lockedBoardsIds.push(card._id);
          } else if (elm && elm.classList.contains('app-board-unlocked')) {
            unlockedBoardsIds.push(card._id);
          } else if (elm && elm.classList.contains('app-special-card-start')) {
            specialCardsAtStartIds.push(card._id);
          } else if (elm && elm.classList.contains('app-special-card-end')) {
            specialCardsAtEndIds.push(card._id);
          } else if (elm) {
            readOnlyBoardsIds.push(card._id);
          }
        }

        const cardsIdsOrganized = specialCardsAtStartIds.concat(categoriesIds.concat(sharedSpacesIds.concat(readOnlyBoardsIds
          .concat(unlockedBoardsIds.concat(lockedBoardsIds.concat(specialCardsAtEndIds))))));

        for (let i = 0; i < cardsIdsOrganized.length; i++) {
          const index = cards.findIndex(board => board._id === cardsIdsOrganized[i]);
          if (index >= 0 && index !== i) {
            this.arrayMove(cards, index, i);
          }
        }
      }
      this.planService.setfreeSlotsAvailable(freeSlotsAvailable);
    }
    return freeSlotsAvailable;
  }

  discoveryBoards(boardId: string, srcBoards = [], boards = []) {
    const board = srcBoards.find(b => b._id === boardId);

    if (typeof board === 'undefined' || board === null) { return; }

    for (const cardId of board.cards) {
      this.discoveryBoards(cardId, srcBoards, boards);
    }
    boards.push(board);
  }

  arrayMove(arr = [], oldIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  }

  setReadOnlyBoard(board: ActivityBoard) {
    const boardElm = document.getElementById(board._id);
    if (boardElm) {
      boardElm.classList.add('app-board-read-only');
      boardElm.classList.remove('app-board-locked');
      boardElm.classList.remove('app-board-unlocked');
      boardElm.classList.remove('app-board-fixed');
    }
  }

  lockBoard(board: ActivityBoard) {
    const boardElm = document.getElementById(board._id);
    if (boardElm) {
      boardElm.classList.add('app-board-locked');
      boardElm.classList.remove('app-board-unlocked');
      boardElm.classList.remove('app-board-read-only');
      boardElm.classList.remove('app-board-fixed');
    }
  }

  unlockBoard(board: ActivityBoard) {
    const boardElm = document.getElementById(board._id);
    if (boardElm) {
      boardElm.classList.add('app-board-unlocked');
      boardElm.classList.remove('app-board-locked');
      boardElm.classList.remove('app-board-read-only');
      boardElm.classList.remove('app-board-fixed');
    }
  }

  fixBoard(board: ActivityBoard) {
    const boardElm = document.getElementById(board._id);
    if (boardElm) {
      boardElm.classList.add('app-board-fixed');
      boardElm.classList.remove('app-board-unlocked');
      boardElm.classList.remove('app-board-locked');
      boardElm.classList.remove('app-board-read-only');
    }
  }

  isBoardLocked(boardId: string) {
    const boardElm = document.getElementById(boardId);
    if (boardElm) {
      return document.getElementById(boardId).classList.contains('app-board-locked');
    } else {
      return undefined;
    }
  }

  listActivitiesMedias() {
    const medias = [];
    for (const board of this.activityBoards) {
      if (board.img !== '') { medias.push(board.img); }
      if (board.audio !== '') { medias.push(board.audio); }
      if (board.questionImgSrc !== '') { medias.push(board.questionImgSrc); }
      if (board.questionAudio !== '') { medias.push(board.questionAudio); }
    }
    return medias;
  }

  /**
   * Clear the all activities that are inside the app, including activities in shared spaces
   */
  clearActivityBoards() {
    if (this.activityBoardsSub) { this.activityBoardsSub.unsubscribe(); }
    if (this.activityBoardsSharedSpaceSub) { this.activityBoardsSharedSpaceSub.unsubscribe(); }
    this.parentBoard = new ActivityBoard();
    this.activityBoards = [];
    this.activityBoardsSub = null;
    this.activityBoardsBS.next([]);
    this.activityBoardsSharedSpace = [];
    this.activityBoardsSharedSpaceBS.next([]);
    this.activityBoardsSharedSpaceSub = null;
  }

  /**
   * Creates a new activity boards collection with sample boards for a given language
   */
  private createActivityBoardsCollection(lang: string, langSuffix: string) {
    const activityBoardsSample = this.sampleBoardsService.createActivitySampleBoards(lang);

    const batchActivitySample = this.firestore.firestore.batch();

    for (const board of activityBoardsSample) {
      batchActivitySample.set(this.firestore.firestore.collection(`users/${this.app.user.uid}/activity-boards${langSuffix}`)
        .doc(board._id), board);
    }

    batchActivitySample.commit();
    return activityBoardsSample;
  }

  getActivityIdFromParent(index: number): string {
    if (this.parentBoard.cards) {
      return this.parentBoard.cards[index];
    } else {
      return '';
    }
  }

  isBoardInSharedSpace(boardId: string) {
    return this.activityBoardsSharedSpace.findIndex(board => board._id === boardId) >= 0 ? true : false;
  }

  // Salva no navegador os dados da última atividade compartilhada que foi executada
  // Para fazer a atribuição de MGM
  saveLastSharedAtivityPlay(sharedBy: string, activityId: string, sharedLocale: string) {
    var date = new Date();
    const log = {
      timestamp: date.getTime(),
      sharedBy: sharedBy,
      activityId: activityId,
      sharedLocale: sharedLocale
    }
    setTimeout(() => { // Delay para atualizar estado do login
      if (!this.app.isUserLogged()) {
        this.storageService.set(`lastSharedAtivityPlay`, log);
      }
    }, 5000);
  }

  clearNewSharedSpacesTag(sharedSpaces: ActivityBoard[]) {
    const newProps = {
      viewedBy: this.app.firebaseArrayUnion(this.app.user.uid)
    };

    for (const sharedSpace of sharedSpaces) {
      this.databaseService.updateActivityBoardProps(sharedSpace, newProps);
    }
  }

  /**
   * Clears the new tag on recently created activities or categories in a shared spaced
   * @param sharedSpace Shared Space
   */
  clearSharedSpaceNewBoardsTag(sharedSpace: ActivityBoard) {
    let boards = [];
    this.discoveryBoards(sharedSpace._id, this.activityBoardsSharedSpace, boards);
    boards = boards.filter(board => board.isActivity);

    const newProps = {
      viewedBy: this.app.firebaseArrayUnion(this.app.user.uid)
    };

    for (const board of boards) {
      this.databaseService.updateActivityBoardProps(board, newProps);
    }
  }

  canCreateActivity(sharedSpace?: ActivityBoard) {
    if (this.planService.hasFullAccess()) {
      return true;
    } else {
      if (sharedSpace) {
        const activities = this.getActivitiesMadeByUser(sharedSpace);
        return ((1 - activities.length) > 0);
      } else {
        return this.planService.plan.freeSlotsAvailable > 0;
      }
    }
  }

  canCreateCategory() {
    if (this.planService.hasFullAccess()) {
      return true;
    } else {
      return false;
    }
  }

  canAttachActivities(sharedSpace: ActivityBoard, amount: number) {
    if (this.planService.hasFullAccess()) {
      return true;
    } else {
      const activities = this.getActivitiesMadeByUser(sharedSpace);
      if (amount) {
        return (1 - (amount + activities.length) >= 0);
      } else {
        return ((1 - activities.length) > 0);
      }
    }
  }

  getActivitiesMadeByUser(parentBoard: ActivityBoard) {
    const boards: ActivityBoard[] = [];
    const srcBoards = parentBoard.shared ? this.activityBoardsSharedSpace : this.activityBoards;
    this.discoveryBoards(parentBoard._id, srcBoards, boards);
    const activities = boards.filter(board => board.isActivity && board.owner === this.app.user.uid);
    return activities;
  }

  /**
   * Gets all activities boards that are actually part of the current user's set of boards.
   * It's known that activityBoards may contain unlinked boards (that is no longer
   * connected to the user set).
   */
  getOnlyLinkedBoards(rootBoard?: ActivityBoard, activityBoards?: ActivityBoard[]) {
    if (rootBoard) {
      const boards = [];
      this.discoveryBoards(rootBoard._id, activityBoards, boards);
      return boards;
    } else {
      const boards = [];
      this.discoveryBoards(this.getRootBoard()?._id, this.activityBoards, boards);
      return boards;
    }
  }

  /**
   * Moves an activity or category into other category
   * @param board Card or Board to be moved
   * @param oldParent Old board's parent (where the board was in)
   * @param newParent New board's parent (where the board will move to)
   * @returns Promise
   */
  moveActivityBoard(board: ActivityBoard, oldParents: ActivityBoard[], newParent: ActivityBoard) {
    this.databaseService.updateActivityBoard(newParent, { cards: this.app.firebaseArrayUnion(board._id) });
    for (const oldParent of oldParents) {
      this.databaseService.updateActivityBoard(oldParent, { cards: this.app.firebaseArrayRemove(board._id) });
    }
  }

  updateActivityDescription(activity: ActivityBoard, props: any): Promise<any> {
    return this.updateActivity(activity);
  }

  updateActivity(board: ActivityBoard): Promise<any> {
    return this.databaseService.updateActivityBoard(board);
  }

  deleteDescriptionPhoto(activity: ActivityBoard) {
    this.databaseService.deleteDescriptionPhoto(activity, this.app.user);
  }

  filterSearchBoards(searchText: string, srcBoards, filteredBoards = []): any {
    let activities = srcBoards.filter((board) => board.isActivity);

    srcBoards = srcBoards.filter((board) => board._id !== this.parentBoard._id);

    let innerCardsActivities = [];

    for (let board of srcBoards) {
      let boardTitle = board.title.normalize("NFD").replace(/[\u0300-\u036f]/g, '');

      let boardSearchText = "";
      if (board.imgSearchText) {
        boardSearchText = board.imgSearchText.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
      }

      if (boardTitle.toUpperCase().match(searchText) || boardSearchText.toUpperCase().match(searchText)) {
        if (board.isCategory || board.isActivity || board.isContact || board.isSharedSpace || board.isGroup) {
          filteredBoards.push(board);
        } else {
          // Encontra a atividade em que o cartão está
          for (let activity of activities) {
            if (activity.cards.includes(board._id)) innerCardsActivities.push(activity);
          }
        }
      }
    }

    filteredBoards.sort(compare);
    innerCardsActivities.sort(compare);
    filteredBoards = filteredBoards.concat(innerCardsActivities);
    filteredBoards = filteredBoards.filter((item, pos, self) => self.findIndex(o => o.title === item.title) === pos);

    return filteredBoards;

    function compare(a: any, b: any) {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    }

  }
}
