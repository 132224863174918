import { User } from './../user/user.model';
import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AacService } from '../aac/aac.service';
import { ActivitiesService } from '../activities/activities.service';
import { PlanService } from '../plan/plan.service';
import { PlanFeatures } from '../plan/plan-features.model';
import { LanguageService } from '../language/language.service';
import firebase from 'firebase/compat/app';
import { UserService } from '../user/user.service';
import { UserServiceV2 } from '../v2/user/user.service';

@Injectable({
   providedIn: 'root',
})
export class AuthService {

   userDocSub: Subscription;
   authUser: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
   authUserSubject: Subject<firebase.User> = new Subject<firebase.User>();

   // The pending Facebook credential.
   pendingCred: firebase.auth.UserCredential;
   // The provider account's email address.
   pendingEmail = '';
   pendingPwd = '';
   authStateInitiated = false;

   constructor( private globals: Globals, private fireAuth: AngularFireAuth, private firestore: AngularFirestore,
                private navCtrl: NavController, private aacService: AacService, private activityService: ActivitiesService, 
                private app: Globals, private planService: PlanService, public fns: AngularFireFunctions, private lang: LanguageService,
                private userService: UserService, private userServiceV2: UserServiceV2) {

                /**
                * Watch for any changes on app language
                */
                  this.lang.langObs.subscribe((lang) => {
                     this.fireAuth.languageCode = new Promise(() => lang); // Set language used by Firebase Auth Emails
                  });
                }

   initAuthListener() {
      this.fireAuth.authState.subscribe(user => {
         if (user) {
            this.setupUser(user);
         } else {
            this.clearUser();

            if (this.app.isRunningOnNative()) {
               // If the app is running on native app, the user can access it without being logged in. In this case,
               // we create a free plan that allows access to free features.
               this.planService.updatePlan(PlanFeatures.fromSubscriptionId('free'));
            } else if (this.app.isRunningOnBrowser()) {
               if (!window.location.href.includes('shared') && !window.location.href.includes('promo') &&
                   !window.location.href.includes('invites')) {
                  this.navCtrl.navigateRoot('/login');
               }
            }
         }

         // console.log('FIRE AUTH STATE USER => ', user);
         
         this.authUser.next(user);

         // Emitted only when the changes on auth is intended by the user. In other words, skips the
         // first emittion since il will be regarding the auth`s initialization.
         if (this.authStateInitiated) {
            this.authUserSubject.next(user);
         } else {
            this.authStateInitiated = true;
         }
      });
   }

   setupUser(user: firebase.User) {
      // Workaround to ensure that app.user will have the most important data updated as fast as possible
      this.app.user.uid = user.uid;
      this.app.user.name = user.displayName;
      this.app.user.email = user.email;

      this.userServiceV2.loadUser(user.uid).then(userDoc => {
         // As configurações relacionadas a varredura e uso com o TiX são mantidas à atuais do app
         if (userDoc.preferences) {
            userDoc.preferences.useScanMode = false;
            userDoc.preferences.useWithTix = false;
         }

         if (userDoc.name === '' || userDoc.email === '') {
            Object.assign(userDoc, { name: user.displayName, email: user.email});
         }

         this.globals.user = userDoc;
         this.userService.userChanged(userDoc);

         /**
          * Updates the user plan based on the plan features. In the future, the ideia is get this features
          * from the user doc. This way, we will be able to create custom features that may suit specific needs.
          * For now, we are considering fixed features related with our main plans: Activities and Aac.
          */
         this.planService.updatePlan(PlanFeatures.fromSubscriptionId(this.globals.user.subscription.id));
      
         // console.log('[auth] User Doc: ', userDoc);
      });
   }

   clearUser(): Promise<any> {
      return new Promise(async (resolve, reject) => {
         try {
            this.aacService.clearAacBoards();
            this.activityService.clearActivityBoards();
            this.userServiceV2.clearCurrentUser();
            this.globals.user = new User();
            this.userService.userChanged(this.globals.user);
            resolve(false);
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   signIn(email: string, password: string): Promise<void> {
      return new Promise(async (resolve, reject) => {
         try {
            await this.fireAuth.signInWithEmailAndPassword(email.toLowerCase(), password);
            resolve();
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   signOut(): Promise<void> {
      return new Promise(async (resolve, reject) => {
         try {
            await this.fireAuth.signOut();
            resolve();
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   async sendPasswordResetEmail(email: string): Promise<any> {
      this.fireAuth.languageCode = new Promise(() => this.app.lang); // Set language used by Firebase Auth Emails
      return new Promise <void> ( async (resolve, reject) => {
      this.fireAuth.sendPasswordResetEmail(email.toLowerCase()).then(
         () => {
          resolve();
         },
         err => {
           reject();
         }
       );
      });
   }
}
