import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ActivitiesService } from 'src/app/activities/activities.service';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
import { UserContact, UserContactClickEvent } from 'src/app/user/user-contacts/user-contact/user-contact.model';
import { GroupAddParticipantsComponent } from '../group-add-participants/group-add-participants.component';
import { GroupAddSubjectComponent } from '../group-add-subject/group-add-subject.component';
import { GroupsService } from '../groups.service';
import { GroupInfoUserMorePopoverComponent } from './group-info-user-more-popover/group-info-user-more-popover.component';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
})
export class GroupInfoComponent implements OnInit, OnDestroy {

  @Input() group: ActivityBoard;

  theme = 'activities';
  participants: UserContact[] = [];
  hasAdminRights = false;
  activityBoardsSub: Subscription;
  removedFromGroup = false;

  constructor(public app: Globals, private modalController: ModalController, public lang: LanguageService,
    private popoverController: PopoverController, private groupsService: GroupsService,
    private activitiesService: ActivitiesService, private navCtrl: NavController, private alertController: AlertController) { }

  ngOnInit() {
    this.activityBoardsSub = this.activitiesService.activityBoardsSharedSpaceObs.subscribe((boards) => {
      const group = boards.find(board => board._id === this.group._id);
      if (group) {
        this.group = group;
      } else {
        this.group.status = 'removed';
      }

      this.refreshGroupParticipants();
      this.removedFromGroup = this.group.status && this.group.status.includes('removed') ? true : false;
      this.hasAdminRights = this.app.user.uid === this.group.owner && !this.removedFromGroup ? true : false;
    });
  }

  ngOnDestroy(): void {
    if (this.activityBoardsSub) { this.activityBoardsSub.unsubscribe(); }
  }

  refreshGroupParticipants() {
    this.participants = this.group.membersInfo.filter(memberInfo => memberInfo.uid !== this.app.user.uid);
    if (this.group.isGroup) {
      this.participants.unshift({ uid: this.app.user.uid, name: this.app.user.name, photo: this.app.user.profile.photo });
    }
  }

  onBackBtnClicked(ev: any) {
    this.modalController.dismiss();
  }

  async onEditGroupBtnClicked(ev: any) {
    const modal = await this.modalController.create({
      component: GroupAddSubjectComponent,
      componentProps: {
        group: this.group,
        title: this.lang.words.groups.editGroup,
        subtitle: '',
        groupImg: this.group.img,
        groupName: this.group.title,
        action: 'edit-group'
      },
      showBackdrop: false
    });
    await modal.present();
  }

  async onAddParticipantBtnClicked(ev: any) {
    const modal = await this.modalController.create({
      component: GroupAddParticipantsComponent,
      componentProps: {
        group: this.group,
        participants: this.participants,
        title: this.lang.words.groups.addParticipants,
        subtitle: ''
      },
      showBackdrop: false
    });
    await modal.present();
  }

  async onUserMoreBtnClicked(ev: UserContactClickEvent) {
    const popover = await this.popoverController.create({
      component: GroupInfoUserMorePopoverComponent,
      event: ev.ev,
      translucent: true,
      componentProps: {
        userName: ev.contact.name.split(' ')[0]
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data && data.action === 'remove') {
      this.groupsService.removeUserFromGroup(this.group, ev.contact.uid);
    }
  }

  onExitGroupBtnClicked() {
    this.exitGroupAlert(this.group);
  }

  onDeleteGroupBtnClicked() {
    this.deleteGroupAlert(this.group);
  }

  async deleteGroupAlert(group: ActivityBoard) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.common.thinkCarefully,
      message: this.lang.words.alerts.deleteGroup.message,
      buttons: [
        {
          text: this.lang.words.common.yes,
          handler: () => {
            this.groupsService.deleteGroup(group);
            this.modalController.dismiss();
          }
        },
        {
          text: this.lang.words.common.no,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  async exitGroupAlert(group: ActivityBoard) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.common.thinkCarefully,
      message: this.lang.words.alerts.leaveGroup.message,
      buttons: [
        {
          text: this.lang.words.common.yes,
          handler: () => {
            this.group = this.groupsService.removeUserFromGroup(group, this.app.user.uid);
          }
        },
        {
          text: this.lang.words.common.no,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  onDeleteContactBtnClicked() {
    this.deleteContactAlert(this.group);
  }

  async deleteContactAlert(group: ActivityBoard) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.common.thinkCarefully,
      message: this.lang.words.alerts.deleteContact.message,
      buttons: [
        {
          text: this.lang.words.common.yes,
          handler: () => {
            this.groupsService.deleteContact(group, this.app.user.uid);
            this.modalController.dismiss();
          }
        },
        {
          text: this.lang.words.common.no,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
}
