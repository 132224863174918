import { AacPreferences } from '../aac/aac-board/aac-preferences.model';
import { AppSubscription } from '../subscription/subscription.model';
import { UserPreferences } from './user-preferences.model';
import { UserStatistics } from './user-statistics.model';
import { UserLocalParameters } from './user-local-parameters.model';
import { UserProfile } from './user-profile/user-profile.model';
import { UserBasicInfo } from './user-basic-info.model';
import { UserContact } from './user-contacts/user-contact/user-contact.model';

interface RootAac {
    idiom: string;
    rootBoard: string;
    preferences: AacPreferences;
}

// tslint:disable:variable-name
export class User {
    _id = '';
    uid = '';
    name = '';
    email = '';
    token = '';
    rootActivities = [{
        idiom: 'pt-br',
        rootBoard: ''
    }];
    rootAac: RootAac[] = [{
        idiom: 'pt-br',
        rootBoard: '',
        preferences: undefined
    }];
    preferences: UserPreferences = new UserPreferences();
    subscription: AppSubscription = new AppSubscription();
    statistics: UserStatistics = new UserStatistics();
    local: UserLocalParameters = new UserLocalParameters();
    profile: UserProfile = new UserProfile();
    subscriptionRef = '';
    plan = {
        planLockEnabled: true
    };
    mgmOriginUid = '';
    friends: string[] = [];

    constructor(obj = null) {
        if (typeof obj !== 'undefined' && obj !== null) {
            Object.assign(this, obj);
            if (obj.statistics) {
                this.statistics = new UserStatistics(obj.statistics);
            }
            if (obj.profile) {
                this.profile = new UserProfile(obj.profile);
            }
        }
    }

    isSubscriber() {
        return this.subscription.isActive;
    }
}
