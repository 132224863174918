import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivitiesService } from '../activities/activities.service';
import { Globals } from '../globals/globals';
import { LanguageService } from '../language/language.service';
import { StorageService } from '../storage/storage.service';
import { WalkthroughData } from './walkthrough.data';
import { WalkthroughStep } from './walkthrough.model';
import { WalkthroughService } from './walkthrough.service';
import { Tooltip, TooltipProperties } from 'src/app/tooltip-pro';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.scss'],
})
export class WalkthroughComponent implements OnInit {

  @ViewChild('walkthroughDivElm') walkthroughDivElm: ElementRef;
  @ViewChild('walkthroughInnerDivElm') walkthroughInnerDivElm: ElementRef;

  walkthroughStep: WalkthroughStep;
  walkthroughSteps: WalkthroughStep[];
  tooltipEnabled = true;
  properties: TooltipProperties;
  currentPage: string;

  constructor(private lang: LanguageService, public app: Globals, private walkthroughData: WalkthroughData,
              private activitiesService: ActivitiesService, private walkthroughService: WalkthroughService,
              private storageService: StorageService, private tooltip: Tooltip, private authService: AuthService) {
    this.walkthroughSteps = walkthroughData.walkthroughSteps;
    this.walkthroughService.pageChangesBS.subscribe(page => {
      //console.log(`Pagina atual: ${page}`);
      this.currentPage = page;
      this.dismiss();
      this.nextStep(page);
    });

    /**
    * Watch for any changes on app language
    */
    this.lang.langObs.subscribe((lang) => {
      this.walkthroughData.refresh(); // Atualiza textos após alteração de idioma
      this.walkthroughSteps = this.walkthroughData.walkthroughSteps;
    });
  }

  ngOnInit() {
    this.walkthroughStep = this.walkthroughSteps[0];
  }

  show(prop: TooltipProperties) {
    this.tooltip.load(prop);
  }

  dismiss() {
    if (this.tooltip) {
      this.tooltip.close();
    }
  }

  async nextStep(page: string) {
    const pageTooltips = this.walkthroughSteps.filter(step => step.page === page);
    for (let i = 0; i < pageTooltips.length; i++) {
      this.walkthroughStep = pageTooltips[i];
      var showTooltip = false;
      var tooltipLastLog = await this.storageService.get(`tooltip-${this.walkthroughStep.targetElementId}`);

      if (typeof tooltipLastLog == 'undefined' || tooltipLastLog == null) { 
        tooltipLastLog = {
          timestamp: 0,
          displayCount: 0
        }
      }
      
      switch(this.walkthroughStep.type) {
        case 'conditional': {
          showTooltip = this.walkthroughStep.condition(tooltipLastLog, this.app);
          break;
        } 
        case 'standalone':
        default: {       
          if (typeof tooltipLastLog.timestamp !== 'undefined' && tooltipLastLog.timestamp) {
            showTooltip = false;
          } else { 
            showTooltip = true;
          }
        } 
      }
      
      if (showTooltip) { 
        let targetElementId = this.walkthroughStep.targetElementId;
        
        if (this.walkthroughStep.targetElementId.includes('activity-card') || this.walkthroughStep.targetElementId.includes('activity-shared')) {
          const cardIndex = this.walkthroughStep.targetElementId.split('-')[2];
          targetElementId = this.activitiesService.getActivityIdFromParent(parseInt(cardIndex, 10));
          console.log(targetElementId);
        }
        
        if (this.targetElementInPage(targetElementId)){
          this.setTooltipPosition(targetElementId);
          setTimeout(() => {
            if(this.walkthroughStep.page === this.currentPage && this.targetElementInPage(targetElementId)) {
              this.show(this.properties);
              // Atualiza log de exibição
              var date = new Date();
              const tooltipLog = {
                timestamp: date.getTime(),
                displayCount: tooltipLastLog.displayCount + 1
              }
              this.storageService.set(`tooltip-${this.walkthroughStep.targetElementId}`, tooltipLog);
            } else { // BUGFIX: Evita que tooltips sejam criados na página errada quando há troca de página antes do tooltip ser carregado
              this.dismiss(); // Cancelar, página mudou
            }
          }, 1100);
        }
        return;
      }
    }
  }

  setTooltipPosition(targetElementId: string) {
    setTimeout(() => {
      //console.log(document.getElementById(targetElementId));
      const elmPosition = document.getElementById(targetElementId).getBoundingClientRect();
      this.walkthroughDivElm.nativeElement.style.marginTop = `${elmPosition.top}px`;
      this.walkthroughDivElm.nativeElement.style.marginLeft = `${elmPosition.left}px`;

      this.walkthroughInnerDivElm.nativeElement.style.width = `${elmPosition.width}px`;
      this.walkthroughInnerDivElm.nativeElement.style.height = `${elmPosition.height}px`;
      //console.log(targetElementId);
      //console.log(document.getElementById(targetElementId));
      // Configurações do tooltip
      this.properties = {
        targetElement: document.getElementById(targetElementId), 
        tooltipText: this.walkthroughStep.dynamicText ? this.walkthroughStep.dynamicTextFunction(this.app) : this.walkthroughStep.text,
        trigger:'click',
        minWidth: '200px',
        maxWidth: '350px',
        hideDelay: 8000,
        width: `${elmPosition.height}px`,
        theme: this.walkthroughStep.theme ? this.walkthroughStep.theme : 'light',
        placement: this.app.isRunningOnMobile() ? this.walkthroughStep.placementMobile : this.walkthroughStep.placementDesktop 
      }
    }, 600);
  }

  targetElementInPage(targetElementId: string){
    const targetElement = document.getElementById(targetElementId);
    if(targetElement == null || typeof targetElement == 'undefined'){
      console.log(`Target element n encontrado`);
      console.log(targetElement);
      return false;
    } else {
      console.log(`Target element encontrado`);
      return true;
    }
  }
}
