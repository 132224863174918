import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AacBoard } from '../aac/aac-board/aac-board.model';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import * as aacBoardsSampleJson from '../../assets/sample-boards/aac-boards-sample-pt-br.json';
import * as aacBoardsSampleEnGbJson from '../../assets/sample-boards/aac-boards-sample-en-gb.json';
import * as aacBoardsSampleArSAJson from '../../assets/sample-boards/aac-boards-sample-ar-sa.json';
import * as aacBoardsSampleItITJson from '../../assets/sample-boards/aac-boards-sample-it-it.json';
import * as activityBoardsSampleJson from '../../assets/sample-boards/activity-boards-sample-pt-br.json';
import * as activityBoardsSampleEnGbJson from '../../assets/sample-boards/activity-boards-sample-en-gb.json';
import * as activityBoardsSampleArSAJson from '../../assets/sample-boards/activity-boards-sample-ar-sa.json';
import * as activityBoardsSampleItITJson from '../../assets/sample-boards/activity-boards-sample-it-it.json';


@Injectable({
  providedIn: 'root'
})
export class SampleBoardsService {

  activityBoardsSample: ActivityBoard[] = [];
  aacBoardsSample: AacBoard[] = [];

  constructor(private firestore: AngularFirestore) {
    this.aacBoardsSample = (aacBoardsSampleJson as any).default;
    this.activityBoardsSample = (activityBoardsSampleJson as any).default;
  }

  getAacSampleBoards(lang = 'pt-BR'): AacBoard[] {
    let sampleBoards = [];
    switch (lang) {
      case 'en-GB': { sampleBoards = (aacBoardsSampleEnGbJson as any).default; break; }
      case 'ar-SA': { sampleBoards = (aacBoardsSampleArSAJson as any).default; break; }
      case 'it-IT': { sampleBoards = (aacBoardsSampleItITJson as any).default; break; }
      case 'pt-BR': { sampleBoards = (aacBoardsSampleJson as any).default; break; }
      default: { sampleBoards = (aacBoardsSampleEnGbJson as any).default; break; }
    }
    return JSON.parse(JSON.stringify(sampleBoards));
  }

  getActivitiesSampleBoards(lang: string) {
    let sampleBoards = [];
    switch (lang) {
      case 'en-GB': { sampleBoards = (activityBoardsSampleEnGbJson as any).default; break; }
      case 'ar-SA': { sampleBoards = (activityBoardsSampleArSAJson as any).default; break; }
      case 'it-IT': { sampleBoards = (activityBoardsSampleItITJson as any).default; break; }
      case 'pt-BR': { sampleBoards =  (activityBoardsSampleJson as any).default; break; }
      default: { sampleBoards =  (activityBoardsSampleEnGbJson as any).default; break; }
    }
    return JSON.parse(JSON.stringify(sampleBoards));
  }

  createAacSampleBoards(lang = 'pt-BR'): AacBoard[] {
    const aacBoards = [];
    this.chooseAacSampleLanguage(lang);
    const rootBoardId = this.aacBoardsSample.find(b => b.title === 'root')._id;
    this.discoveryBoards(rootBoardId, this.aacBoardsSample, aacBoards);
    return aacBoards;
  }

  createActivitySampleBoards(lang = 'pt-BR'): ActivityBoard[] {
    const boards = [];
    this.chooseActivitiesSampleLanguage(lang);
    const rootBoardId = this.activityBoardsSample.find(b => b.title === 'root')._id;
    this.discoveryBoards(rootBoardId, this.activityBoardsSample, boards);
    return boards;
  }

  discoveryBoards(boardId: string, sampleBoards = [], boards = []) {
    const board = sampleBoards.find(b => b._id === boardId);
    board.cards = board.cards.filter(c => c && c.length == 20);
    const newCardsIds = [];

    if (typeof board === 'undefined' || board === null) { return; }

    board._id = this.firestore.createId();
    delete board.owner;
    delete board.createdAt;
    delete board.updatedAt;
    delete board.__v;
    // delete board.question;

    for (const cardId of board.cards) {
      const newCardId = this.discoveryBoards(cardId, sampleBoards, boards);
      newCardsIds.push(newCardId);
    }

    board.cards = newCardsIds;
    boards.push(board);

    return board._id;
  }

  getCardsByActivityName(activityName: string) {
    const activity = this.activityBoardsSample.filter(act => act.title.toLocaleLowerCase() === activityName.toLocaleLowerCase()).pop();
    if (activity) {
      const cardsIds = activity.cards;
      let cardsIdInline = '';
      for (const id of cardsIds) {
        cardsIdInline += id + ' ';
      }
      const cards = this.activityBoardsSample.filter(board => cardsIdInline.includes(board._id));

      const cardsOrdered = [];
      for (let i = 0; i < cards.length; i++) {
        const card = cards.find(c => c._id === cardsIds[i]);
        if (card !== undefined) {
          cardsOrdered.push(cards.find(c => c._id === cardsIds[i]));
        }
      }
      return JSON.parse(JSON.stringify(cardsOrdered));
    } else {
      return [];
    }
  }

  listImagesOfActivitiesSampleBoards() {
    const activitiesImages = [];
    for (const activity of this.activityBoardsSample) {
      activitiesImages.push(activity.img);
      activitiesImages.push(activity.questionImgSrc);
    }
    console.log(JSON.stringify(activitiesImages));
    return activitiesImages;
  }

  listAudiosOfActivitiesSampleBoards() {
    const activitiesAudios = [];
    for (const activity of this.activityBoardsSample) {
      activitiesAudios.push(activity.audio);
      activitiesAudios.push(activity.questionAudio);
    }
    console.log(JSON.stringify(activitiesAudios));
    return activitiesAudios;
  }

  listImagesOfAacSampleBoards() {
    const aacImages = [];
    for (const board of this.aacBoardsSample) {
      aacImages.push(board.img);
    }
    console.log(JSON.stringify(aacImages));
    return aacImages;
  }

  listAudiosOfAacSampleBoards() {
    const aacAudios = [];
    for (const board of this.aacBoardsSample) {
      aacAudios.push(board.audio);
    }
    console.log(JSON.stringify(aacAudios));

    return aacAudios;
  }

  listSamplesMedias() {
    const sampleMedias = [];
    for (const board of this.aacBoardsSample) {
      if (board.img !== '') { sampleMedias.push(board.img); }
      if (board.audio !== '') { sampleMedias.push(board.audio); }
    }

    for (const activity of this.activityBoardsSample) {
      if (activity.img !== '') { sampleMedias.push(activity.img); }
      if (activity.questionImgSrc !== '') { sampleMedias.push(activity.questionImgSrc); }
      if (activity.audio !== '') { sampleMedias.push(activity.audio); }
      if (activity.questionAudio !== '') { sampleMedias.push(activity.questionAudio); }
    }

    return sampleMedias;
  }

  private chooseAacSampleLanguage(lang: string) {
    if (lang === 'en-GB') {
      this.aacBoardsSample = (aacBoardsSampleEnGbJson as any).default;
    } else if (lang === 'ar-SA') {
      this.aacBoardsSample = (aacBoardsSampleArSAJson as any).default;
    } else if (lang === 'it-IT') {
      this.aacBoardsSample = (aacBoardsSampleItITJson as any).default;
    } else if (lang === 'pt-BR') {
      this.aacBoardsSample = (aacBoardsSampleJson as any).default;
    } else {
      this.aacBoardsSample = (aacBoardsSampleEnGbJson as any).default;
    }
  }

  private chooseActivitiesSampleLanguage(lang: string) {
    if (lang === 'en-GB') {
      this.activityBoardsSample = (activityBoardsSampleEnGbJson as any).default;
    } else if (lang === 'ar-SA') {
      this.activityBoardsSample = (activityBoardsSampleArSAJson as any).default;
    } else if (lang === 'it-IT') {
      this.activityBoardsSample = (activityBoardsSampleItITJson as any).default;
    } else if (lang === 'pt-BR') {
      this.activityBoardsSample = (activityBoardsSampleJson as any).default;
    } else {
      this.activityBoardsSample = (activityBoardsSampleEnGbJson as any).default;
    }
  }
}
