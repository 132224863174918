import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject } from 'rxjs';
import { Globals } from '../globals/globals';
import { Invite } from './invite/invite.model';

@Injectable({
  providedIn: 'root'
})
export class InvitesService {

  private invitesRequestsBS = new BehaviorSubject<Invite[]>([]);
  invitesRequestsObs = this.invitesRequestsBS.asObservable();
  invitesRequestsCount = 0;
  inviteByLinkId = '';

  constructor(private app: Globals, private fns: AngularFireFunctions) { }

  async createInvite(myUid: string, invitedEmail = ''): Promise<Invite> {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('createInvite')({ fromUid: myUid, invitedUserEmail: invitedEmail, lang: this.app.lang }).toPromise()
      .then(invite => {
        resolve(invite);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  async getInvite(id: string): Promise<Invite> {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('getInvite')({inviteId: id}).toPromise()
      .then(invite => {
        resolve(invite);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  async acceptInvite(invite: Invite) {
    return this.fns.httpsCallable('acceptInvite')({ inviteId: invite.id, fromUid: invite.from.uid, toUid: this.app.user.uid }).toPromise();
  }

  async refuseInvite(invite: Invite) {
    return this.fns.httpsCallable('refuseInvite')({ inviteId: invite.id, toUid: this.app.user.uid }).toPromise();
  }
}
