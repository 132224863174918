import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivitiesService } from '../activities/activities.service';
import { ActivityBoardChange } from '../activities/activity-board/activity-board-change.model';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import { AnalyticsService } from '../analytics/analytics.service';
import { DatabaseService } from '../database/database.service';
import { Globals } from '../globals/globals';
import { NotificationService } from '../notification/notification.service';
import { UserBasicInfo } from '../user/user-basic-info.model';
import { UserContact } from '../user/user-contacts/user-contact/user-contact.model';
// tslint:disable:object-literal-shorthand

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private fns: AngularFireFunctions, private activitiesService: ActivitiesService, private databaseService: DatabaseService,
              private app: Globals, private analyticsService: AnalyticsService, private notificationService: NotificationService) { }

  async createGroup(name = '', groupImg = '', owner: string, membersUid: string[], membersInfo: UserBasicInfo[]): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line:object-literal-shorthand
      this.fns.httpsCallable('createGroup')
      ({ owner: owner, membersUid: membersUid, membersInfo: membersInfo, name: name, groupImg: groupImg, lang: this.app.lang }).toPromise()
      .then(group => {
        this.analyticsService.logEvent('group_create', { id: group._id });
        this.notificationService.createNewGroupNotification(group._id );
        resolve(group);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  addParticipantsToGroup(group: ActivityBoard, participants: UserContact[]): Promise<void> {
    return new Promise((resolve, reject) => {
        const participantsUid = participants.map(participant => participant.uid);
        const groupBoards: ActivityBoard[] = [];
        this.activitiesService.discoveryBoards(group._id, this.activitiesService.activityBoardsSharedSpace, groupBoards);

        const newProps = {
          membersUid: group.membersUid.concat(participantsUid),
        };

        const activitiesBoardChanges: ActivityBoardChange[] = [];
        for (const board of groupBoards) {
          activitiesBoardChanges.push({ board: board, changes: newProps });
        }

        const membersInfo = participants.map(({uid, name, photo}) => ({uid, name, photo}));
        activitiesBoardChanges.push({ board: group, changes: { membersInfo: group.membersInfo.concat(membersInfo) } });

        const participantsRemovedFromGroupUid = participantsUid.filter(participantUid =>
          group.removedMembersUid && group.removedMembersUid.includes(participantUid));

        // Get the boards from participants that were once in the group but were removed from it at certain point
        this.fns.httpsCallable('getUsersBoardsInGroup')({ groupId: group._id, usersId: participantsRemovedFromGroupUid })
        .toPromise().then(usersBoards => {

          // Gets users' categories, activities and cards
          const boards = usersBoards.filter(board => !board.isSharedSpace);

          // Adds the users' boards to the group. Thoses boards were in the group when the user was removed from it
          boards.forEach(board => {
            activitiesBoardChanges.push({ board: board, changes: newProps });
          });

          // Gets copies of shared spaces that was created when the users were removed
          const sharedSpaceCopies = usersBoards.filter(board => board.isSharedSpace && board.status && board.status.includes('removed'));

          // Removes the shared spaces copies since the user is in the group again
          sharedSpaceCopies.forEach(sharedSpaceCopy => {
            this.databaseService.deleteActivityBoard(sharedSpaceCopy, undefined, [sharedSpaceCopy]);
          });

          this.databaseService.updateManyActivitiesBoards(activitiesBoardChanges);
          this.notificationService.createNewParticipantsNotification(group._id, participants.map(p => p.uid));
          this.analyticsService.logEvent('group_participants_add', { id: group._id, uids: participants.map(p => p.uid) });

          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  removeUserFromGroup(group: ActivityBoard, uid: string) {
    // Discovery all boards within the group
    const groupBoards: ActivityBoard[] = [];
    this.activitiesService.discoveryBoards(group._id, this.activitiesService.activityBoardsSharedSpace, groupBoards);

    // Removes the user from group. All the contents created by the user will no longer be available for the group.
    const newProps = {
      membersUid: this.app.firebaseArrayRemove(uid),
      viewedBy: this.app.firebaseArrayRemove(uid)
    };

    const activitiesBoardChanges: ActivityBoardChange[] = [];
    for (const board of groupBoards) {
      if (board.owner === uid && !board.isSharedSpace) {

        if (board.isCategory) {
          const boardsInCaregory = [];
          this.activitiesService.discoveryBoards(board._id, groupBoards, boardsInCaregory);
          const activitiesFromOtherUsers = boardsInCaregory.filter(b => b.isActivity && b.owner !== uid);

          // Moves to the root the activities from other users that are insides a category made by the removing member
          activitiesFromOtherUsers.forEach(activity => {
            // Adds the activity to the group's root
            activitiesBoardChanges.push({ board: group, changes: { cards: this.app.firebaseArrayUnion(activity._id) }});

            // Finds the category that holds the activity and removes the activity's id from its cards list
            const category = boardsInCaregory.find(b => b.cards.includes(activity._id));
            if (category) {
              activitiesBoardChanges.push({ board: category, changes: { cards: this.app.firebaseArrayRemove(activity._id) }});
            }
          });
        }

        activitiesBoardChanges.push({ board: board, changes: { membersUid: [uid], viewedBy: [uid] }});
      } else {
        activitiesBoardChanges.push({ board: board, changes: newProps });
      }
    }

    // Creates a copy of the group root as it is at the moment the user is removed. This is intended to allow the user access
    // all activities in the group that were created by him/her.
    const groupCopy: ActivityBoard = JSON.parse(JSON.stringify(group));
    groupCopy._id = this.app.firebaseCreateId();
    groupCopy.status = `removed-from-group ${group._id}`;
    groupCopy.membersUid = [uid];
    activitiesBoardChanges.push({ board: groupCopy, changes: groupCopy });

    // Removes the user's info from the group and also adds it to removed members list
    activitiesBoardChanges.push({ board: group,
      changes: { membersInfo: this.app.firebaseArrayRemove(group.membersInfo.find(m => m.uid === uid)),
                 removedMembersUid: this.app.firebaseArrayUnion(uid)
      }});

    this.databaseService.updateManyActivitiesBoards(activitiesBoardChanges);
    this.notificationService.clearSharedSpaceNotifications(group._id, uid);
    this.analyticsService.logEvent('group_participant_remove', { groupId: group._id, uid: uid });

    return groupCopy;
  }

  deleteGroup(group: ActivityBoard) {
    const groupBoards: ActivityBoard[] = [];
    this.activitiesService.discoveryBoards(group._id, this.activitiesService.activityBoardsSharedSpace, groupBoards);

    this.databaseService.deleteActivityBoard(group, undefined, groupBoards).then(() => {
      this.analyticsService.logEvent('group_delete', { id: group._id });
    });
  }

  deleteContact(group: ActivityBoard, uid: string) {
    // Discovery all boards within the group
    const groupBoards: ActivityBoard[] = [];
    this.activitiesService.discoveryBoards(group._id, this.activitiesService.activityBoardsSharedSpace, groupBoards);

    // Removes the user from group. All the contents created by the user will no longer be available for the group.
    const newProps = {
      membersUid: this.app.firebaseArrayRemove(uid),
      viewedBy: this.app.firebaseArrayRemove(uid)
    };

    const boardsToDelete = [];
    const activitiesBoardChanges: ActivityBoardChange[] = [];
    for (const board of groupBoards) {
      if (board.owner === uid && !board.isSharedSpace && !board.isCategory || group.status.includes('removed')) {
        boardsToDelete.push(board);
      } else {
        activitiesBoardChanges.push({ board: board, changes: newProps });
      }
    }

    activitiesBoardChanges.push({ board: group, changes: { status: 'removed' } });
    this.databaseService.updateManyActivitiesBoards(activitiesBoardChanges);
    this.databaseService.deleteActivityBoard(group, undefined, boardsToDelete);
    this.notificationService.clearSharedSpaceNotifications(group._id);
    this.analyticsService.logEvent('contact_delete', { sharedSpaceId: group._id });
  }

  deleteGroupImg(groupImg: string) {
    this.databaseService.deleteMedia(groupImg);
  }
}
