import { ImageCroppedEvent } from 'ngx-image-cropper';

export class ImageCropInfo {
    imageUrl = '';
    imageType = '';
    imgSearchText = '';
    canvasWidth: number;
    canvasHeight: number;
    imagePreviewVerticalPadding: number;
    imagePreviewHorizontalPadding: number;
    imagePreviewWidth: number;
    imagePreviewHeight: number;
    imageCroppedEvent: ImageCroppedEvent;
    isImgFromDevice: boolean;
}
