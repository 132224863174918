import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';
import { UserContact, UserContactSelectChangeEv, UserContactClickEvent } from './user-contact.model';
// tslint:disable:object-literal-shorthand

@Component({
  selector: 'app-user-contact',
  templateUrl: './user-contact.component.html',
  styleUrls: ['./user-contact.component.scss'],
})
export class UserContactComponent implements OnInit, OnChanges {

  @Input() contact: UserContact;
  // @Input() subtitle: string;
  // @Input() badge: string;
  @Input() tag: string;
  @Input() selectable = false;
  @Input() subtitleAndBadge = {subtitle: '', badge: ''};
  @Input() subtitle = '';
  @Input() badge = '';
  @Input() showMoreButton = false;
  @Input() hideDetailIcon = false;
  @Input() isButton = true;
  @Input() dummyIcon = '';
  @Input() dummyBackground = '';
  @Output() click = new EventEmitter<UserContactClickEvent>();
  @Output() contactClick = new EventEmitter<UserContactClickEvent>();
  @Output() moreBtnClick = new EventEmitter<UserContactClickEvent>();
  @Output() selectChange = new EventEmitter<UserContactSelectChangeEv>();
  userPhotoImgSrc = '';
  userPhotoNotLoaded = false;

  constructor(private mediaStorageService: MediaStorageService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (this.contact.subtitle) {
      this.subtitle = this.contact.subtitle;
    }

    if (this.contact.badge) {
      this.badge = this.contact.badge;
    }

    if (this.contact.tag) {
      this.tag = this.contact.tag;
    }

    this.userPhotoImgSrc = this.mediaStorageService.resolveImageSrc(this.contact.photo);
  }

  onUserClicked(contact: UserContact, ev: any) {
    this.click.emit({contact: contact, ev: ev});
    this.contactClick.emit({contact: contact, ev: ev});
  }

  onUserSelectionChange(contact: UserContact, checked: boolean) {
    this.selectChange.emit({contact: contact, checked: checked});
  }

  onMoreBtnClicked(contact: UserContact, ev: any) {
    ev.stopPropagation();
    this.moreBtnClick.emit({contact: contact, ev: ev});
  }

  onUserPhotoLoaded(ev: any) {
    this.userPhotoNotLoaded = false;
  }

  onUserPhotoError(ev: any) {
    this.userPhotoNotLoaded = true;
    setTimeout(() => {
      const userPhotoImgSrc = this.userPhotoImgSrc;
      this.userPhotoImgSrc = '';
      setTimeout(() => { this.userPhotoImgSrc = userPhotoImgSrc; });

     }, 4000);
  }
}
