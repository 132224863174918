import { Injectable } from '@angular/core';
import { ActivityBoard } from '../activity-board/activity-board.model';
import { ActivitiesService } from '../activities.service';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';
import { DatabaseService } from 'src/app/database/database.service';
import { ImageCropInfo } from 'src/app/editor-tools/models/image-crop-info.model';
import { LanguageService } from 'src/app/language/language.service';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { NotificationService } from '../../notification/notification.service';

interface SaveCardOptions {
  isNewCard: boolean;
  saveCardImage: boolean;
  deleteOldCardImage: boolean;
  saveQuestionImage: boolean;
  deleteOldQuestionImage: boolean;
  saveCardAudio: boolean;
  deleteOldCardAudio: boolean;
  saveQuestionAudio: boolean;
  deleteOldQuestionAudio: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ActivityEditorService {

  newActivityName = '';

  // Atividade selecionada para edição
  selectedActivity: ActivityBoard;

  // Atividade ativa para edição
  editorActivity: ActivityBoard;

  // Cartão selecionado para edição
  selectedCard: ActivityBoard;

  // Cartão ativo para edição / criação
  editorCard: ActivityBoard;

  parentBoard: ActivityBoard;
  boardRoutes: ActivityBoard[];

  imageCropperSrc;

  constructor(private activitiesService: ActivitiesService, private mediaStorageService: MediaStorageService,
              private databaseService: DatabaseService, private lang: LanguageService, private loadingController: LoadingController,
              private alertController: AlertController, private toastController: ToastController,
              private analyticsService: AnalyticsService, private notificationService: NotificationService) { }

  createActivity(): Promise<ActivityBoard> {
    return new Promise(async (resolve, reject) => {
      return this.databaseService.createActivityBoard(this.editorActivity, this.parentBoard)
              .then((data) => { resolve(data); })
              .catch((err) => reject(err));
    });
  }

  deleteActivity(activity: ActivityBoard, parentBoard: ActivityBoard): Promise<any> {
    return new Promise((resolve, reject) => {
      const innerCards: ActivityBoard[] = [];
      this.discoveryBoards(activity._id, this.activitiesService.getAllBoards(), innerCards);
      this.databaseService.deleteActivityBoard(activity, parentBoard, innerCards)
      .then((data) => { resolve(data); })
      .catch((err) => { console.log(err); reject(err); });
    });
  }

  updateActivityPreferences(activity: ActivityBoard): Promise<any> {
    return new Promise(async (resolve, reject) => {
      return this.databaseService.updateActivityBoard(activity)
              .then((data) => { resolve(data); })
              .catch((err) => reject(err));
    });
  }

  createCard(): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      this.databaseService.createActivityCard(this.editorCard, this.editorActivity)
      .then((data) => { resolve(data); })
      .catch((err) => reject(err));
    });
  }

  updateCard(): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      this.databaseService.updateActivityCard(this.editorCard)
      .then((data) => { resolve(data); })
      .catch((err) => reject(err));
    });
  }

  saveActivity(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.databaseService.updateActivityBoard(this.editorActivity)
        .then((data) => { resolve(data); })
        .catch((err) => reject(err));
    });
  }

  // tslint:disable-next-line:max-line-length
  async saveCard(cardImageCropInfo: ImageCropInfo, questionImageCropInfo: ImageCropInfo, cardAudioBlob: Blob, questionAudioBlob: Blob): Promise<any> {
    const saveCardOptions = this.getSaveCardOptions();

    return new Promise(async (resolve, reject) => {
      try {
        if (saveCardOptions.saveCardImage) {
          await this.mediaStorageService.saveImage(cardImageCropInfo)
          .then(savedImageName => this.editorCard.img = savedImageName);
        }

        if (saveCardOptions.saveQuestionImage) {
          await this.mediaStorageService.saveImage(questionImageCropInfo)
          .then(savedImageName => this.editorCard.questionImgSrc = savedImageName);
        }

        if (saveCardOptions.saveCardAudio) {
          await this.mediaStorageService.saveAudio(cardAudioBlob)
          .then(savedAudioName => this.editorCard.audio = savedAudioName);
        }

        if (saveCardOptions.saveQuestionAudio) {
          await this.mediaStorageService.saveAudio(questionAudioBlob)
          .then(savedAudioName => this.editorCard.questionAudio = savedAudioName);
        }

        if (saveCardOptions.deleteOldCardImage) {
          this.mediaStorageService.deleteImage(this.selectedCard.img).then().catch();
        }

        if (saveCardOptions.deleteOldQuestionImage) {
          this.mediaStorageService.deleteImage(this.selectedCard.questionImgSrc).then().catch();
        }

        if (saveCardOptions.deleteOldCardAudio) {
          this.mediaStorageService.deleteAudio(this.selectedCard.audio.toString()).then().catch();
        }

        if (saveCardOptions.deleteOldQuestionAudio) {
          this.mediaStorageService.deleteAudio(this.selectedCard.questionAudio.toString()).then().catch();
        }

        let card: ActivityBoard;
        if (saveCardOptions.isNewCard) {
          card = await this.createCard();
          // this.editorActivity.cards.push(this.editorCard._id);
        } else {
          card = await this.updateCard();
        }

        resolve(card);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  getSaveCardOptions(): SaveCardOptions {
    const options = {
      isNewCard: true,
      saveCardImage: false,
      deleteOldCardImage: false,
      saveQuestionImage: false,
      deleteOldQuestionImage: false,
      saveCardAudio: false,
      deleteOldCardAudio: false,
      saveQuestionAudio: false,
      deleteOldQuestionAudio: false
    };

    options.isNewCard = (this.editorCard._id === '' || typeof this.activitiesService.getBoardById(this.editorCard._id) === 'undefined') ? true : false;

    let selectedCard = this.selectedCard;

    if (typeof selectedCard === 'undefined') {
      // Cartão vazio. Será usado como comparação para determinar quais recursos do cartão serão salvos
      selectedCard = new ActivityBoard();
    }

    if (this.editorCard.img !== selectedCard.img) {
      if (this.editorCard.img !== '') {
        options.saveCardImage = true;
      }
      if (selectedCard.img !== '') {
        options.deleteOldCardImage = true;
      }
    }

    if (this.editorCard.questionImgSrc !== selectedCard.questionImgSrc) {
      if (this.editorCard.questionImgSrc !== '') {
        options.saveQuestionImage = true;
      }
      if (selectedCard.questionImgSrc !== '') {
        options.deleteOldQuestionImage = true;
      }
    }

    if (this.editorCard.audio !== selectedCard.audio) {
      if (this.editorCard.audio !== '') {
        options.saveCardAudio = true;
      }
      if (selectedCard.audio !== '') {
        options.deleteOldCardAudio = true;
      }
    }

    if (this.editorCard.questionAudio !== selectedCard.questionAudio) {
      if (this.editorCard.questionAudio !== '') {
        options.saveQuestionAudio = true;
      }
      if (selectedCard.questionAudio !== '') {
        options.deleteOldQuestionAudio = true;
      }
    }

    return options;
  }

  async deleteActivityAlert(activity: ActivityBoard, parentBoard: ActivityBoard) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteActivity.header,
      message: this.lang.words.alerts.deleteActivity.message,
      buttons: [
        {
          text: this.lang.words.alerts.deleteActivity.buttonYes,
          handler: async () => {
            const loading = await this.loadingController.create({
              cssClass: 'app-standard-loading',
              message: this.lang.words.loadings.deleteActivity.message,
              spinner: 'dots',
              animated: false
            });

            this.deleteActivity(activity, parentBoard)
              .then(() => {
                this.loadingController.dismiss();
                if (activity.isCategory) {
                  this.analyticsService.logEvent('activities_category_delete', { id: activity._id });
                  this.showCategoryDeletedToast();
                } else {
                  this.analyticsService.logEvent('activity_delete', { id: activity._id });
                  if (activity.shared) {
                    this.notificationService.clearActivityNotifications(activity._id);
                  }
                  this.showActivityDeletedToast();
                }
              })
              .catch((err) => { this.loadingController.dismiss(); this.errorAlert(); });

            await loading.present();
          }
        },
        {
          text: this.lang.words.alerts.deleteActivity.buttonNo,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  async showCategoryDeletedToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.categoryDeleted.message,
      cssClass: 'app-toast',
      duration: 1000
    });
    toast.present();
  }

  async showActivityDeletedToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.activityDeleted.message,
      cssClass: 'app-toast',
      duration: 1000
    });
    toast.present();
  }

  async deleteCardAlert(card: ActivityBoard) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteCard.header,
      // TODO: Alterar nome da estrutura de tradução para incluir o termo 'o cartão?'
      message: this.lang.words.alerts.deleteCard.message + '?',
      buttons: [
        {
          text: this.lang.words.alerts.deleteCard.buttonYes,
          handler: async () => {
            const loading = await this.loadingController.create({
              cssClass: 'app-standard-loading',
              message: this.lang.words.loadings.deleteCard.message,
              spinner: 'dots',
              animated: false
            });

            this.deleteCard(card)
              .then(() => {
                this.loadingController.dismiss();
                this.showCardDeletedToast();
                this.analyticsService.logEvent('activity_card_delete', { id: card._id });
              }).catch((err) => { this.loadingController.dismiss(); this.deleteCardError(err); });

            await loading.present();
          }
        },
        {
          text: this.lang.words.alerts.deleteCard.buttonNo,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  deleteCard(card: ActivityBoard): Promise<any> {
    return new Promise((resolve, reject) => {
      const innerCards: ActivityBoard[] = [];
      this.discoveryBoards(card._id, this.activitiesService.getAllBoards(), innerCards);
      this.databaseService.deleteActivityCard(card, this.editorActivity, innerCards)
      .then((data) => { resolve(data); })
      .catch((err) => reject(err));
    });
  }

  async showCardDeletedToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.cardDeleted.message,
      cssClass: 'app-toast',
      duration: 1000
    });
    toast.present();
  }

  deleteCardError(err: any) {
    this.errorAlert();
  }

  async errorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.error.header,
      subHeader: this.lang.words.alerts.error.subHeader,
      message: this.lang.words.alerts.error.message,
      buttons: [this.lang.words.alerts.error.button]
    });
    await alert.present();
  }

  discoveryBoards(boardId: string, srcBoards = [], boards = []) {
    const board = srcBoards.find( b => b._id === boardId);

    if (typeof board === 'undefined' || board === null) { return; }

    for (const cardId of board.cards) {
        this.discoveryBoards(cardId, srcBoards, boards);
    }
    boards.push(board);
  }

  activityHasChanged(): boolean {
    return JSON.stringify(this.selectedActivity) !== JSON.stringify(this.editorActivity);
  }

  activityIsEmpty(): boolean {
    return ((this.editorActivity.cards.length) === 0 && (this.editorActivity.title === ''));
  }

  finishActivityEditing() {
    this.selectedActivity = undefined;
    this.editorActivity = undefined;
    this.selectedCard = undefined;
    this.editorCard = undefined;
  }

  finishCardEditing() {
    this.selectedCard = undefined;
    this.editorCard = undefined;
  }

  /**
   * Creates the activity into user's account that was initialized when the user wasn't logged in yet.
   */
  createActivityAfterLogin(parentBoard: ActivityBoard) {
    this.parentBoard = parentBoard;
    this.createActivity().then(board => {
      this.analyticsService.logEvent('activity_create', { id: board._id, shared: false });
    });
  }
}
