import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-user-contact-more-popover',
  templateUrl: './user-contact-more-popover.component.html',
  styleUrls: ['./user-contact-more-popover.component.scss'],
})
export class UserContactMorePopoverComponent implements OnInit {

  constructor(private popoverController: PopoverController, public lang: LanguageService) { }

  @Input() isGroup = false;
  scope = 'activities';

  ngOnInit() {}

  onEditBtnClicked() {
    this.popoverController.dismiss('edit');
  }

  onDeleteBtnClicked() {
    this.popoverController.dismiss('delete');
  }

}
