import { PlanFeatures } from './plan-features.model';

export class Plan {
    type: string;
    features: PlanFeatures;
    canAccessAac: boolean;
    canEditAac: boolean;
    canCreateAac: boolean;
    canAccessActivities: boolean;
    canEditActivities: boolean;
    canCreateActivities: boolean;
    hasExpired: boolean;
    freeSlotsAvailable: number;

    constructor() {}

    /**
     * Get the plan type based on the subscription id
     */
    static subscriptionIdToPlanType(subsId: string) {
        switch (subsId) {
            case 'monthly_subscription': return 'activities';
            case 'monthly_subscription_aac': return 'aac';
            case 'read_only': return 'read_only';
            default: return 'free';
        }
    }
}
