import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { Globals } from '../globals/globals';
import { PlanFeatures } from './plan-features.model';
import { Plan } from './plan.model';

// tslint:disable:max-line-length

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  FREE_SLOTS = 3;

  plan: Plan;
  planBS: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(new Plan());

  constructor(private app: Globals, private firestore: AngularFirestore) { }

  /**
   * Updates the user plan based on a plan features. The plan features contains all features present in a plan taking into account
   * plataform-specific features. It's important to mention that a plan is more releated with the conditions at runtime rather than
   * static as plan features. For example, on activities plan, the plan features it has won't change at runtime. But the plan may
   * change if the user subscription expire or when the trial period ends.
   */
  updatePlan(planFeat: PlanFeatures) {
    let planFeatures = planFeat;
    if (this.app.isUserLogged()) {
      let expiryTimeMillis = this.app.user.subscription.expiryTimeMillis;
      if (typeof expiryTimeMillis === 'undefined') { expiryTimeMillis = 0; }

      if (expiryTimeMillis === 0 && !this.app.user.subscription.isActive) {

        // TODO: Pegar tempo no servidor para termos uma fonte mais confiável
        this.app.user.subscription.expiryTimeMillis = this.calculateFreeTrialExpiryDate(new Date(), 30, 0, 0).getTime();

        // Atribuimos o plano de comunicação alternativa para o usuário (período gratuíto no qual
        // poderá testar a comunicação a alternativa na web). Com o modelo freemium, o usuário não
        // tem acesso total aos recursos do plano de atividades.
        this.app.user.subscription.id = 'monthly_subscription_aac';

        // Atualiza o campo de assinatura do usuário no banco de dados
        const updateData = { subscription: JSON.parse(JSON.stringify(this.app.user.subscription)) };
        this.firestore.collection('users').doc(this.app.user.uid).update(updateData);
      }

      /**
       * Check if the plan has expired. If so, we considere the plan with free features
       */
      let hasExpired = false;
      if (new Date().getTime() > this.app.user.subscription.expiryTimeMillis) {
        planFeatures = new PlanFeatures('free');
        hasExpired = true;
      } else {
        if (this.plan) { this.plan.freeSlotsAvailable = this.FREE_SLOTS; }
      }

      /**
       * Create a plan based on the plan features. The plan features has all features
       * available in the app and also takes account the platform that the app is running.
       * On the other hand, a plan is a more general class that also contains features related
       * stuff, but with the platform-specific already resolved.
       */
      let plan: Plan;
      if (this.app.isRunningOnNative()) {
        plan = planFeatures.createPlanForApp();
      } else {
        plan = planFeatures.createPlanForWeb();
      }

      // Set the expiry info and also finds out the plan type based on the subscription id
      plan.hasExpired = hasExpired;
      plan.type = Plan.subscriptionIdToPlanType(this.app.user.subscription.id);

      // Set the original plan feature that we recived as parameter of this method
      plan.features = planFeat;

      // Keep track of available free slots before overwriting the plan
      plan.freeSlotsAvailable = this.plan ? this.plan.freeSlotsAvailable : this.FREE_SLOTS;

      // Send an update of the plan to everyone who is listening to it
      this.plan = plan;
      this.planBS.next(plan);
    } else {
      /**
       * When not logged, the base plan is free
       */
      planFeatures = new PlanFeatures('free');
      let plan: Plan;
      if (this.app.isRunningOnNative()) {
        plan = planFeatures.createPlanForApp();
      } else {
        plan = planFeatures.createPlanForWeb();
      }

      /**
       * If the user is not logged in, him/her can play the activities for a few time
       * before we lock it.
       */
      if (this.app.activitiesPlayCount >= 10) {
        plan.canAccessActivities = false;
      } else {
        plan.canAccessActivities = true;
      }

      // Set the free plan features since the user is not logged
      plan.features = planFeatures;

      // Send an update of the plan to everyone who is listening to it
      this.plan = plan;
      this.planBS.next(plan);
    }
  }

  setfreeSlotsAvailable(value: number) {
    if (this.plan && this.plan.freeSlotsAvailable !== value) {
      this.plan.freeSlotsAvailable = value;
      this.planBS.next(this.plan);
    }
  }

  /**
   * Calculates the expiry date of the free trial period
   *
   * @param date Starting date of the free trial
   * @param days Number of days within the free trial
   */
  calculateFreeTrialExpiryDate(date: Date, days: number, hours: number = 0, minutes: number = 0): Date {
    /**
     * We are trusting the date on the user device. For a more robust solution, we can get the date online
     * (for exemple, by using this api https://unixtime.co.za/) and have the method bellow as a fallback.
     */
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    result.setHours(result.getHours() + hours);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
  }

  /**
   * Checks if the user has full access of the app's features.
   * @returns true if the use has access to all features in the app
   */
  hasFullAccess() {

    if (this.app.isBetaTesting() || this.app.user.isSubscriber() || this.app.user.plan.planLockEnabled === false) {
      return true;
    } else {
      return false;
    }
  }
}
