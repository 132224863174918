import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AacBoard } from 'src/app/aac/aac-board/aac-board.model';

@Injectable({
  providedIn: 'root'
})
export class AacDataApiService {

  constructor(private fns: AngularFireFunctions) { }

  /**
   * Gets the aac boards that are stored in cloud
   * @param lang boards' language
   * @returns boards
   */
  getAacBoards(langSuffix: string, opts: any): Promise<{ boards: Array<AacBoard>, serverTimestamp: number }>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('getAacBoards')({ langSuffix,  opts}).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }

  /**
   * Gets the aac boards that are stored in cloud
   * @param lang boards' language
   * @returns boards
   */
  saveSharedBoard(sharedBoardLinkId: string, langSuffix: string, root: AacBoard = null): Promise<void>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('saveSharedBoard')({ sharedBoardLinkId, langSuffix, root }).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }
}
