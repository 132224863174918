import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  {
    path: 'shared-activities/:id',
    loadChildren: () => import('./activities/activity-play/activity-play.module').then(m => m.ActivityPlayPageModule)
  },
  {
    path: 'aac',
    loadChildren: () => import('./aac/aac.module').then(m => m.AacPageModule)
  },
  {
    path: 'shared-aac/:id-board',
    loadChildren: () => import('./aac/aac.module').then(m => m.AacPageModule)
  },
  {
    path: 'shared-sentence/:id-sentence',
    loadChildren: () => import('./aac/aac.module').then(m => m.AacPageModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesPageModule)
  },
  {
    path: 'activities/:root',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesPageModule)
  },
  { path: 'login', redirectTo: 'login/', pathMatch: 'full' },
  {
    path: 'login/:nextRoute',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  { path: 'sign-up', redirectTo: 'sign-up/', pathMatch: 'full' },
  {
    path: 'sign-up/:nextRoute',
    loadChildren: () => import('./auth/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'editor-tools/image-search',
    loadChildren: () => import('./editor-tools/image-search/image-search.module').then(m => m.ImageSearchPageModule)
  },
  {
    path: 'editor-tools/image-crop',
    loadChildren: () => import('./editor-tools/image-crop/image-crop.module').then(m => m.ImageCropPageModule)
  },
  {
    path: 'scan-pace',
    loadChildren: () => import('./scan/scan-pace/scan-pace.module').then(m => m.ScanPacePageModule)
  },
  {
    path: 'subscription-success',
    loadChildren: () => import('./subscription/subscription-success/subscription-success.module')
      .then(m => m.SubscriptionSuccessPageModule)
  },
  {
    path: 'subscription-intro',
    loadChildren: () => import('./subscription/subscription-intro/subscription-intro.module').then(m => m.SubscriptionIntroPageModule)
  },
  {
    path: 'subscription-plans',
    loadChildren: () => import('./subscription/subscription-plans/subscription-plans.module').then(m => m.SubscriptionPlansPageModule)
  },
  {
    path: 'promo/:promoCode',
    loadChildren: () => import('./subscription/subscription-plans/subscription-plans.module').then(m => m.SubscriptionPlansPageModule)
  },
  {
    path: 'invites/:id',
    loadChildren: () => import('./invites/invites.module').then(m => m.InvitesPageModule)
  },
  {
    path: 'aac-home',
    loadChildren: () => import('./v2/Aac/aac-home/aac-home.module').then(m => m.AacHomePageModule)
  },  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module').then( m => m.FormsPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
