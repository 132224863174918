import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ShareActivityPopupRoutingModule } from './share-activity-popup-routing.module';

// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { Platform } from '@angular/cdk/platform';
import { Clipboard } from '@angular/cdk/clipboard';
import { Clipboard as ClipboardNative } from '@ionic-native/clipboard/ngx';
// import { ShareService } from 'ngx-sharebuttons';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ShareActivityPopupComponent } from 'src/app/activities/share-activity-popup/share-activity-popup.component';


@NgModule({
    declarations: [ShareActivityPopupComponent],
    imports: [
        CommonModule,
        IonicModule,
        ShareActivityPopupRoutingModule,
        // ShareButtonsModule, // TODO UPDATE ANGULAR
        // ShareIconsModule // TODO UPDATE ANGULAR
    ],
    providers: [
        Platform,
        // ShareService, // TODO UPDATE ANGULAR
        Clipboard,
        ClipboardNative,
        FaConfig,
        FaIconLibrary
    ]
})
export class ShareActivityPopupModule { }
