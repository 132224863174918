import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImgComponent } from './img.component';
import { CroppedImgV2Component } from './cropped-img-v2/cropped-img-v2.component';

@NgModule({
 imports:      [ CommonModule, IonicModule, FormsModule ],
 declarations: [ ImgComponent, CroppedImgV2Component ],
 exports:      [ ImgComponent ]
})
export class ImgModule {}
