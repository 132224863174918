import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-user-interest',
  templateUrl: './user-interest.component.html',
  styleUrls: ['./user-interest.component.scss'],
})
export class UserInterestComponent implements OnInit {

  @ViewChild('otherRadio') otherRadio: any;

  selectedOccupation: string;
  otherValue = '';

  constructor(public lang: LanguageService, private modalController: ModalController, public app: Globals) { }

  ngOnInit() {
    let totalViews = parseInt(localStorage.getItem('occupation-popup-views'), 10);
    if (totalViews) {
      totalViews++;
      localStorage.setItem('occupation-popup-views', String(totalViews));
    } else {
      localStorage.setItem('occupation-popup-views', '1');
    }
  }

  onBackBtnClicked() {
    this.modalController.dismiss('back-activity');
  }

  onRadioClicked(ev: any) {
    this.selectedOccupation = ev.detail.value;
  }

  onOtherItemClicked() {
    this.otherRadio.el.click();
  }

  onSubmitBtnClicked(ev: any) {
    this.selectedOccupation = this.selectedOccupation !== 'other' ? this.selectedOccupation : this.otherValue;

    if (this.selectedOccupation) {
      this.modalController.dismiss({ action: 'set-occupation', occupation: this.selectedOccupation });
    }
  }
}
