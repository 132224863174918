import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GroupAddParticipantsComponent } from './group-add-participants/group-add-participants.component';
import { UserModule } from '../user/user.module';
import { GroupAddSubjectComponent } from './group-add-subject/group-add-subject.component';
import { GridModule } from '../grid/grid.module';
import { GroupInfoComponent } from './group-info/group-info.component';
import { GroupInfoUserMorePopoverComponent } from './group-info/group-info-user-more-popover/group-info-user-more-popover.component';
import { ImgModule } from '../layout/img/img.module';

@NgModule({
    declarations: [
        GroupAddParticipantsComponent,
        GroupAddSubjectComponent,
        GroupInfoComponent,
        GroupInfoUserMorePopoverComponent
    ],
    imports: [
        CommonModule,
        CommonModule,
        FormsModule,
        IonicModule,
        UserModule,
        GridModule,
        UserModule,
        ImgModule
    ]
})
export class GroupsModule { }
