import {Component, ElementRef, EventEmitter, Input, ComponentFactoryResolver, ViewChild, HostBinding, HostListener, Renderer2} from '@angular/core';
import {Overlay} from './overlay/overlay.service';
import {ComponentProperties, OverlayProperties} from './overlay/interfaces';
import {EventService as OverlayEventService} from './overlay/event.service';
import {AdDirective} from './ad.directive';

@Component({
    selector: 'tooltip-container',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.sass']
})
export class TooltipComponent {
    tooltipPosition = {
        left: '',
        top: ''
    };
    _properties: OverlayProperties;
    minTimeout: number = 0;
    globalEventsSubscription;

    @HostListener('focusin', ['$event'])
    @HostListener('mouseenter', ['$event'])
    onMouseEnter(event) {
        if (!this.isTooltipDestroyed) {
            this.overlayEventService.emitChangeEvent({
                type: 'Focusin'
            });
        }
    }

    @HostListener('focusout')
    @HostListener('mouseleave')
    onMouseLeave() {
        this.overlayEventService.emitChangeEvent({
            type: 'Focusout'
        });
    }

    @HostBinding('style.top') hostTop: string;
    @HostBinding('style.left') hostLeft: string;
    @HostBinding('style.padding')
    get hostPadding() {
        return this.properties.metadata.padding;
    }
    @HostBinding('style.white-space')
    get hostWhiteSpace() {
        return this.properties.metadata.whiteSpace;
    }
    @HostBinding('style.border-radius')
    get hostBorderRadius() {
        return this.properties.metadata.borderRadius;
    }
    @HostBinding('style.box-shadow')
    get hostBoxShadow() {
        return this.properties.metadata.shadow ? this.properties.metadata.shadow : 'none';
    }
    @HostBinding('style.pointer-events')
    get hostPointerEvents() {
        return this.properties.metadata.pointerEvents;
    }
    @HostBinding('style.font-size')
    get hostFontSize() {
        return this.properties.metadata.fontSize;
    }
    @HostBinding('class.tooltip-light') hostLight: boolean;
    @HostBinding('class.tooltip-red') hostRed: boolean;
    @HostBinding('class.tooltip-blue') hostBlue: boolean;
    @HostBinding('class.tooltip-green') hostGreen: boolean;
    @HostBinding('class.tooltip-yellow') hostYellow: boolean;
    @HostBinding('class.tooltip-noarrow')
    get hostNoArrow() {
        return this.properties.metadata.noArrow;
    }

    @ViewChild(AdDirective, {static: true}) adHost: AdDirective;

    @Input() set overlayProperties(properties: OverlayProperties) {
        this._properties = properties;
    }

    get properties() {
        return this._properties;
    }

    get component() {
        return this.properties.childComponent;
    }

    get element() {
        return this.properties.metadata.element;
    }

    get tooltipText() {
        return this.properties.metadata.tooltipText;
    }

    get srcElement() {
        if (this.properties.metadata.event) {
            return this.properties.metadata.event.srcElement;
        }
    }

    get targetElement() {
        return this.properties.metadata.targetElement;
    }

    get placement() {
        return this.properties.metadata.placement;
    }

    get isAlignToCenter() {
        return this.properties.metadata.alignToCenter;
    }

    get isThemeLight() {
        return this.properties.metadata['theme'] === 'light';
    }

    get isArrow() {
        return !this.properties.metadata.noArrow; //this.isThemeLight && !this.hostNoArrow;
    }

    get isTooltipDestroyed() {
        return this.overlay.componentRefs[0] && this.overlay.componentRefs[0].hostView.destroyed;
    }

    get componentRef() {
        return this.overlay.componentRefs[0];
    }

    get autoPlacement() {
        return this.properties.metadata.autoPlacement;
    }

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        public overlay: Overlay,
        private overlayEventService: OverlayEventService) {

        this.globalEventsSubscription = this.overlayEventService.emitter.subscribe(
            (event) => {
                this.handleGlobalEvents(event);
            }
        );
    }

    ngOnInit() {
        setTimeout(() => {
            if (this.component) {
                this.loadComponent();
            } else if (this.element) {
                this.appendElement();
            } else if (this.tooltipText) {
                this.appendText();
            }

            this.setThemeClass();
            this.setPosition(this.properties);
            this.checkAndSetPosition();
        }, this.minTimeout);
    }

    loadComponent() {
        let adItem = this.properties;
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.childComponent);
        let viewContainerRef = this.adHost.viewContainerRef;
        viewContainerRef.clear();
        let componentRef = viewContainerRef.createComponent(componentFactory);
    }

    appendElement() {
        this.element.style.display = '';
        document.getElementsByTagName('tooltip-container')[0].appendChild(this.element);
    }

    appendText() {
        document.getElementsByTagName('tooltip-container')[0].innerHTML += this.tooltipText;
    }

    setPlacementClass(placement: string) {
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-' + placement);
    }

    setThemeDark() {
        this.hostLight = false;
        this.hostRed = false;
        this.hostBlue = false;
        this.hostGreen = false;
        this.hostYellow = false;
    }

    setThemeClass(): void {
        //this.hostLight = this.isThemeLight;
        this.setThemeDark();
        switch(this.properties.metadata['theme']) {
            case 'light':
                this.hostLight = true;
            break;
            case 'red':
                this.hostRed = true;
            break;
            case 'blue':
                this.hostBlue = true;
            break;
            case 'green':
                this.hostGreen = true;
            break;
            case 'yellow':
                this.hostYellow = true;
            break;
        }
    }

    getPositionProperty() {
        const element = this.srcElement || this.targetElement;

        if (this.properties.metadata.position) {
            return this.properties.metadata.position;
        }

        if (element) {
            return element.getBoundingClientRect();
        } else {
            return {
                left: this.properties.metadata.left,
                top: this.properties.metadata.top
            }
        }
    }

    checkAndSetPosition() {
        if (this.setPosition(this.placement)) {
            this.setPlacementClass(this.placement);
        } else {
            const placements = ['top-left', 'top', 'top-right', 'right-top', 'right', 'right-bottom', 'bottom-right', 'bottom', 'bottom-left', 'left-bottom', 'left', 'left-top',];
            let isPlacementSet;

            for (const placement of placements) {
                if (this.setPosition(placement)) {
                    this.setPlacementClass(placement);
                    isPlacementSet = true;
                    return;
                }
            }

            /* Set original placement */
            /*
            if (!isPlacementSet) {
                this.setPosition(this.placement, true);
                this.setPlacementClass(this.placement);
            }
            */
        }
    }

    setPosition(placement, disableAutoPlacement: boolean = false) {
        const arrowHeight = 8;
        const element = this.properties.metadata.position ? false : this.srcElement || this.targetElement;
        const elementPosition = this.getPositionProperty();
        const elementHeight = element ? element.offsetHeight : 0;
        const elementWidth = element ? element.offsetWidth: 0;
        const tooltipHeight = this.elementRef.nativeElement.clientHeight;
        const tooltipWidth = this.elementRef.nativeElement.clientWidth;
        const scrollY = window.pageYOffset;
        const offset = this.properties.metadata.offset + arrowHeight;
        const arrowOffset = 16;
        const arrowWidth = 16;
        const offsetArrowCenter = arrowOffset + arrowWidth / 2;
        let hostTop;
        let hostLeft;

        // Top - bottom
        if (placement === 'top' || 'top-left' || 'top-right') {
            hostTop = (elementPosition.top + scrollY) - (tooltipHeight + offset);
        }

        if (placement === 'top-right' || placement === 'bottom-right') {
            if (this.isAlignToCenter) {
                hostLeft = (elementPosition.left + elementWidth / 2 - offsetArrowCenter);
            } else {
                hostLeft = elementPosition.left;
            }
        }

        if (placement === 'top-left' || placement === 'bottom-left') {
            if (this.isAlignToCenter) {
                hostLeft = (elementPosition.left) - (tooltipWidth - elementWidth) - (elementWidth / 2 - offsetArrowCenter);
            } else {
                hostLeft = (elementPosition.left) - (tooltipWidth - elementWidth);
            }
        }

        if (placement === 'bottom' || placement === 'bottom-right' || placement === 'bottom-left') {
            hostTop = (elementPosition.top + scrollY) + elementHeight + offset;
        }

        if (placement === 'top' || placement === 'bottom') {
            hostLeft = (elementPosition.left + elementWidth / 2) - tooltipWidth / 2;
        }

        // Left - right
        if (placement === 'left' || placement === 'left-bottom' || placement === 'left-top') {
            hostLeft = elementPosition.left - tooltipWidth - offset;
        }

        if (placement === 'right' || placement === 'right-bottom' || placement === 'right-top') {
            hostLeft = elementPosition.left + elementWidth + offset;
        }

        if (placement === 'left' || placement === 'right') {
            hostTop = (elementPosition.top + scrollY) + elementHeight / 2 - tooltipHeight / 2;
        }

        if (placement === 'right-bottom' || placement === 'left-bottom') {
            if (this.isAlignToCenter) {
                hostTop = (elementPosition.top + scrollY) + (elementHeight / 2 - offsetArrowCenter);
            } else {
                hostTop = (elementPosition.top + scrollY);
            }
        }

        if (placement === 'right-top' || placement === 'left-top') {
            if (this.isAlignToCenter) {
                hostTop = (elementPosition.top + scrollY) + elementHeight - tooltipHeight - (elementHeight / 2 - offsetArrowCenter);
            } else {
                hostTop = (elementPosition.top + scrollY) + elementHeight - tooltipHeight;
            }
        }

        if (this.autoPlacement && !disableAutoPlacement) {
            let checkPositionResult = this.checkPosition({
                top: hostTop,
                left: hostLeft,
                width: tooltipWidth,
                height: tooltipHeight
            });

            if (!checkPositionResult) {
                 return false;
            }
        }

        this.hostTop = hostTop + 'px';
        this.hostLeft = hostLeft + 'px';
        return true;
    }

    checkPosition(properties: {
        top: number, 
        left: number, 
        width: number, 
        height: number
    }) {
        const scrollY = window.pageYOffset;

        const topEdge = properties.top - scrollY;
        const bottomEdge = properties.top + properties.height;
        const leftEdge = properties.left;
        const rightEdge = properties.left + properties.width;
        const bodyHeight = window.innerHeight + scrollY;
        const bodyWidth = document.body.clientWidth;

        if (topEdge < 0 || bottomEdge > bodyHeight || leftEdge < 0 || rightEdge > bodyWidth) {
            return false;
        } else {
            return true;
        }
    }

    handleGlobalEvents(event) {
        if (event.type === 'Change position'){
            this.changePosition(event.position);
        }
    }

    changePosition(position) {
        this.hostTop = position.top;
        this.hostLeft = position.left;
    }
}
