import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals/globals';
import { ImageCropInfo } from '../editor-tools/models/image-crop-info.model';
import { IDatabaseMedia } from '../media-storage/database-media.model';

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {

  constructor(private app: Globals, private http: HttpClient) {}

  /**
   * Saves an image to the cloud
   * @param imageName Image name
   * @param imageCropInfo Image Crop info that is generated after cropping the image on image-crop page
   */
  saveImage(imageName: string, imageCropInfo: ImageCropInfo): Promise<any> {
    return new Promise((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.app.user.token}`)
      };

      delete imageCropInfo.imageCroppedEvent.base64;

      const media: IDatabaseMedia = {
        name: imageName,
        type: 'image',
        imageCropInfo
      };

      this.http.post(`${this.app.apiEndpoint}/cropAndSaveImage?imageName=${imageName}`, media , header).subscribe(
        res => { console.log(res); resolve(res); },
        err => { reject(err); }
      );
    });
  }

  deleteImage(imageName: string): Promise<any> {
    return new Promise((resolve, reject) => {
    /**
     * Estamos utilizando uma nova abordagem para deletar as mídias. Ao invés de deletar em tempo real,
     * no lado do servidor faremos uma reciclagem através de uma rotina programada responsável por deletar
     * as mídias que não tem mas vínculo com nenhuma prancha.
     */
      resolve(true);
    });
  }

  /**
   * Saves an audio to the cloud
   * @param audioName Audio name
   * @param dataURL Audio data URL in base 64
   */
  saveAudioDataUrl(audioName: string, dataURL: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.app.user.token}`)
      };

      this.http.post(`${this.app.apiEndpoint}/uploadAudio?audioName=${audioName}`, dataURL , header).subscribe(
        res => { resolve(audioName); },
        err => { reject(err); }
      );
    });
  }

  deleteAudio(audioName: string): Promise<any> {
    const header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.app.user.token}`)
    };

    return new Promise((resolve, reject) => {
      // Estamos utilizando uma nova abordagem para deletar as mídias. Ao invés de deletar em tempo real,
      // no lado do servidor faremos uma reciclagem através de uma rotina programada responsável por deletar
      // as mídias que não tem mas vínculo com nenhuma prancha
      resolve(true);
    });
  }

  resolveImageSrc(imgName: string): string {
    if (imgName) {
      return this.app.imageStorageLocation + imgName;
    } else {
      return '';
    }
  }

  resolveAudioSrc(audioName: string): string {
    if (typeof audioName === 'undefined' || audioName === '') {
      return '';
    } else {
      return this.app.audioStorageLocation + audioName;
    }
  }
}
