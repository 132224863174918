import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageSearchV2Component } from './image-search-v2/image-search-v2.component';
import { ImageCropV2Component } from './image-crop-v2/image-crop-v2.component';
import { ImageCropPageModule } from './image-crop/image-crop.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageSourceMorePopoverComponent } from './image-source-more-popover/image-source-more-popover.component';

@NgModule({
  declarations: [
    ImageSearchV2Component,
    ImageCropV2Component,
    ImageSourceMorePopoverComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ImageCropperModule,
    ImageCropPageModule
  ]
})
export class EditorToolsModule { }
