import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { LanguageService } from 'src/app/language/language.service';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';
import { Invite } from '../invite/invite.model';

@Component({
  selector: 'app-invite-request-popup',
  templateUrl: './invite-request-popup.component.html',
  styleUrls: ['./invite-request-popup.component.scss'],
})
export class InviteRequestPopupComponent implements OnInit {

  @Input() invite: Invite;
  @Input () alreadyInContactList = false;
  avatarSrc = '';

  constructor(private mediaStorageService: MediaStorageService, private modalController: ModalController, public lang: LanguageService,
              private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.avatarSrc = this.mediaStorageService.resolveImageSrc(this.invite.from.photo);
    if (!this.alreadyInContactList) {
      this.analyticsService.logEvent('invite_contact_view', { id: this.invite.id });
    }
  }

  async onAcceptInviteBtnClicked() {
    this.analyticsService.logEvent('invite_contact_accept_btn_click', { id: this.invite.id });
    this.modalController.dismiss('accept');
  }

  async onRefuseInviteBtnClicked() {
    this.analyticsService.logEvent('invite_contact_refuse_btn_click', { id: this.invite.id });
    this.modalController.dismiss('refuse');
  }

  async onOkBtnClicked() {
    this.modalController.dismiss();
  }
}
