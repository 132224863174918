import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import { Globals } from '../globals/globals';
import { Invite } from '../invites/invite/invite.model';
import { NotificationInApp } from './notification.model';
// tslint:disable:object-literal-shorthand

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationsBS = new BehaviorSubject<NotificationInApp[]>([]);
  notifications = this.notificationsBS.asObservable();
  notificationsSnapshot: NotificationInApp[];

  private newActivitiesInSharedSpacesBS = new BehaviorSubject<NotificationInApp[]>([]);
  newActivitiesInSharedSpaces = this.newActivitiesInSharedSpacesBS.asObservable();

  private newContactsBS = new BehaviorSubject<NotificationInApp[]>([]);
  newContacts = this.newContactsBS.asObservable();

  private newActivitiesResultsBS = new BehaviorSubject<NotificationInApp[]>([]);
  newActivitiesResults = this.newActivitiesResultsBS.asObservable();

  private targetsWithNewTagBS = new BehaviorSubject<any>({});
  targetsWithNewTagObs = this.targetsWithNewTagBS.asObservable();
  targetsWithNewTagObj = {};

  private activitiesResultsNotifCountBS = new BehaviorSubject<any>({});
  activitiesResultsNotifCountObs = this.activitiesResultsNotifCountBS.asObservable();
  activitiesResultsNotifCount = {};

  private notificationsSub: Subscription;

  private newActivitiesInStoreBS = new BehaviorSubject<ActivityBoard[]>([]);
  newActivitiesInStore = this.newActivitiesInStoreBS.asObservable();

  constructor(private firestore: AngularFirestore, private app: Globals, private fns: AngularFireFunctions) { }

  watchNotifications(uid: string) {
    if (this.notificationsSub) {
      this.notificationsSub.unsubscribe();
    }

    console.log(this.app.user.uid);

    this.notificationsSub = this.firestore.collection<NotificationInApp>('notifications', ref => ref.where('uid', '==', this.app.user.uid))
      .valueChanges().subscribe(notifications => {
        this.processNotifications(notifications);
        this.notificationsBS.next(notifications);
        this.notificationsSnapshot = notifications;
        this.newActivitiesInSharedSpacesBS.next(notifications.filter(notification => notification.type === 'new-shared-space-activity'));
        this.newActivitiesResultsBS.next(notifications.filter(notification => notification.type === 'new-shared-space-activity-result'));
        this.newContactsBS.next(notifications.filter(notification => notification.type === 'new-shared-space-contact'));
    });
  }

  private processNotifications(notifications: NotificationInApp[]) {
    const targetsWithNewTagObj = {};
    notifications.filter(notification => notification.type === 'new-shared-space-activity').forEach(notification => {
      targetsWithNewTagObj[notification.activityId] = true;
      notification.path.forEach(boardId => {
        targetsWithNewTagObj[boardId] = true;
      });
    });

    this.newActivitiesInStoreBS.value.forEach(activity => {
      targetsWithNewTagObj[activity._id] = true;
    });

    const activitiesResultsNotifCount = {};
    notifications.filter(notification => notification.type === 'new-shared-space-activity-result').forEach(notification => {
      if (!activitiesResultsNotifCount[notification.activityId]) {
        activitiesResultsNotifCount[notification.activityId] = 1;
      } else {
        activitiesResultsNotifCount[notification.activityId]++;
      }
      targetsWithNewTagObj[notification.targetId] = true;
    });

    this.targetsWithNewTagObj = targetsWithNewTagObj;
    this.targetsWithNewTagBS.next(targetsWithNewTagObj);
    this.activitiesResultsNotifCount = activitiesResultsNotifCount;
    this.activitiesResultsNotifCountBS.next(activitiesResultsNotifCount);
  }

  createNewContactNotification(invite: Invite) {
    let sharedSpaceId = '';
    console.log(invite);
    if (invite.from.uid < this.app.user.uid) {
        sharedSpaceId = invite.from.uid + this.app.user.uid;
    } else {
        sharedSpaceId = this.app.user.uid + invite.from.uid;
    }

    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uid: this.app.user.uid,
      type: 'new-shared-space-contact',

      sharedSpaceId: sharedSpaceId,
      targetId: sharedSpaceId
    };

    this.processNotificationRequest(notificationRequest);
  }

  createNewGroupNotification(groupId: string) {
    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uid: this.app.user.uid,
      type: 'new-shared-space-contact',
      sharedSpaceId: groupId,
      targetId: groupId
    };

    this.processNotificationRequest(notificationRequest);
  }

  createNewParticipantsNotification(groupId: string, uids = []) {
    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uids: uids,
      type: 'new-participants',
      sharedSpaceId: groupId,
      targetId: groupId
    };

    this.processNotificationRequest(notificationRequest);
  }

  newSharedSpaceActivity(activityId: string, boardRoutes: ActivityBoard[]) {
    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uid: this.app.user.uid,
      type: 'new-shared-space-activity',
      sharedSpaceId: boardRoutes[0]._id,
      path: boardRoutes.map(board => board._id),
      activityId: activityId,
      targetId: activityId
    };

    this.processNotificationRequest(notificationRequest);
  }

  createNewActivityResultNotification(resultId: string, activityId: string, sharedSpaceId: string, uid: string) {
    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uid: uid,
      type: 'new-shared-space-activity-result',
      sharedSpaceId: sharedSpaceId,
      activityId: activityId,
      targetId: resultId
    };

    this.processNotificationRequest(notificationRequest);
  }

  clearActivityNotifications(activityId: string, uid = '') {
    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uid: uid,
      type: 'clear-activity-notifications',
      activityId: activityId,
      targetId: activityId
    };

    this.processNotificationRequest(notificationRequest);
  }

  clearSharedSpaceNotifications(sharedSpaceId: string, uid = '') {
    const notificationRequest: NotificationInApp = {
      id: this.app.firebaseCreateId(),
      uid: uid,
      type: 'clear-shared-space-notifications',
      sharedSpaceId: sharedSpaceId,
      targetId: sharedSpaceId
    };

    this.processNotificationRequest(notificationRequest);
  }

  clearActivityResultsNotifications(activityId: string) {
    const notifications = this.notificationsSnapshot.filter(notif => notif.activityId === activityId &&
       notif.type === 'new-shared-space-activity-result');
    for (const notification of notifications) {
      this.firestore.collection('notifications').doc(notification.id).delete();
    }
  }

  clearNewActivitiesInStoreNotifications() {
    for (const activity of this.newActivitiesInStoreBS.value) {
      this.targetsWithNewTagObj[activity._id] = false;
    }

    this.newActivitiesInStoreBS.next([]);
  }

  clearNotificationByTarget(targetId: string) {
    const notifications = this.notificationsSnapshot.filter(notif => notif.targetId === targetId);
    for (const notification of notifications) {
      this.firestore.collection('notifications').doc(notification.id).delete();
    }
  }

  clearNotification(targetId: string, type: string) {
    const notifications = this.notificationsSnapshot.filter(notif => notif.type === type && notif.targetId === targetId);
    for (const notification of notifications) {
      this.firestore.collection('notifications').doc(notification.id).delete();
    }
  }

  refreshNewActivitiesInStore(activities: ActivityBoard[]) {
    this.app.getUserIdentity().then(userIdentity => {
      this.fns.httpsCallable('getLastAnalyticsEvent')
      ({ eventName: 'activity_store_page', userIdentity: userIdentity }).toPromise()
      .then(ev => {
        const timestamp = ev ? ev.timestamp : Date.now() - 15 * 24 * 60 * 60 * 1000; // 15 dias para trás
        const newActivities = activities.filter(activity => activity.createdAt >= timestamp);

        newActivities.forEach(activity => {
          this.targetsWithNewTagObj[activity._id] = true;
        });

        this.newActivitiesInStoreBS.next(newActivities);
      })
      .catch(err => {
        console.log(err);
      });
    });
  }

  private processNotificationRequest(notification: NotificationInApp) {
    this.firestore.collection('notifications-manager').doc(notification.id).set(notification);
  }
}
