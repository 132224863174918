import { Injectable } from '@angular/core';
import { User } from '../../user.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class UserDataApiService {

  constructor(private fns: AngularFireFunctions) { }

  /**
   * Gets the user doc that are stored in cloud
   * @returns user
   */
  getUserDoc(): Promise<{ user: User, serverTimestamp: number }>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('getUserDoc')({}).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }

  /**
   * Gets the user geolocation
   * @returns user
   */
  findUserGeolocation(): Promise<{ user: User, serverTimestamp: number }>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('findUserGeolocation')({}).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }
}
