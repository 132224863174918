import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ImageCropInfo } from '../../models/image-crop-info.model';

@Component({
  selector: 'app-cropped-img',
  templateUrl: './cropped-img.component.html',
  styleUrls: ['./cropped-img.component.scss'],
})
export class CroppedImgComponent implements OnInit, OnChanges {

  @Input() imageCropInfo: ImageCropInfo;
  @Input() height = 0;
  @Input() width = 0;
  @Input() widthOffset = 0;
  @Input() imgTopOffset = 0;
  @Input() imgLeftOffset = 0;
  @Input() marginLeft: 0;
  @Input() marginTop = 0;
  @Input() marginBottom: number;
  @Input() background = 'white';
  @Input() borderColor = '';
  @Input() borderEnabled = true;

  @Output() load = new EventEmitter();
  @Output() error = new EventEmitter();

  imgTopProp = 0;
  imgLeftProp = 0;
  imgWidth = 0;
  imgHeight = 0;
  imgSrc = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    console.log('ngOnChanges on cropped-img');
    if (this.imageCropInfo) {
      const k = (this.width + this.widthOffset) / this.imageCropInfo.imageCroppedEvent.width;

      this.imgTopProp = - this.imageCropInfo.imageCroppedEvent.imagePosition.y1 * k +
        (this.imageCropInfo.imagePreviewVerticalPadding / 2) * k + this.imgTopOffset;

      this.imgLeftProp = - this.imageCropInfo.imageCroppedEvent.imagePosition.x1 * k +
        (this.imageCropInfo.imagePreviewHorizontalPadding / 2) * k + this.imgLeftOffset;

      this.imgWidth = this.imageCropInfo.imagePreviewWidth * k;
      this.imgHeight = this.imageCropInfo.imagePreviewHeight * k;
      this.imgSrc = this.imageCropInfo.imageUrl;
    }
  }

  onImgLoad(ev: any) {
    this.load.emit(ev);
  }

  onImgError(ev: any) {
    this.error.emit(ev);
  }
}
