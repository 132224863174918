import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { InvitesPageRoutingModule } from './invites-routing.module';

import { InvitesPage } from './invites.page';
import { InviteComponent } from './invite/invite.component';
import { InviteRequestPopupComponent } from './invite-request-popup/invite-request-popup.component';

// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons'; // TODO UPDATE ANGULAR
// import { ShareIconsModule } from 'ngx-sharebuttons/icons'; // TODO UPDATE ANGULAR
import { Platform } from '@angular/cdk/platform';
import { Clipboard } from '@angular/cdk/clipboard';
import { Clipboard as ClipboardNative } from '@ionic-native/clipboard/ngx';
// import { ShareService } from 'ngx-sharebuttons'; // TODO UPDATE ANGULAR
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        InvitesPageRoutingModule,
        ReactiveFormsModule,
        // ShareButtonsModule,
        // ShareIconsModule
    ],
    providers: [
        Platform,
        // ShareService,
        Clipboard,
        ClipboardNative,
        FaConfig,
        FaIconLibrary
    ],
    declarations: [
        InvitesPage,
        InviteComponent,
        InviteRequestPopupComponent
    ],
    exports: [
        InviteComponent
    ]
})
export class InvitesPageModule {}
