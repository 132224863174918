import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'src/app/globals/globals';
import { AudioRecord } from './audio-record.model';
import AudioRecorder from 'audio-recorder-polyfill';

declare var MediaRecorder: any;

@Injectable({
  providedIn: 'root',
})
export class AudioRecorderService {

  audioInputAvailable = false;
  audioStream: MediaStream;
  recorder: any;

  constructor(private app: Globals, private sanitizer: DomSanitizer) {}

  initAudioRecorder() {
    if (this.app.isRunningOnAndroidApp()) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.audioStream = stream;
        this.audioInputAvailable = true;
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  finishAudioRecorder() {
    // Fecha todos streams de audio
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => {
        if (track.readyState === 'live' && track.kind === 'audio') {
            track.stop();
        }
      });
    }
  }

  startRecording(): Promise<AudioRecord> {
    return new Promise(async (resolve, reject) => {

      await this.checkAudioInputAvailability();

      if (this.app.isRunningOnAndroidApp()) {
        if (this.audioInputAvailable) {
          this.recordAudio()
            .then((audioRecord: AudioRecord) => { resolve(audioRecord); })
            .catch((err) => reject(err));
        } else {
          resolve(null);
        }
      } else {

        if (!this.audioInputAvailable) { resolve(null); }

        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          this.audioStream = stream;

          this.recordAudio()
            .then((audioRecord: AudioRecord) => { resolve(audioRecord); })
            .catch((err) => reject(err));
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  async checkAudioInputAvailability() {
    // Se a entrada de audio não estiver disponível, listamos os dispositivos de media para verificar se
    // o usuário permitiu o uso do microfone
    if (!this.audioInputAvailable) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        this.audioInputAvailable = true;
      } else {
        const devices = await navigator.mediaDevices.enumerateDevices();
        devices.forEach((device) => {
          if (device.kind === 'audioinput' && device.label !== '') {
            this.audioInputAvailable = true;
          }
        });
      }
    }
  }

  private recordAudio(): Promise<AudioRecord> {
    return new Promise((resolve, reject) => {
      // Uses Audio recorder pollyfill to record audio on ios devices. It records the audio in wav. This is a sort of fallback to
      // allows us record audio on Safari since it doesn't support Media Recorder yet.
      if (this.app.isRunningOnIos) {
        this.recorder = new AudioRecorder(this.audioStream);
      } else {
        // Uses Media Recorder to record the audio in the majority of the devices. It's the best an efficient way
        // of recording audio.
        this.recorder = new MediaRecorder(this.audioStream);
      }

      this.recorder.start();

      // Impede que a gravação se inicie logo após o usuário aceitar a permisão para usar o microfone.
      // A gravação só começará quando o usuário estiver de fato segurando o click ou tocando no botão de gravação.
      if (!this.audioInputAvailable) {
        this.stopRecording();
        reject();
        return;
      }

      this.recorder.addEventListener('dataavailable', async (e: any) => {
        const audioBlob = e.data;
        const audioURL = URL.createObjectURL(audioBlob);
        const audioURLBypassed = this.sanitizer.bypassSecurityTrustUrl(audioURL);

        console.log(audioURL);
        console.log(audioBlob);



        resolve(new AudioRecord(audioURL, audioBlob));
      });

      this.recorder.addEventListener('error', (err: any) => {
        console.log(err);
        reject(err);
      });
      console.log('Recording started');
    });
  }

  stopRecording() {
    // Apenas conclui a gravação se ela tiver sido iniciada
    if (this.recorder) {
      this.recorder.stop();
    }

    if (!this.app.isRunningOnAndroidApp()) {
      this.finishAudioRecorder();
    }
  }
}
