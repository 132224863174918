import { Injectable } from '@angular/core';
import { AacService } from '../aac/aac.service';
import { ActivitiesService } from '../activities/activities.service';
import { Globals } from '../globals/globals';
import { SampleBoardsService } from '../helpers/sample-boards.service';
import { NativeStorageService } from '../native-storage/native-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MediaSyncService {

  sampleMedias = [];
  nativeImages = [];
  nativeAudios = [];

  deviceMediasSyncQueue = [];
  cloudMediasSyncQueue = [];

  refreshNativeMediasTimeout = null;

  constructor(public app: Globals, public nativeStorageService: NativeStorageService, public aacService: AacService,
              public activitiesService: ActivitiesService, public sampleBoardsService: SampleBoardsService) {
    // this.initSync();
  }

  async initSync() {
    this.sampleMedias = this.sampleBoardsService.listSamplesMedias();
    this.nativeImages = this.nativeStorageService.nativeImages;
    this.nativeAudios = this.nativeStorageService.nativeAudios;
  }

  sync() {
    if (this.app.isRunningOnNative()) {
      this.syncDevice();
      this.syncCloud();
    }
  }

  async syncDevice() {
    const sampleMedias = [...this.sampleMedias];
    const nativeMedias = [...this.nativeImages].concat([...this.nativeAudios]);

    const aacMedias = this.aacService.listAacMedias();
    const activitiesMedias = this.activitiesService.listActivitiesMedias();

    const syncedMedias = sampleMedias.concat(nativeMedias);

    const aacMediasNotSynced = aacMedias.filter(media => syncedMedias.findIndex(syncedMedia => media === syncedMedia ) < 0);

    const activitiesMediasNotSynced = activitiesMedias.filter(media => syncedMedias.findIndex(syncedMedia => media === syncedMedia ) < 0);

    const mediasNotSynced = aacMediasNotSynced.concat(activitiesMediasNotSynced);

    this.deviceMediasSyncQueue = mediasNotSynced;

    this.syncRefresh();
  }

  syncCloud() {
    this.nativeStorageService.uploadMedias();
  }

  async syncRefresh() {
    for (const media of this.deviceMediasSyncQueue) {
      this.nativeStorageService.donwloadMedia(media).then(() => {
        const index = this.deviceMediasSyncQueue.findIndex(m => m === media);
        this.deviceMediasSyncQueue.splice(index, 1);

        clearTimeout(this.refreshNativeMediasTimeout);
        this.refreshNativeMediasTimeout = setTimeout(() => {
          this.nativeStorageService.initNativeImages();
          this.nativeStorageService.initNativeAudios();
        }, 2000);
      });
    }
  }
}
