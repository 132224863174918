import { Injectable } from '@angular/core';
import { ImageCropInfo } from '../editor-tools/models/image-crop-info.model';
import { Globals } from '../globals/globals';
import { StorageService } from '../storage/storage.service';
import * as uuid from 'uuid';
import { Capacitor } from '@capacitor/core';
import { File } from '@ionic-native/file/ngx';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NativeStorageService {

  nativeImages = [];
  nativeAudios = [];

  constructor(public app: Globals, public storage: StorageService, private file: File, private http: HttpClient) {
    // this.initNativeImages();
    // this.initNativeAudios();
  }

  async initNativeImages() {
    try {
      this.nativeImages = await this.listNativeImages();
    } catch (err) {
      console.log(err);
    }
  }

  async initNativeAudios() {
    try {
      this.nativeAudios = await this.listNativeAudios();
    } catch (err) {
      console.log(err);
    }
  }

  saveImage(imageCropInfo: ImageCropInfo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.file.checkDir(this.file.externalApplicationStorageDirectory, 'board-images');
      } catch (err) {
        try {
          await this.file.createDir(this.file.externalApplicationStorageDirectory, 'board-images', false);
        } catch (err) {
          console.log(err);
          reject(err);
          return;
        }
      }

      try {
        const imageName = uuid.v4() + '.png';

        await this.writeImage(imageCropInfo.imageCroppedEvent.base64, imageName);

        let imageSyncQueue = await this.storage.getObject('image-sync-queue');
        let nativeImages = await this.storage.getObject('native-images');

        if (typeof imageSyncQueue === 'undefined' || !imageSyncQueue) { imageSyncQueue = []; }
        if (typeof nativeImages === 'undefined' || !nativeImages) { nativeImages = []; }

        imageSyncQueue.push(imageName);
        nativeImages.push(imageName);
        this.nativeImages.push(imageName);

        await this.storage.setObject('image-sync-queue', imageSyncQueue);
        await this.storage.setObject('native-images', nativeImages);

        resolve(imageName);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  deleteImage(imageName: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.storage.remove(imageName);

      const imageSyncQueue = await this.storage.getObject('image-sync-queue');
      if (typeof imageSyncQueue !== 'undefined' && imageSyncQueue) {
        const index = imageSyncQueue.findIndex(name => name === imageName);
        if (index >= 0) { imageSyncQueue.splice(index, 1); }
      }

      const nativeImages = await this.storage.getObject('native-images');
      if (typeof nativeImages !== 'undefined' && nativeImages) {
        const index = nativeImages.findIndex(name => name === imageName);
        if (index >= 0) { nativeImages.splice(index, 1); this.nativeImages.splice(index, 1); }
      }

      await this.storage.setObject('image-sync-queue', imageSyncQueue);
      await this.storage.setObject('native-images', nativeImages);

      resolve();
    });
  }

  saveAudio(audioBlob: Blob): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.file.checkDir(this.file.externalApplicationStorageDirectory, 'board-audios');
      } catch (err) {
        try {
          await this.file.createDir(this.file.externalApplicationStorageDirectory, 'board-audios', false);
        } catch (err) {
          console.log(err);
          reject(err);
          return;
        }
      }

      try {
        const audioName = uuid.v4() + '.mp3';

        await this.writeAudio(audioBlob, audioName);

        let audioSyncQueue = await this.storage.getObject('audio-sync-queue');
        let nativeAudios = await this.storage.getObject('native-audios');

        if (typeof audioSyncQueue === 'undefined' || !audioSyncQueue) { audioSyncQueue = []; }
        if (typeof nativeAudios === 'undefined' || !nativeAudios) { nativeAudios = []; }

        audioSyncQueue.push(audioName);
        nativeAudios.push(audioName);
        this.nativeAudios.push(audioName);

        await this.storage.setObject('audio-sync-queue', audioSyncQueue);
        await this.storage.setObject('native-audios', nativeAudios);

        resolve(audioName);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  deleteAudio(audioName: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.storage.remove(audioName);

      const audioSyncQueue = await this.storage.getObject('audio-sync-queue');
      if (typeof audioSyncQueue !== 'undefined' && audioSyncQueue) {
        const index = audioSyncQueue.findIndex(name => name === audioName);
        if (index >= 0) { audioSyncQueue.splice(index, 1); }
      }

      const nativeAudios = await this.storage.getObject('native-audios');
      if (typeof nativeAudios !== 'undefined' && nativeAudios) {
        const index = nativeAudios.findIndex(name => name === audioName);
        if (index >= 0) { nativeAudios.splice(index, 1); this.nativeAudios.splice(index, 1); }
      }

      await this.storage.setObject('audio-sync-queue', audioSyncQueue);
      await this.storage.setObject('native-audios', nativeAudios);
      resolve();
    });
  }

  resolveMedia(mediaName: string) {
    if (typeof mediaName === 'undefined' || mediaName === '') { return ''; }

    return (Capacitor.convertFileSrc(this.file.dataDirectory + 'medias/' + mediaName));
  }

  resolveImageSrc(imgName: string): string {
    if (typeof imgName === 'undefined' || imgName === '') { return ''; }

    if (this.nativeImages.findIndex(img => img === imgName) >= 0) {
      return (Capacitor.convertFileSrc(this.file.externalApplicationStorageDirectory + 'board-images/' + imgName));
    } else {
      return (Capacitor.convertFileSrc('../../../assets/board-images/' + imgName));
    }
  }

  resolveAudioSrc(audioName: string): string {
    if (typeof audioName === 'undefined' || audioName === '') { return ''; }

    if (this.nativeAudios.findIndex(audio => audio === audioName) >= 0) {
      return (Capacitor.convertFileSrc(this.file.externalApplicationStorageDirectory + 'board-audios/' + audioName));
    } else {
      return (Capacitor.convertFileSrc('../../../assets/board-audios/' + audioName));
    }
  }

  listNativeImages(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const nativeImg = (await this.file.listDir(this.file.externalApplicationStorageDirectory, 'board-images')).map(file => file.name);
        resolve(nativeImg);
      } catch (err) {
        reject(err);
      }
    });
  }

  listNativeAudios(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const path = this.file.externalApplicationStorageDirectory;
        const nativeAudios = (await this.file.listDir(path, 'board-audios')).map(file => file.name);
        resolve(nativeAudios);
      } catch (err) {
        reject(err);
      }
    });
  }

  listNativeMediasLegacy(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let nativeImages = [];
      let nativeAudios = [];
      try {
        nativeImages = (await this.file.listDir(this.file.externalApplicationStorageDirectory, 'board-images')).map(file => file.name);
        nativeAudios = (await this.file.listDir(this.file.externalApplicationStorageDirectory, 'board-audios')).map(file => file.name);
        resolve(nativeImages.concat(nativeAudios));
      } catch (err) {
        console.log(err);
        resolve(nativeImages.concat(nativeAudios));
      }
    });
  }

  listNativeMedias(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.file.checkDir(this.file.dataDirectory, 'medias');
      } catch (err) {
        try {
          await this.file.createDir(this.file.dataDirectory, 'medias', false);
        } catch (err) {
          reject(err);
          return;
        }
      }

      this.file.listDir(this.file.dataDirectory, 'medias').then(files => {
        resolve(files.map(file => file.name));
      }).catch(err => {
        reject(err);
      });
    });
  }

  async saveFile(path: string, fileName: string, blob: Blob) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.file.checkDir(this.file.dataDirectory, path);
      } catch (err) {
        try {
          await this.file.createDir(this.file.dataDirectory, path, false);
        } catch (err) {
          reject(err);
          return;
        }
      }

      const filePath = this.file.dataDirectory + path;
      try {
        this.file.writeFile(filePath, fileName, blob)
          .then(() => resolve(Capacitor.convertFileSrc(this.file.dataDirectory + path + '/' + fileName)))
          .catch(err => reject(err));
      } catch (err) {
        reject(err);
      }
    });
  }

  donwloadMedia(mediaName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let mediaUrl = '';
      let mediaType = '';
      if (mediaName.includes('.ogg') || mediaName.includes('.mp3') || mediaName.includes('.wav')) {
        mediaUrl = this.app.audioStorageLocation + mediaName;
        mediaType = 'audio';
      } else {
        mediaUrl = this.app.imageStorageLocation + mediaName;
        mediaType = 'image';
      }

      this.http.get(mediaUrl, { responseType: 'blob' }).subscribe(
        async data => {
          if (mediaType === 'image') {
            try {
              await this.writeImageFromBlob(data, mediaName);
              resolve();
            } catch (err) {
              console.log(err);
              reject();
            }
          } else {
            try {
              await this.writeAudio(data, mediaName);
              resolve();
            } catch (err) {
              console.log(err);
              reject();
            }
          }
        },
        err => {
          console.log(err);
          reject();
        }
      );
    });
  }

  async uploadMedias() {
    let imageSyncQueue = [];
    let audioSyncQueue = [];

    try {
      const imageQueue = await this.storage.getObject('image-sync-queue');
      if (typeof imageQueue !== 'undefined' && imageQueue) {
        imageSyncQueue = imageQueue;
      }

      const audioQueue = await this.storage.getObject('audio-sync-queue');
      if (typeof audioQueue !== 'undefined' && audioQueue) {
        audioSyncQueue = audioQueue;
      }

    } catch (err) {
      console.log(err);
    }

    const mediaSyncQueue = imageSyncQueue.concat(audioSyncQueue);

    for (const media of mediaSyncQueue) {
      this.uploadMedia(media).then(() => console.log('Media ' + media + 'salva na núvem!'))
        .catch((err) => console.log('Falha ao salvar midia ' + media));
    }
  }

  uploadMedia(mediaName: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      console.log('Trying to upload media ' + mediaName);
      let cloudUrl = '';
      let nativeUrl = '';
      let mediaType = '';
      let mediaPath = '';

      if (mediaName.includes('.ogg') || mediaName.includes('.mp3') || mediaName.includes('.wav')) {
        cloudUrl = this.app.cloudStorageEndpoint + '/audios?audioName=' + mediaName;
        mediaType = 'audio';
        mediaPath = this.file.externalApplicationStorageDirectory + 'board-audios';
      } else {
        cloudUrl = this.app.cloudStorageEndpoint + '/images2?imageName=' + mediaName;
        mediaType = 'image';
        mediaPath = this.file.externalApplicationStorageDirectory + 'board-images';
        nativeUrl = Capacitor.convertFileSrc(this.file.externalApplicationStorageDirectory + 'board-images/' + mediaName);
      }

      this.http.get(nativeUrl, { responseType: 'blob' }).subscribe(
        async data => {
          this.http.post(cloudUrl, data).subscribe(
            async res => {
              if (mediaType === 'image') {
                try {
                  const imageSyncQueue = await this.storage.getObject('image-sync-queue');
                  if (typeof imageSyncQueue !== 'undefined' && imageSyncQueue) {
                    const index = imageSyncQueue.findIndex(name => name === mediaName);
                    if (index >= 0) { imageSyncQueue.splice(index, 1); }
                  }
                  await this.storage.setObject('image-sync-queue', imageSyncQueue);
                  resolve();
                } catch (err) {
                  console.log(err);
                  reject();
                }
              } else {
                try {
                  const audioSyncQueue = await this.storage.getObject('audio-sync-queue');
                  if (typeof audioSyncQueue !== 'undefined' && audioSyncQueue) {
                    const index = audioSyncQueue.findIndex(name => name === mediaName);
                    if (index >= 0) { audioSyncQueue.splice(index, 1); }
                  }
                  await this.storage.setObject('audio-sync-queue', audioSyncQueue);
                  resolve();
                } catch (err) {
                  console.log(err);
                  reject();
                }
              }
            },
            err => {
              console.log(err);
              reject();
            }
          );
        },
        err => {
          console.log(err);
          reject();
        }
      );
    });
  }

  private async writeImage(base64Data: any, fileName: any) {
    const filePath = this.file.externalApplicationStorageDirectory + 'board-images';
    try {
      const res = await fetch(base64Data);
      const blob = await res.blob();
      await this.file.writeFile(filePath, fileName, blob);
    } catch (err) {
      console.log(err);
    }
  }

  private async writeImageFromBlob(blob: any, fileName: any) {

    try {
      await this.file.checkDir(this.file.externalApplicationStorageDirectory, 'board-images');
    } catch (err) {
      try {
        await this.file.createDir(this.file.externalApplicationStorageDirectory, 'board-images', false);
      } catch (err) {
        console.log(err);
        return;
      }
    }

    const filePath = this.file.externalApplicationStorageDirectory + 'board-images';
    try {
      await this.file.writeFile(filePath, fileName, blob);
    } catch (err) {
      console.log(err);
    }
  }

  private async writeAudio(blob: any, fileName: any) {

    try {
      await this.file.checkDir(this.file.externalApplicationStorageDirectory, 'board-audios');
    } catch (err) {
      try {
        await this.file.createDir(this.file.externalApplicationStorageDirectory, 'board-audios', false);
      } catch (err) {
        console.log(err);
        return;
      }
    }

    const filePath = this.file.externalApplicationStorageDirectory + 'board-audios';
    try {
      await this.file.writeFile(filePath, fileName, blob);
    } catch (err) {
      console.log(err);
    }
  }

  async writeFile(data: any, fileName: any) {
    const filePath = this.file.externalApplicationStorageDirectory;
    try {
      await this.file.writeFile(filePath, fileName, data);
    } catch (err) {
      console.log(err);
    }
  }

  async readJsonFile(fileName: any) {
    return new Promise((resolve, reject) => {
      const filePath = Capacitor.convertFileSrc(this.file.externalApplicationStorageDirectory + fileName);
      this.http.get(filePath).subscribe(
        (data: any) => resolve(data),
        (err) => reject(err)
      );
    });
  }
}
