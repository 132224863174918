import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OverlayModule} from './overlay/overlay.module';
import {TooltipComponent} from './tooltip.component';
import {AdDirective} from './ad.directive';
import {Tooltip} from './tooltip.service';
import {TooltipProperties} from './interfaces';
import {TooltipElementDirective} from './tooltip-element.directive';
import {TooltipTriggerDirective} from './tooltip-trigger.directive';
import {TooltipPropertiesService} from './tooltip-properties.service';

@NgModule({
    declarations: [
        TooltipComponent,
        AdDirective,
        TooltipElementDirective,
        TooltipTriggerDirective
    ],
    imports: [
        CommonModule,
        OverlayModule
    ],
    exports: [
        TooltipElementDirective,
        TooltipTriggerDirective
    ],
    providers: [
        Tooltip
    ],
    bootstrap: []
})
export class TooltipModule {
    static forRoot(initProperties: TooltipProperties): ModuleWithProviders<TooltipModule> {
        return {
            ngModule: TooltipModule,
            providers: [
                {
                    provide: TooltipPropertiesService,
                    useValue: initProperties
                }
            ]
        };
    }
}
