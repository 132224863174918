import { Injectable } from '@angular/core';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import { CloudDatabaseService } from '../cloud-database/cloud-database.service';
import { Globals } from '../globals/globals';
import { AacBoard } from '../aac/aac-board/aac-board.model';
import { User } from '../user/user.model';
import { UserContact } from '../user/user-contacts/user-contact/user-contact.model';
import { ActivityBoardChange } from '../activities/activity-board/activity-board-change.model';
import { MarketingPopup } from '../marketing/marketing-popup/marketing-popup.model';
import { IDatabaseMedia } from '../media-storage/database-media.model';
import { Feedback } from '../home/feedback/feedback.model';
import { AacDataService } from '../aac/aac-data/aac-data.service';
import { UserDataService } from '../user/user-data/user-data.service';
import { ActivitiesDataService } from '../activities/activities-data/activities-data.service';

export interface ICreateAacBoardOpts {
  keepId?: boolean,
  noDispatchAfterCreation?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(public app: Globals, private cloudDatabaseService: CloudDatabaseService, private aacDataService: AacDataService,
    private userDataService: UserDataService, private activitiesDataService: ActivitiesDataService) { }

  createActivityBoard(board: ActivityBoard, parentBoard: ActivityBoard = null): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.createActivityBoard(board, parentBoard).then((createdBoard) => {
        this.activitiesDataService.onActivitiesBoardCreated(createdBoard, parentBoard);
        resolve(createdBoard);
      });
    });
  }

  updateActivityBoard(board: ActivityBoard, updates = null): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.updateActivityBoard(board, updates).then((updatedCard) => {
        this.activitiesDataService.onActivitiesBoardUpdated(updatedCard, updates); 
        resolve(updatedCard);
      });
    });
  }

  updateActivitiesBoards(boardChanges: ActivityBoardChange[]): Promise<any> {
    return this.cloudDatabaseService.updateActivitiesBoards(boardChanges);
  }

  updateActivityBoardProps(board: ActivityBoard, newProps: any): Promise<void> {
    return this.cloudDatabaseService.updateActivityBoardProps(board, newProps);
  }

  updateManyActivitiesBoards(boardChanges: ActivityBoardChange[]) {
    for (const change of boardChanges) {
      this.updateActivityBoard(change.board, change.changes);
    }
  }

  deleteActivityBoard(board: ActivityBoard, parentBoard: ActivityBoard, innerCards: ActivityBoard[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.deleteActivityBoard(board, parentBoard, innerCards).then(() => {
        this.activitiesDataService.onActivitiesBoardDeleted(board, parentBoard, innerCards);
        resolve();
      });
    });
  }

  createActivityCard(card: ActivityBoard, parentBoard: ActivityBoard): Promise<ActivityBoard> {
    // Criar um cartão é exatemente o mesmo processo de adicionar um item na coleção das pranchas de atividades
    return this.createActivityBoard(card, parentBoard);
  }

  updateActivityCard(card: ActivityBoard): Promise<ActivityBoard> {
    // Atualizar um cartão é exatemente o mesmo processo de atualizar um item na coleção das pranchas de atividades
    return this.updateActivityBoard(card);
  }

  deleteActivityCard(card: ActivityBoard, parentBoard: ActivityBoard, innerCards: ActivityBoard[]): Promise<any> {
    // Deletar um cartão é exatemente o mesmo processo de excluir um item da coleção das pranchas de atividades
    return this.deleteActivityBoard(card, parentBoard, innerCards);
  }

  createAacBoard(board: AacBoard, parentBoard: AacBoard = null, opts: ICreateAacBoardOpts = {}): Promise<AacBoard> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.createAacBoard(board, parentBoard, opts).then((createdBoard) => {
        this.aacDataService.onAacBoardCreated(createdBoard, parentBoard, opts);
        resolve(createdBoard);
      });
    });
  }

  updateAacBoard(board: AacBoard, updates = null): Promise<AacBoard> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.updateAacBoard(board, updates).then((updatedCard) => {
        console.log('UPDATES => ', updates);
        console.log('updatedCard => ', updatedCard);
        this.aacDataService.onAacBoardUpdated(updatedCard, updates); 
        resolve(updatedCard);
      });
    });
  }

  deleteAacBoard(board: AacBoard, parentBoard: AacBoard, innerCards: AacBoard[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.deleteAacBoard(board, parentBoard, innerCards).then(() => {
        this.aacDataService.onAacBoardDeleted(board, parentBoard, innerCards);
        resolve();
      });
    });
  }

  createAacCard(card: AacBoard, parentBoard: AacBoard = null, opts: ICreateAacBoardOpts = {}): Promise<any> {
    // Criar um cartão é exatemente o mesmo processo de adicionar um item na coleção das pranchas de comunicacao
    return this.createAacBoard(card, parentBoard, opts);
  }

  updateAacCard(card: AacBoard): Promise<AacBoard> {
    // Atualizar um cartão é exatemente o mesmo processo de atualizar um item na coleção das pranchas de comunicacao
    return this.updateAacBoard(card);
  }

  deleteAacCard(card: AacBoard, parentBoard: AacBoard, innerCards: AacBoard[]): Promise<any> {
    // Deletar um cartão é exatemente o mesmo processo de excluir um item da coleção das pranchas de atividades
    return this.deleteAacBoard(card, parentBoard, innerCards);
  }

  updateUserAsync(user: User): Promise<any> {
    return this.cloudDatabaseService.updateUserAsync(user);
  }

  updateUser(updates: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cloudDatabaseService.updateUser(updates).then(() => {
        this.userDataService.onUserUpdated(updates);
        resolve()
      });
    });
  }

  // Atualiza apenas o bloco de estatísticas
  updateStatsAsync(user: User): Promise<any> {
    return this.cloudDatabaseService.updateStatsAsync(user);
  }

  watchUserContacts(uid: string) {
    return this.cloudDatabaseService.watchUserContacts(uid);
  }

  updateUserContactAsync(uid: string, contact: UserContact): Promise<any> {
    return this.cloudDatabaseService.updateUserContactAsync(uid, contact);
  }

  setContactStatus(uid: string, contact: UserContact, newStatus: string) {
    return this.cloudDatabaseService.setContactStatus(uid, contact, newStatus);
  }

  updateSharedSpaceUserInfo(uid: string, sharedSpace: ActivityBoard, newUserInfo: any): Promise<void> {
    return this.cloudDatabaseService.updateSharedSpaceUserInfo(uid, sharedSpace, newUserInfo);
  }

  deleteUserProfilePhoto(user: User): Promise<void> {
    return this.cloudDatabaseService.deleteUserProfilePhoto(user);
  }

  deleteMedia(mediaName: string): Promise<void> {
    return this.cloudDatabaseService.deleteMedia(mediaName);
  }

  logEvent(eventName: string, eventParams?: any, paramName?: string): Promise<void> {
    return this.cloudDatabaseService.logEvent(eventName, eventParams, paramName);
  }

  getMarketingPopup(): Promise<MarketingPopup> {
    return this.cloudDatabaseService.getMarketingPopup();
  }

  createCloudDatabaseMedia(media: IDatabaseMedia): Promise<void> {
    return this.cloudDatabaseService.createCloudDatabaseMedia(media);
  }

  getCloudDatabaseMedia(mediaName: string): Promise<any> {
    return this.cloudDatabaseService.getCloudDatabaseMedia(mediaName);
  }

  deleteDescriptionPhoto(activity: ActivityBoard, user: User) {
    return this.cloudDatabaseService.deleteDescriptionPhoto(activity, user);
  }

  saveSpokenSentence(sentenceCards: AacBoard[]) {
    return this.cloudDatabaseService.saveSpokenSentence(sentenceCards);
  }

  updateSpokenSentence(sentence: any) {
    return this.cloudDatabaseService.updateSpokenSentence(sentence);
  }

  sendFeedback(feedback: Feedback) {
    return this.cloudDatabaseService.sendFeedback(feedback);
  }

  updateFeedback(feedback: Feedback) {
    return this.cloudDatabaseService.updateFeedback(feedback);
  }
}
