import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, NavController } from '@ionic/angular';
import { Globals } from '../globals/globals';
import { UserService } from '../user/user.service';
import { AppSubscription } from './subscription.model';
// import { IAPProduct, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
import { Platform } from '@ionic/angular';
import { StorageService } from '../storage/storage.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { HttpClient } from '@angular/common/http';
import { loadStripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  isNewSubscription = false;
  subscriptionFlowState = '';
  subscription = new AppSubscription();
  // inAppProduct: IAPProduct;
  discountPromoCode = '';

  constructor(public actionSheetController: ActionSheetController, public navCtrl: NavController, public storage: StorageService,
    public alertController: AlertController, public app: Globals, public userService: UserService,
    public platform: Platform, public firestore: AngularFirestore,
    public fns: AngularFireFunctions, public http: HttpClient,
    public loadingController: LoadingController) {

    this.platform.ready().then(() => {

      // this.store.verbosity = this.store.DEBUG;

      // // Registra os produtos disponíveis (Não está sendo criado um novo, apenas registrando os que foram criados no Google Play Console)
      // this.store.register([
      //   {
      //     id: 'monthly_subscription',
      //     type: this.store.PAID_SUBSCRIPTION,
      //   }, {
      //     id: 'monthly_subscription_aac',
      //     type: this.store.PAID_SUBSCRIPTION,
      //   }
      // ]);

      // // Test if function is safe to use: Avoids error in browser  
      // if (typeof this.store.when('subscription').registered !== "undefined") {

      //   // Evento emitido quando as assinaturas são registradas no plugin InAppPurchase2
      //   this.store.when('subscription').registered((product: IAPProduct) => {
      //     console.log('Registered: ' + JSON.stringify(product));
      //   });

      //   // Evento emitido quando alguma assinatura é aprovada (adquirida)
      //   this.store.when('subscription').approved(async (p: IAPProduct) => {
      //     p.finish();
      //     this.inAppProduct = p;

      //     if (this.isNewSubscription) {
      //       this.showSubscriptionSuccessPage();
      //     } else {
      //       this.checkInAppPurchaseFinished(p);
      //     }
      //   });

      //   // Evento emitido o usuário fecha o popup de assinatura
      //   this.store.when('subscription').cancelled((product) => {
      //     this.isNewSubscription = false;
      //   });

      // }

      // // Rastreia todos os erros relacionados com o plugin InAppPurchase2
      // this.store.error((err) => {
      //   console.error('Store Error ' + JSON.stringify(err));
      //   this.isNewSubscription = false;
      // });

      // // Evento emitido quando o plugin da loja estiver pronto para funcionar
      // this.store.ready(() => {
      //   console.log('Store is ready');
      //   console.log('Products: ' + JSON.stringify(this.store.products));
      //   console.log(JSON.stringify(this.store.get('subscription')));
      // });

      // // Atualiza o estado dos produtos da loja
      // this.store.refresh();
    });
  }

  /**
   * Orders a new Aac Plan subscription. When running on native app, we use in app puchase to proceed to checkout.
   * On web, we use stripe platform instead.
   */
  async orderAacPlan() {
    if (this.app.isRunningOnAndroidApp()) {
      this.orderInAppAacSubscription();
    } else if (this.app.isRunningOnBrowser()) {
      const loading = await this.loadingController.create({
        cssClass: 'app-standard-loading',
        message: 'Abrindo ambiente de compra seguro',
        spinner: 'dots',
        animated: false
      });

      this.orderStripeAacSubscription();
      await loading.present();
      setTimeout(() => { loading.dismiss(); }, 5000);
    }
  }

  /**
   * Orders a new Activities Plan subscription. When running on native app, we use in app puchase to proceed to checkout.
   * On web, we use stripe platform instead.
   */
  async orderActivitiesPlan() {
    if (this.app.isRunningOnAndroidApp()) {
      this.orderInAppActivitiesSubscription();
    } else if (this.app.isRunningOnBrowser()) {
      const loading = await this.loadingController.create({
        cssClass: 'app-standard-loading',
        message: 'Abrindo ambiente de compra seguro',
        spinner: 'dots',
        animated: false
      });

      this.orderStripeActivitiesSubscription();
      await loading.present();
      setTimeout(() => { loading.dismiss(); }, 5000);
    }
  }

  storeManageSubscriptions() {
    // this.store.manageSubscriptions();
  }

  orderInAppAacSubscription() {
    if (this.app.canCreateNewSubscription()) {
      this.isNewSubscription = true;
    } else {
      this.userAlreadySubscribedAlert();
    }
  }

  orderInAppActivitiesSubscription() {
    if (this.app.canCreateNewSubscription()) {
      this.isNewSubscription = true;
      // this.store.order('monthly_subscription');
    } else {
      this.userAlreadySubscribedAlert();
    }
  }

  async userAlreadySubscribedAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: 'Você já possui uma assinatura',
      message: 'Cancele a assinatura atual para trocar de plano',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.stripeManageSubscriptions();
          }
        }
      ]
    });
    await alert.present();
  }

  showSubscriptionSuccessPage() {
    this.navCtrl.navigateForward('/subscription-success');
  }

  // checkInAppPurchaseFinished(p: IAPProduct) {
  // const serverUrl = 'https://safe-wildwood-39479.herokuapp.com/check-in-app-purchase-google-finished';
  // this.http.get<any>(serverUrl + '?subscriptionId=' + p.id + '&purchaseToken=' + p.transaction.purchaseToken).subscribe(
  //   res => {
  //     console.log('RESPOSTA DO SERVIDOR DE RECIDOS:');
  //     console.log('');
  //     if (!res.finished) {
  //       console.log('ASSINATURA NÃO FINALIZADA');
  //       // A conta do usuário no Google play do dispositivo possui uma assinatura que ainda não foi registrada
  //       // para alguma conta do Expressia. Abrimos novamente a página para registro da assinatura.
  //       this.showSubscriptionSuccessPage();
  //     } else {
  //       console.log('ASSINATURA FINALIZADA');
  //     }
  //   },
  // );
  // }

  async subscribeUserInApp(): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      // try {
      //   const purchaseToken = this.inAppProduct.transaction.purchaseToken;
      //   const subscriptionId = this.inAppProduct.id;

      //   this.app.user.subscription.id = this.inAppProduct.id;
      //   this.app.user.subscription.origin = 'in-app-google';
      //   this.app.user.subscription.inAppProduct = this.inAppProduct;
      //   this.app.user.subscriptionRef = this.inAppProduct.transaction.purchaseToken;

      //   const serverReceiptUrl = 'https://safe-wildwood-39479.herokuapp.com/in-app-purchase-google';

      //   this.http.get<any>(serverReceiptUrl + '?subscriptionId=' + subscriptionId + '&purchaseToken=' + purchaseToken).subscribe(
      //     res => {
      //       this.app.user.subscription.expiryTimeMillis = res.expiryTimeMillis;
      //       this.app.user.subscription.isActive = true;
      //       this.app.user.subscription.cancelWhenExpire = false;

      //       // Atualiza o campo de assinatura do usuário no banco de dados
      //       const updateData = {
      //         subscription: JSON.parse(JSON.stringify(this.app.user.subscription)),
      //         subscriptionRef: this.app.user.subscriptionRef
      //       };

      //       this.firestore.collection('users').doc(this.app.user.uid).update(updateData).then(() => resolve()).catch(() => reject());
      //     },
      //     err => { console.log(err); reject(err); }
      //   );
      // } catch (err) {
      //   console.log(err);
      // }
    });
  }

  setDiscountPromoCode(promoCode: string) {
    this.discountPromoCode = promoCode;
  }

  // *************************************************//
  // Funções do Stripe para criar e gerenciar        //
  // assinaturas no navegador                        //
  // *************************************************//
  orderStripeAacSubscription(): Promise<any> {
    if (this.app.canCreateNewSubscription()) {
      this.isNewSubscription = true;
      return this.checkout(this.app.user.local.stripeAacPriceID);
    } else {
      this.loadingController.dismiss();
      setTimeout(() => { this.userAlreadySubscribedAlert(); }, 5000);

    }
  }

  orderStripeActivitiesSubscription(): Promise<any> {
    if (this.app.canCreateNewSubscription()) {
      this.isNewSubscription = true;
      return this.checkout(this.app.user.local.stripeActivitiesPriceID);
    } else {
      this.loadingController.dismiss();
      setTimeout(() => { this.userAlreadySubscribedAlert(); }, 5000);
    }
  }

  // Replace planID with a SKU for your own product (created either in the Stripe Dashboard or with the API)
  async checkout(priceID) {

    console.log('PROMO CODE: ' + this.discountPromoCode);

    // Replace with the domain you want your users to be redirected back to after payment
    const DOMAIN = window.location.hostname;

    const stripe = await loadStripe(environment.stripePublishableKey);
    const docRef = await this.firestore
      .collection('customers')
      .doc(this.app.user.uid)
      .collection('checkout_sessions')
      .add({
        price: priceID,
        allow_promotion_codes: true,
        promotion_code: this.discountPromoCode,
        success_url: 'https://web.expressia.life/subscription-success',
        cancel_url: 'https://web.expressia.life',
        billing_address_collection: 'required',
        customerEmail: this.app.user.email
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`Ocorreu um erro: ${error.message}`);
        document.querySelectorAll('button').forEach((b) => (b.disabled = false));
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        try {
          // Init Stripe
          stripe.redirectToCheckout({ sessionId }).then(this.handleStripeCheckoutResult);
        } catch (error) {
          console.error('checkout() try catch error', error);
        }
      }
    });
  }

  handleStripeCheckoutResult(result) {
    console.log('handleResult()', result);
  }

  // Gerencia assinaturas existentes: cancelar, editar cartão, trocar de plano
  async stripeManageSubscriptions() {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: 'Abrindo gerenciador de assinatura',
      spinner: 'dots',
      animated: false
    });

    await loading.present();
    setTimeout(() => { loading.dismiss(); }, 8000);

    // Call billing portal function
    const callable = await this.fns.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
      ({ returnUrl: 'https://web.expressia.life' }).toPromise();
    window.location.assign(callable.url);

  }
}
