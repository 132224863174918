import { Injectable } from '@angular/core';
import { AacBoard } from 'src/app/aac/aac-board/aac-board.model';
import { SampleBoardsService } from 'src/app/helpers/sample-boards.service';
import { AacDataApiService } from './aac-data-api/aac-data-api.service';
import { StorageService } from 'src/app/storage/storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ICreateAacBoardOpts } from 'src/app/database/database.service';

@Injectable({
  providedIn: 'root'
})
export class AacDataService {

  // Aac boards in array format
  aacBoardsChangedBS = new Subject<AacBoard[]>();

  // Aac Boards Object
  private aacBoardsObj: any = {};

  // Key in the local database that is used to store user's aac boards
  private myBoardsDatabaseKey: string;

  constructor(private database: StorageService, private aacDataApi: AacDataApiService,
              private sampleBoardsService: SampleBoardsService) { }

  /**
   * Loads the aac boards from database
   * @param lang App language
   * @param uid User uid. It may be empty or null if the user is not logged in
   */
  async loadBoards(lang: string, uid: string): Promise<Array<AacBoard>> {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('[aac-data] Load boards started. Lang = ' + lang);
  
        // Lang suffix used to separete the boards by languages. Due legacy, the suffix is empty for language pt-br.
        const langSuffix = lang.toLowerCase() === 'pt-br' ? '' : `-${lang.toLowerCase()}`;
    
        // Key to access the boards in database.
        let key = `my-boards${langSuffix}`;
    
        // console.log('[aac-data] User uid: ', uid);
    
        // Adds the user uid if it's available.
        if (uid) { 
          key += `-${uid}`; 
        } else {
          throw new Error('user-not-logged');
        }
    
        // console.log('[aac-data] LangSuffix = ' + langSuffix);
    
        // Sets the my boards database key, lang and uid.
        this.myBoardsDatabaseKey = key;

        // Gets the boards from database.
        let boardsObj = await this.database.getObject(key);
    
        let isFirstSync = false;

        // If the boards were not found in local database we need aditional steps...
        if (!boardsObj) {
          // console.log('[aac-data] Boards not found in local database');

          isFirstSync = true;
    
          // Starts with the sample boards. It may be overwritten if the local backup file is available.
          let boards = this.sampleBoardsService.getAacSampleBoards(lang);
    
          // try {
          //   console.log('[aac-data] Getting boards from Backup file');
          //   const boardsFromBackupFile = await this.getBoardsFromBackupFile(key, langSuffix);
          //   boards = boardsFromBackupFile;
          // } catch (err) {
          //   console.log('[aac-data] Error while Getting boards from Backup file');
          //   console.log('[aac-data] ', err);
          // }
    
          // Transforms the boards array into object
          boardsObj = this.database.arrayToObj(boards);
    
          // // Saves the boards on local database.
          // await this.database.setObject(key, boardsObj);
        }
    
        // Init the aac boards object to content just loaded.
        this.aacBoardsObj = boardsObj;
    
        // Sync the aac boards.
        await this.syncAacBoads(key, langSuffix, isFirstSync);
        
        const aacBoardsArray = this.objToArray(this.aacBoardsObj);
        resolve(aacBoardsArray);
      } catch(err) {
        console.log(err);
        // Even if we face a error while trying to get the boards, the reject will the sample boards.
        const sampleBoards = this.sampleBoardsService.getAacSampleBoards(lang);
        reject(sampleBoards);
      }
    });
  }

  /**
   * Dispatch/emits the current aac boards
   */
  dispatchAacBoards() {
    this.aacBoardsChangedBS.next(this.objToArray(this.aacBoardsObj));
  }

  getAacBoardByIdLocal(boardId: string) {
    const cardId = boardId === 'root' ? 'root' : boardId;
    let board = this.aacBoardsObj[cardId];
    return board;
  }

  getAacBoardById(boardId: string, langSuffix: string): Promise<AacBoard> {
    return new Promise((resolve, reject) => {
      const cardId = boardId === 'root' ? 'root' : boardId;
      let board = this.aacBoardsObj[cardId];
  
      if (!board) {
        this.aacDataApi.getAacBoards(langSuffix, { boardId }).then((result) => {
          resolve(result.boards.pop());
        }).catch(err => reject(err));
      } else {
        resolve(board);
      }
    });
  }

  /**
   * Gets the board's cards
   * @param boardId board id
   */
  getBoardCards(boardId: string) {
    // Gets the board
    const board = this.aacBoardsObj[boardId];
    const cardsIds = board ? board.cards : [];
    const cards = [];

    cardsIds.forEach(id => {
      const card = this.aacBoardsObj[id];
      if (card) {
        cards.push(card);
      }
    });

    return cards;
  }

  /**
   * Gets the aac boards
   */
  getAacBoards() {
    return this.objToArray(this.aacBoardsObj);
  }

  /**
   * Handles when the aac card/board is created
   * @param boardId board id
   */
  async onAacBoardCreated(card: AacBoard, parentBoard: AacBoard, opts: ICreateAacBoardOpts = {}) {
    card.sync = true;
    this.aacBoardsObj[card._id] = card;

    if (parentBoard) {
      parentBoard.sync = true;
      parentBoard.cards.push(card._id);
      const parentId = parentBoard.title === 'root' ? 'root' : parentBoard._id;

      if (this.aacBoardsObj[parentId] === undefined) { this.aacBoardsObj[parentId] = {} }
      Object.assign(this.aacBoardsObj[parentId], parentBoard);
    }

    // Saves aac boards in the local database.
    await this.database.setObject(this.myBoardsDatabaseKey, this.aacBoardsObj);

    if (!opts.noDispatchAfterCreation) {
      // Emits the changes in the aac boards
      this.aacBoardsChangedBS.next(this.objToArray(this.aacBoardsObj));
    }
  }

  /**
   * Handles when the aac card/board is updated
   * @param card
   */
  async onAacBoardUpdated(card: AacBoard, updates = null) {
    card.sync = true;
    const cardId = card.title === 'root' ? 'root' : card._id;

    const fieldValueUpdate = updates?.cards?._delegate;

    if (fieldValueUpdate) {
      if (fieldValueUpdate._methodName === 'FieldValue.arrayUnion') {
        const array = fieldValueUpdate.pa;
        card.cards = card.cards.concat(array);
      } else if (fieldValueUpdate._methodName === 'FieldValue.arrayRemove') {
        const array = fieldValueUpdate.pa;
        for (const item of array) {
          const index = card.cards.findIndex(id => id === item);
          card.cards.splice(index, 1);
        }
      }
    }

    // console.log('card => ', card);
    // console.log('this.aacBoardsObj[cardId] => ', this.aacBoardsObj[cardId]);

    if (this.aacBoardsObj[cardId] === undefined) { 
      this.aacBoardsObj[cardId] = card; 
    } else {
      Object.assign(this.aacBoardsObj[cardId], card);
    }

    // Saves aac boards in the local database.
    await this.database.setObject(this.myBoardsDatabaseKey, this.aacBoardsObj);

    // Emits the changes in the aac boards
    this.aacBoardsChangedBS.next(this.objToArray(this.aacBoardsObj));
  }

  /**
   * Handles when the aac card/board is deleted
   * @param card
   */
  async onAacBoardDeleted(card: AacBoard, parentBoard: AacBoard, innerCards: Array<AacBoard>) {
    card.sync = true;
    delete  this.aacBoardsObj[card._id];
    for (const innerCard of innerCards) {
      delete  this.aacBoardsObj[innerCard._id];
    }
    const cardIndex = parentBoard.cards.findIndex(id => id === card._id);

    if (cardIndex >= 0) {
      parentBoard.cards.splice(cardIndex, 1);
    }

    const parentId = parentBoard.title === 'root' ? 'root' : parentBoard._id;
    Object.assign(this.aacBoardsObj[parentId], parentBoard);

    // Saves aac boards in the local database.
    await this.database.setObject(this.myBoardsDatabaseKey, this.aacBoardsObj);

    // Emits the changes in the aac boards
    this.aacBoardsChangedBS.next(this.objToArray(this.aacBoardsObj));
  }

  /**
   * Syncs the aac boards.
   * It makes a "pull" to get the changes from cloud
   */
  private async syncAacBoads(key: string, langSuffix: string, isFirstSync = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // Gets the last time the database was synced.
        // It will be considered 0 if the database hasn't being synced yet.
        let lastSync = await this.database.getObject(`last-sync-${key}`);
        if (!lastSync) { lastSync = 0; }
  
        // console.log('[aac-data] syncAacBoads Last sync: ', lastSync);
        // console.log('[aac-data] syncAacBoads Getting changes from cloud');
  
        // Pull: Get the cards where updateAt is greater than lastSync.
        const response = await this.aacDataApi.getAacBoards(langSuffix, { lastSync });
        const cards = response.boards;
  
        // console.log('[aac-data] syncAacBoads Boards gotten from cloud: ', cards);
  
        // Updates the card in the local database.
        cards.forEach(card => {
          const cardId = card.title === 'root' ? 'root' : card._id;
          this.aacBoardsObj[cardId] = card;
          card.sync = true;
        });
  
        // console.log('[aac-data] syncAacBoads Getting changes from cloud done!');
        // console.log('[aac-data] syncAacBoads Last sync got from server: ', response.serverTimestamp);
  
        // Sets the last sync to server time that the boards were fetched.
        lastSync = response.serverTimestamp;
        await this.database.set(`last-sync-${key}`, lastSync);
  
        // Saves the synced aac boards in the local database.
        await this.database.setObject(key, this.aacBoardsObj);
  
        // console.log('[aac-data] syncAacBoads All done!');
        
        resolve();
      } catch (err) {
        console.log('[aac-data] syncAacBoads Error');
        console.log('[aac-data] ', err);

        if (isFirstSync) {
          reject();
        } else {
          resolve();
        }
      }
    });
  }

  /**
   * Gets the boards that are in the backup file
   * @returns boards
   */
  private async getBoardsFromBackupFile(key: string, langSuffix: string) {
    // TODO: Chamar função para acessar o sistema de arquivo local do sistema para buscar o arquivo das pranchas.
    // Aqui nesta função verificaremos se foi possível carregar ou nao as pranchas do arquivo local de backup. Caso
    // nao tenha sido possivel o carregamento, vamos carregar os exemplos.

    throw new Error('Backup file not implemented yet');

    return [];
  }

  /**
   * Converts an object to array
   * @param obj objetct to be converted into array
   */
  private objToArray(obj: Array<AacBoard>) {
    return Object.keys(obj).map((key) => obj[key]);
  }
}
