import { SafeUrl } from '@angular/platform-browser';
import * as uuid from 'uuid';
import * as utils from '../../utils/utils';
import { ActivityDescription } from '../activity-description/activity-description.model';
import { ActivityBoardPreferences } from './activity-board-preferences.model';

export class ActivityBoard {
    id = '';
    // tslint:disable-next-line: variable-name
    _id = '';
    owner = '';
    userId = '';
    locale = '';
    lang: string;
    sync = false;
    img = '';
    imgSearchText: string;
    title = '';
    link: string;
    question = '';
    questionImgSrc = '';
    questionImgSearchText: string;
    questionAudio: SafeUrl = '';
    audio: SafeUrl = '';
    color = 'white';
    borderColor = '';
    cards = [];
    cardsOrder? = []
    description: ActivityDescription;
    isCategory = false;
    isActivity = false;
    isVisible = true;
    isOrderable: boolean;
    isEditable: boolean;
    isSharedSpace: boolean;
    isGroup: boolean;
    isContact: boolean;
    shared: boolean;
    preferences: ActivityBoardPreferences;
    membersInfo: Array<any>;
    membersUid: Array<string>;
    removedMembersUid: Array<string>;
    shareSave: number;
    sharePlay: number;
    createdAt: number;
    updatedAt: number;
    mgmAcquisition = [];
    viewedBy: Array<string>;
    hasNewContents: boolean;
    status: string;
    resultsCount: number;
    sharedActivitiesQuotaExceeded: boolean;
    categories: Array<string>;

    constructor(obj = null) {
        if (obj !== null) {
            Object.assign(this, obj);
        } else {
            this.id = uuid.v4();
        }
    }
}
