import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { ExpressiaAnalyticsService } from './expressia-analytics/expressia-analytics.service';
import packageInfo  from 'package.json';
import { UserDataApiService } from '../user/user-data/user-data-api/user-data-api.service';

// tslint:disable:object-literal-shorthand

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private expressiaAnalytics: ExpressiaAnalyticsService, private app: Globals, private userDataApi: UserDataApiService) {
    setTimeout(() => {
      this.logEvent('app_info', { plataform: this.app.getPlatform(), version: packageInfo.version });
    }, 5000);

    // Adiciona TIMEOUT para dar tempo do auth atualizar o app.user antes de enviar o evento de geolocation.
    setTimeout(() => { 
      this.refreshUserGeolocation() 
    }, 30000);
  }

  logEvent(eventName: string , eventParams?: any, paramName?: string) {
    this.expressiaAnalytics.logEvent(eventName, eventParams, paramName);
  }

  private async refreshUserGeolocation() {
    this.userDataApi.findUserGeolocation().then(geolocation => {
      this.logEvent('user_geolocation', { geolocation });
    }).catch(err => console.log(err));
  }
}
