import { environment } from './../../environments/environment';
export class UserLocalParameters {
    country = 'none';
    countryCode = undefined;
    currency = 'usd';
    storageServerRegion = 'eu-west-2';
    stripeActivitiesPriceID = environment.stripeActivitiesPriceID;
    stripeActivitiesPriceValue = NaN;
    stripeAacPriceID = environment.stripeAacPriceID;
    stripeAacPriceValue = NaN;

    constructor(obj = null) {
        if (typeof obj !== 'undefined' && obj !== null) {
            Object.assign(this, obj);
        }
    }
}