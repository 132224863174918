import { Injectable } from '@angular/core';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  lang = 'pt-BR';
  speechVoice = null;
  availableVoicesWebSpeech = [];

  constructor(public app: Globals, private tts: TextToSpeech, private langService: LanguageService) {
    langService.langObs.subscribe(lang => {
      this.lang = lang;
      if (this.app.isRunningOnBrowser()) {
        this.webSpeechLoadVoices(this.lang);
      }
    });
  }

  speak(text: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Converts to lowercase to reduce the change of spelling the words
      // instead of saying it.
      text = text.toLowerCase();

      // If the text is empty, there is nothing to speak
      if (text === undefined || text === '') {
        resolve();
      } else if (this.app.isRunningOnNative()) {
        // tslint:disable-next-line:object-literal-shorthand
        this.tts.speak({text: text, locale: this.lang})
          .then(() => { resolve(); })
          .catch((reason: any) => console.log(reason));
      } else {
        const msg = new SpeechSynthesisUtterance();

        msg.text = text;

        if (this.speechVoice === null) {
          msg.voice = this.availableVoicesWebSpeech[0];
        } else {
          msg.voice = this.speechVoice;
        }

        window.speechSynthesis.speak(msg);

        msg.onend = () => {
          resolve();
        };

        msg.onerror = (err) => {
          reject(err);
        };
      }
    });
  }

  webSpeechLoadVoices(lang = 'pt-BR') {
    this.loadVoices(lang);

    // Chorme carrega as vozes de forma assíncrona
    window.speechSynthesis.onvoiceschanged = (e) => {
      this.loadVoices(lang);
    };
  }

  // Carregas a lista de vozes disponíveis
  loadVoices(lang: string) {
    const voices = speechSynthesis.getVoices();

    voices.forEach((voice, i) => {
        this.availableVoicesWebSpeech.push(voice);

        if (voice.lang === lang) {
          this.speechVoice = voice;
        }

        // Dá preferência para a voz do Google
        const googleVoiceIndex = this.availableVoicesWebSpeech.findIndex(v => v.lang === lang && v.name.includes('Google'));
        if (googleVoiceIndex >= 0) {
          this.speechVoice = this.availableVoicesWebSpeech[googleVoiceIndex];
        }
    });
  }

  async cancelSpeak() {
    try {
      if (this.app.isRunningOnNative()) {
        await this.tts.stop();
      } else {
        window.speechSynthesis.cancel();
      }
    } catch (err) {
      console.log(err);
    }

  }
}
