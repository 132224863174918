import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ActivitiesService } from 'src/app/activities/activities.service';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
// tslint:disable-next-line:max-line-length
import { UserSharedSpacesMorePopoverComponent } from 'src/app/user/user-shared-spaces/user-shared-spaces-more-popover/user-shared-spaces-more-popover.component';

@Component({
  selector: 'app-user-shared-spaces',
  templateUrl: './user-shared-spaces.component.html',
  styleUrls: ['./user-shared-spaces.component.scss'],
})
export class UserSharedSpacesComponent implements OnInit, OnDestroy {

  theme = 'activities';
  sharedSpaces: ActivityBoard[] = [];
  sharedSpacesSub: Subscription;

  constructor(private app: Globals, private modalController: ModalController, private navCtrl: NavController, public lang: LanguageService,
              private activitesService: ActivitiesService, private popoverController: PopoverController) { }

  ngOnInit() {
    this.sharedSpacesSub = this.activitesService.activityBoardsSharedSpaceObs.subscribe(boards => {
      const sharedSpaces = boards.filter(board => board.isSharedSpace);

      sharedSpaces.sort((a, b) =>  b.updatedAt - a.updatedAt);

      sharedSpaces.forEach(sharedSpace => {
        if (sharedSpace.isContact) {
          const memberInfo = sharedSpace.membersInfo.find(contact => contact.uid !== this.app.user.uid);
          if (memberInfo) {
            sharedSpace.title = memberInfo.name;
            sharedSpace.img = memberInfo.photo;
          }
        }
      });

      this.sharedSpaces = sharedSpaces;
    });
  }

  ngOnDestroy(): void {
    if (this.sharedSpacesSub) { this.sharedSpacesSub.unsubscribe(); }
    const newSharedSpaces = this.sharedSpaces.filter(board => !(board.viewedBy && board.viewedBy.includes(this.app.user.uid)));
    this.activitesService.clearNewSharedSpacesTag(newSharedSpaces);
  }

  onBackBtnClicked(ev: any) {
    this.modalController.dismiss();
  }

  async onAddBtnClicked(ev: any) {
    const popover = await this.popoverController.create({
      component: UserSharedSpacesMorePopoverComponent,
      event: ev,
      translucent: true,
      cssClass: 'app-popover-lg'
    });
    await popover.present();
  }

  onSharedSpaceClicked(sharedSpace: ActivityBoard) {
    // Sets the name of the shared space (so its name will appear on the toolbar)
    this.activitesService.getBoardById(sharedSpace._id).title = sharedSpace.title;
    this.modalController.dismiss(sharedSpace._id);
    this.navCtrl.navigateForward(`/activities/${sharedSpace._id}`);
  }

  /**
   * Gets the shared space subtitle and badge. The subtitle informs the number of activities
   * that are inside it while the badge informs the number of activites that the user hasn't seen yet.
   * @param sharedSpace Shared Space
   * @returns subtitle The subtitle for the shared space
   */
  getSharedSpaceSubtitleAndBadge(sharedSpace: ActivityBoard) {
    const sharedSpaceBoards: ActivityBoard[] = [];
    this.activitesService.discoveryBoards(sharedSpace._id, this.activitesService.activityBoardsSharedSpace, sharedSpaceBoards);

    const sharedSpaceActivities = sharedSpaceBoards.filter(board => board.isActivity);
    const activitiesCount = sharedSpaceActivities.length;

    // Subtile telling the number of activities that are inside the shared space
    const sharedSpaceSubtitle = activitiesCount === 1 ? `1 ${this.lang.words.userSharedSpaces.activitySubtitle}` :
                                                        `${activitiesCount} ${this.lang.words.userSharedSpaces.activitiesSubtitle}`;

    // Activities that the user hasn't seen yet
    const newActivitiesCount = sharedSpaceBoards.filter(board =>
      board.isActivity && !(board.viewedBy && board.viewedBy.includes(this.app.user.uid))).length;

    return { subtitle: sharedSpaceSubtitle, badge: newActivitiesCount };
  }

  getSharedSpaceTag(sharedSpace: ActivityBoard) {
    if (!(sharedSpace.viewedBy && sharedSpace.viewedBy.includes(this.app.user.uid))) {
      return `${this.lang.words.common.newTag}`;
    } else {
      return '';
    }
  }
}
