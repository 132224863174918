import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './auth/auth.service';
import { SubscriptionService } from './subscription/subscription.service';
import { MediaSyncService } from './media-sync/media-sync.service';
import { Globals } from './globals/globals';
import packageInfo from 'package.json';
import { UpdaterService } from './tools/updater/updater.service';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import MPEGMode from 'lamejs/src/js/MPEGMode';
import Lame from 'lamejs/src/js/Lame';
import BitStream from 'lamejs/src/js/BitStream';
import {SplashScreen} from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  myWindowRef: any

  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    public subscriptionService: SubscriptionService,
    public mediaSyncService: MediaSyncService,
    public app: Globals,
    private updater: UpdaterService
  ) {
    console.log('Version: ' + packageInfo.version);
    this.initializeApp();
  }

  ngOnInit() {
    this.authService.initAuthListener();
  }

  initializeApp() {
    //firebase.setLogLevel("debug"); 
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      // SplashScreen.hide();
      // setTimeout(()=>{
      //   SplashScreen.hide({
      //     fadeOutDuration: 1000
      //   });
      // }, 10000);

    });

    // TODO:  Quick and dity fix para incompatibilidade do lamejs com angular 13+
    // https://github.com/zhuker/lamejs/issues/71
    // https://github.com/zhuker/lamejs/issues/86
    // combinado com elementos de https://stackblitz.com/edit/angular-ivy-vzr9gx?file=src%2Fapp%2Flame.d.ts,angular.json
    // Alternativa: usar outro plugin ou rodar o lamejs em um worker?

    this.myWindowRef = window;
    this.myWindowRef.MPEGMode = MPEGMode;
    this.myWindowRef.Lame = Lame;
    this.myWindowRef.BitStream = BitStream;
  }
}
