import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private fireAuth: AngularFireAuth, private fns: AngularFireFunctions) { }

  /**
   * Gets the user's document
   * @returns user's doc
   */
  async getUserDoc(uid: string) {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('getUser')({ uid }).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }

  /**
   * Gets the current logged user's uid
   * @returns user's uid
   */
  async getCurrentUserUid(): Promise<string> {
    // TODO: Implementar timeout caso o subscribe nao retornar
    return new Promise((resolve, reject) => {
      this.fireAuth.user.pipe(take(1)).subscribe(user => {
        // console.log(user);
        const userUid = user ? user.uid : '';
        resolve(userUid);
      }, error => {
        reject(error);
      });
    });
  }
}
