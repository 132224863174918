import { Injectable } from '@angular/core';
import { SampleBoardsService } from 'src/app/helpers/sample-boards.service';
import { StorageService } from 'src/app/storage/storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivityBoard } from '../activity-board/activity-board.model';
import { ActivitiesDataApiService } from './activities-data/activities-data-api/activities-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesDataService {

  // Activities boards in array format
  activitiesBoardsChangedBS = new Subject<ActivityBoard[]>();

  // Activities Boards Object
  private activitiesBoardsObj: any = {};

  // Key in the local database that is used to store user's activities boards
  private myActivitiesDatabaseKey: string;

  constructor(private database: StorageService, private activitiesDataApi: ActivitiesDataApiService,
              private sampleBoardsService: SampleBoardsService) { }

  /**
   * Loads the activities boards from database
   * @param lang App language
   * @param uid User uid. It may be empty or null if the user is not logged in
   */
  async loadActivitiesBoards(lang: string, uid: string): Promise<Array<ActivityBoard>> {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('[activities-data] Load activities boards started. Lang = ' + lang);
  
        // Lang suffix used to separete the boards by languages. Due legacy, the suffix is empty for language pt-br.
        const langSuffix = lang.toLowerCase() === 'pt-br' ? '' : `-${lang.toLowerCase()}`;
    
        // Key to access the activities boards in database.
        let key = `my-activities${langSuffix}`;
    
        // console.log('[activities-data] User uid: ', uid);
    
        // Adds the user uid if it's available.
        if (uid) { 
          key += `-${uid}`; 
        } else {
          throw new Error('user-not-logged');
        }
    
        // console.log('[activities-data] LangSuffix = ' + langSuffix);
    
        // Sets the my boards database key, lang and uid.
        this.myActivitiesDatabaseKey = key;

        // Gets the boards from database.
        let boardsObj = await this.database.getObject(key);
    
        let isFirstSync = false;

        // If the boards were not found in local database we need aditional steps...
        if (!boardsObj) {
          // console.log('[activities-data] Boards not found in local database');

          isFirstSync = true;
    
          // Starts with the sample activities
          let boards = this.sampleBoardsService.getActivitiesSampleBoards(lang);
    
          // Transforms the boards array into object
          boardsObj = this.database.arrayToObj(boards);
        }
    
        // Init the activities boards object to content just loaded.
        this.activitiesBoardsObj = boardsObj;
    
        // Sync the activities boards.
        await this.syncActivitiesBoards(key, langSuffix, isFirstSync);
        
        const activitiesBoardsArray = this.objToArray(this.activitiesBoardsObj);
        resolve(activitiesBoardsArray);
      } catch(err) {
        console.log(err);
        // Even if we face a error while trying to get the activities, the reject will the sample activities.
        const sampleBoards = this.sampleBoardsService.getActivitiesSampleBoards(lang);
        reject(sampleBoards);
      }
    });
  }

  /**
   * Dispatch/emits the activities boards.
   */
  dispatchActivitiesBoards() {
    this.activitiesBoardsChangedBS.next(this.objToArray(this.activitiesBoardsObj));
  }

  getActivitiesBoardById(boardId: string, langSuffix: string): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      const cardId = boardId === 'root' ? 'root' : boardId;
      let board = this.activitiesBoardsObj[cardId];
  
      if (!board) {
        this.activitiesDataApi.getActivitiesBoards(langSuffix, { boardId }).then((result) => {
          resolve(result.boards.pop());
        }).catch(err => reject(err));
      } else {
        resolve(board);
      }
    });
  }

  /**
   * Gets the activities boards
   */
  getActivitiesBoards() {
    return this.objToArray(this.activitiesBoardsObj);
  }

  /**
   * Handles when the activities card/board is created
   * @param boardId board id
   */
  async onActivitiesBoardCreated(card: ActivityBoard, parentBoard: ActivityBoard) {
    card.sync = true;
    this.activitiesBoardsObj[card._id] = card;

    if (parentBoard) {
      parentBoard.sync = true;
      parentBoard.cards.push(card._id);
      const parentId = parentBoard.title === 'root' ? 'root' : parentBoard._id;

      if (this.activitiesBoardsObj[parentId] === undefined) { this.activitiesBoardsObj[parentId] = {} }
      Object.assign(this.activitiesBoardsObj[parentId], parentBoard);
    }

    // Saves activities boards in the local database.
    await this.database.setObject(this.myActivitiesDatabaseKey, this.activitiesBoardsObj);

    // Emits the changes in the activities boards
    this.activitiesBoardsChangedBS.next(this.objToArray(this.activitiesBoardsObj));
  }

  /**
   * Handles when the activities card/board is updated
   * @param card
   */
  async onActivitiesBoardUpdated(card: ActivityBoard, updates = null) {
    card.sync = true;
    const cardId = card.title === 'root' ? 'root' : card._id;

    const fieldValueUpdate = updates?.cards?._delegate;

    if (fieldValueUpdate) {
      if (fieldValueUpdate._methodName === 'FieldValue.arrayUnion') {
        const array = fieldValueUpdate.pa;
        card.cards = card.cards.concat(array);
      } else if (fieldValueUpdate._methodName === 'FieldValue.arrayRemove') {
        const array = fieldValueUpdate.pa;
        for (const item of array) {
          const index = card.cards.findIndex(id => id === item);
          card.cards.splice(index, 1);
        }
      }
    }

    // console.log('card => ', card);
    // console.log('this.activitiesBoardsObj[cardId] => ', this.activitiesBoardsObj[cardId]);

    if (this.activitiesBoardsObj[cardId] === undefined) { 
      this.activitiesBoardsObj[cardId] = card; 
    } else {
      Object.assign(this.activitiesBoardsObj[cardId], card);
    }

    // Saves activities boards in the local database.
    await this.database.setObject(this.myActivitiesDatabaseKey, this.activitiesBoardsObj);

    // Emits the changes in the activities boards
    this.activitiesBoardsChangedBS.next(this.objToArray(this.activitiesBoardsObj));
  }

  /**
   * Handles when the activities card/board is deleted
   * @param card
   */
  async onActivitiesBoardDeleted(card: ActivityBoard, parentBoard: ActivityBoard, innerCards: Array<ActivityBoard>) {
    try {
      card.sync = true;
      delete  this.activitiesBoardsObj[card._id];
      for (const innerCard of innerCards) {
        delete  this.activitiesBoardsObj[innerCard._id];
      }
      const cardIndex = parentBoard.cards.findIndex(id => id === card._id);
  
      if (cardIndex >= 0) {
        parentBoard.cards.splice(cardIndex, 1);
      }
  
      const parentId = parentBoard.title === 'root' ? 'root' : parentBoard._id;
      Object.assign(this.activitiesBoardsObj[parentId], parentBoard);
  
      // Saves activities boards in the local database.
      await this.database.setObject(this.myActivitiesDatabaseKey, this.activitiesBoardsObj);
  
      // Emits the changes in the activities boards
      this.activitiesBoardsChangedBS.next(this.objToArray(this.activitiesBoardsObj));
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Syncs the activities boards.
   * It makes a "pull" to get the changes from cloud
   */
  private async syncActivitiesBoards(key: string, langSuffix: string, isFirstSync = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // Gets the last time the database was synced.
        // It will be considered 0 if the database hasn't being synced yet.
        let lastSync = await this.database.getObject(`last-sync-${key}`);
        if (!lastSync) { lastSync = 0; }
  
        // console.log('[activities-data] syncActivitiesBoads Last sync: ', lastSync);
        // console.log('[activities-data] syncActivitiesBoads Getting changes from cloud');
  
        // Pull: Get the cards where updateAt is greater than lastSync.
        const response = await this.activitiesDataApi.getActivitiesBoards(langSuffix, { lastSync });
        const cards = response.boards;
  
        // console.log('[activities-data] syncActivitiesBoads Boards gotten from cloud: ', cards);
  
        // Updates the card in the local database.
        cards.forEach(card => {
          const cardId = card.title === 'root' ? 'root' : card._id;
          this.activitiesBoardsObj[cardId] = card;
          card.sync = true;
        });
  
        // console.log('[activities-data] syncActivitiesBoads Getting changes from cloud done!');
        // console.log('[activities-data] syncActivitiesBoads Last sync got from server: ', response.serverTimestamp);
  
        // Sets the last sync to server time that the boards were fetched.
        lastSync = response.serverTimestamp;
        await this.database.set(`last-sync-${key}`, lastSync);
  
        // Saves the synced activities boards in the local database.
        await this.database.setObject(key, this.activitiesBoardsObj);
  
        // console.log('[activities-data] syncActivitiesBoads All done!');
        
        resolve();
      } catch (err) {
        console.log('[activities-data] syncActivitiesBoads Error');
        console.log('[activities-data] ', err);

        if (isFirstSync) {
          reject();
        } else {
          resolve();
        }
      }
    });
  }

  /**
   * Converts an object to array
   * @param obj objetct to be converted into array
   */
  private objToArray(obj: Array<ActivityBoard>) {
    return Object.keys(obj).map((key) => obj[key]);
  }
}
