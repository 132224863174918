import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserSharedSpacesComponent } from './user-shared-spaces/user-shared-spaces.component';
import { UserContactComponent } from './user-contacts/user-contact/user-contact.component';
import { UserContactsListComponent } from './user-contacts/user-contacts-list/user-contacts-list.component';
// tslint:disable-next-line:max-line-length
import { UserSharedSpacesMorePopoverComponent } from './user-shared-spaces/user-shared-spaces-more-popover/user-shared-spaces-more-popover.component';
import { GroupAddParticipantsComponent } from '../groups/group-add-participants/group-add-participants.component';
import { GridModule } from '../grid/grid.module';
import { ImgModule } from '../layout/img/img.module';
import { UserInterestComponent } from './user-interest/user-interest.component';
import { UserContactMorePopoverComponent } from './user-contacts/user-contact-more-popover/user-contact-more-popover.component';

@NgModule({
    declarations: [
        UserProfileComponent,
        UserSharedSpacesComponent,
        UserContactComponent,
        UserContactsListComponent,
        UserSharedSpacesMorePopoverComponent,
        UserInterestComponent,
        UserContactMorePopoverComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        GridModule,
        ImgModule
    ],
    exports: [
        UserProfileComponent,
        UserContactComponent,
        UserSharedSpacesComponent,
        UserContactsListComponent,
        UserContactMorePopoverComponent
    ]
})
export class UserModule { }
