import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AacBoard } from 'src/app/aac/aac-board/aac-board.model';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { Globals } from 'src/app/globals/globals';
import { CardSelectEvent } from 'src/app/grid/models/card-select-event.model';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-board-picker',
  templateUrl: './board-picker.component.html',
  styleUrls: ['./board-picker.component.scss'],
})
export class BoardPickerComponent implements OnInit {

  @Input() boards: AacBoard[] | ActivityBoard[];
  @Input() onlyCategories = false;
  @Input() scope: 'aac' | 'activities';
  @Input() board: AacBoard | ActivityBoard;
  @Input() moveHere = false;
  @Input() copy = false;
  @Input() title = '';
  @Input() canMoveToRoot = false;

  @Input() rootBoard: AacBoard | ActivityBoard;
  @Input() parentBoard: AacBoard | ActivityBoard;

  selectedBoards = [];
  rootBtnTitle = '';

  nClicks = 0;
  lastClickedBoard: CardSelectEvent;

  constructor(private modalController: ModalController, public lang: LanguageService, public app: Globals) { }

  ngOnInit() {
    if (this.copy) this.rootBtnTitle = this.lang.words.boardPicker.copyToRoot;
    else this.rootBtnTitle = this.lang.words.boardPicker.moveToRoot;
  }

  onBackBtnClicked() {
    this.modalController.dismiss();
  }

  onBoardClicked(moveToRoot: boolean, cardSelectEvent: CardSelectEvent) {
    let selectClass: string;
    if (this.scope === 'aac') {
      selectClass = 'app-aac-category-selected';
    } else if (this.scope === 'activities' && this.onlyCategories) {
      selectClass = 'app-activities-category-selected';
    } else if (this.scope === 'activities') {
      selectClass = 'app-activity-selected';
    }

    if (!this.moveHere) {
      if (moveToRoot === false) {
        if (this.nClicks > 0) {
          this.lastClickedBoard.cardElementRef.classList.remove(selectClass);
          this.selectedBoards.pop();
        }
        this.selectedBoards.push(cardSelectEvent.cardSelected);
        this.lastClickedBoard = cardSelectEvent;

        cardSelectEvent.cardElementRef.classList.add(selectClass);
        this.nClicks++;
      } else {
        if (this.nClicks > 0) this.selectedBoards.pop();

        this.selectedBoards.push(this.rootBoard);
        if (!this.copy) {
          this.modalController.dismiss({ movedBoard: this.board, selectedBoard: this.selectedBoards[0], parentBoard: this.parentBoard });
        } else if (this.copy) {
          this.modalController.dismiss({ copiedBoard: this.board, targetBoard: this.selectedBoards[0] })
        }
      }
    } else {
      const index = this.selectedBoards.findIndex(board => board._id === cardSelectEvent.cardSelected._id);

      if (index >= 0) {
        // The activity was already selected at the time it was clicked. Therefore, we remove it from the selected list.
        this.selectedBoards.splice(index, 1);
        cardSelectEvent.cardElementRef.classList.remove(selectClass);
      } else {
        this.selectedBoards.push(cardSelectEvent.cardSelected);
        cardSelectEvent.cardElementRef.classList.add(selectClass);
      }
    }

  }

  onConfirmBtnClicked() {
    if (!this.copy) {
      if (!this.moveHere) {
        this.modalController.dismiss({ movedBoard: this.board, selectedBoard: this.selectedBoards[0] });
      } else {
        this.modalController.dismiss({ movedBoards: this.selectedBoards, selectedBoard: this.board });
      }
    } else {
      this.modalController.dismiss({ copiedBoard: this.board, targetBoard: this.selectedBoards[0] })
    }
  }

}
