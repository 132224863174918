import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  constructor(private storageService: StorageService) {}

  async setCardSizeChangedByUser(size: any) {
    let key = 'grid-card-size';

    if (location.pathname.includes('aac')) {
      key += '-aac';
    } else if (location.pathname.includes('activities')) {
      key += '-activities';
    }

    this.storageService.set(key, size.toString());
  }

  getCardSizeSetdByUser(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let key = 'grid-card-size';

      if (location.pathname.includes('aac')) {
        key += '-aac';
      } else if (location.pathname.includes('activities')) {
        key += '-activities';
      }

      const cardSize = await this.storageService.get(key);
      if (typeof cardSize !== undefined && cardSize !== null) {
        resolve(Number(cardSize));
      } else {
        resolve(115);
      }
    });
  }
}
