import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserContact, UserContactSelectChangeEv } from '../user-contact/user-contact.model';

@Component({
  selector: 'app-user-contacts-list',
  templateUrl: './user-contacts-list.component.html',
  styleUrls: ['./user-contacts-list.component.scss'],
})
export class UserContactsListComponent implements OnInit {

  @Input() contacts: UserContact[] = [];
  @Input() selectable = false;
  @Input() showMoreButton = false;
  @Output() selectedContactsChange = new EventEmitter<UserContact[]>();
  @Output() contactClick = new EventEmitter<UserContact>();
  selectedContacts: UserContact[] = [];

  constructor() { }

  ngOnInit() {}

  onUserSelectionChanged(ev: UserContactSelectChangeEv) {
    const index = this.selectedContacts.indexOf(ev.contact);
    if (ev.checked === false && index >= 0) {
      this.selectedContacts.splice(index, 1);
    } else if (ev.checked && index <= -1) {
      this.selectedContacts.push(ev.contact);
    }
    this.selectedContactsChange.emit(this.selectedContacts);
  }

  onContactClicked(contact: UserContact) {
    this.contactClick.emit(contact);
  }
}
