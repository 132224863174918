import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AacBoard } from 'src/app/aac/aac-board/aac-board.model';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesDataApiService {

  constructor(private fns: AngularFireFunctions) { }

  /**
   * Gets the activities boards that are stored in cloud
   * @param lang boards' language
   * @returns boards
   */
  getActivitiesBoards(langSuffix: string, opts: any): Promise<{ boards: Array<ActivityBoard>, serverTimestamp: number }>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('getActivitiesBoards')({ langSuffix,  opts}).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }

  /**
   * Saves the shared activity into user account
   * @param lang boards' language
   * @returns boards
   */
  saveSharedActivity(sharedActivityLinkId: string, langSuffix: string ,root: ActivityBoard = null): Promise<void>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('saveSharedActivity')({ sharedActivityLinkId, langSuffix, root }).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }


  /**
   * Gets the shared activity
   */
  getSharedActivity(sharedActivityLinkId: string): Promise<any>  {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable('getSharedActivity')({ sharedActivityLinkId }).subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }
}
