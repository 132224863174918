import { Component, OnInit, Input, Output, OnChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ActivitiesService } from 'src/app/activities/activities.service';
import { ImageCropInfo } from 'src/app/editor-tools/models/image-crop-info.model';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnChanges, OnDestroy {

  @Input() card: any;
  @Input() keyImgSrc = '';
  @Input() keyImgWidth = 0;  // Em porcentagem em relação à largura do cartão
  @Input() imgName = '';
  @Input() imgSrc = '';
  @Input() color = '';
  @Input() title = '';
  @Input() titleHeight = '';
  @Input() imageWidth = 0;
  @Input() imageHeight = 0;
  @Input() cards = [];
  @Input() isCategory = false;
  @Input() isActivity = false;
  @Input() isVisible = true;
  @Input() editable = false;
  @Input() orderable = false;
  @Input() removable = false;
  @Input() grabbing = false;
  @Input() enableRippleEffect = false;
  @Input() statusBarVisible = false;
  @Input() showNewTag = false;
  @Input() buttonsLayout = 'activity';
  @Input() onActivityEditor = false;
  @Input() useHtmlImgTag = false;
  @Input() statusBarBadge = '';
  @Output() hold = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() cardClick = new EventEmitter<any>();
  @Output() toggleVisibility = new EventEmitter<any>();
  @Output() statusBarAction = new EventEmitter<string>();
  @Output() moreBtnClick = new EventEmitter<any>();
  isSharedSpace = false;

  imageCropInfo: ImageCropInfo;
  responsiveBreakpoint = 1;

  constructor(public activitiesService: ActivitiesService, public app: Globals, public mediaStorageService: MediaStorageService,
    public lang: LanguageService) { }

  ngOnInit() {
    if (typeof this.color === 'undefined' || this.color === '') {
      this.color = 'white';
    }

    if (this.card && this.card.isSharedSpace) {
      this.isSharedSpace = true;
    }
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: any): void {
    console.log('ngOnChanges card component');

    /**
     * Calculates the responsive breakpoint (to set the proper card font size) if the change is related with the card width
     */
    if (changes.imageWidth) {
      this.calcResponsiveBreakpoint();
    }

    /* Resolve the new imagem src if the image name has changed */
    if (changes.imgName) {
      if (this.useHtmlImgTag) {
        // TODO: O IDEAL É CONSIDERAR A REGIÃO PARA O CARREGAMENTO DA IMAGEM SER MAIS RÁPIDO
        // this.imgSrc = `${environment.firebaseFunctionsDomain}/resolveImgCors?url=${this.mediaStorageService.resolveImageSrc(this.imgName)}`;
        this.imgSrc = `${environment.firebaseFunctionsDomain}/resolveImgCors?url=https://saopauloms.expressia.life/medias/images/optimized/${this.imgName}`;
      } else {
        this.imgSrc = this.mediaStorageService.resolveImageSrc(this.imgName);
      }

      if (this.imgSrc === '' && this.isCategory) {
        // If a category doesn't have an imagem, we show a folder image instead.
        this.imgSrc = '/assets/images/icons/folder.png';
      }
    }
  }

  onCardClicked(ev: any) {
    if (!this.app.isRunningOnMobile()) {
      this.select.emit(ev);
      console.log('Card selected');
    }
  }

  onHoldCard(ev: any) {
    this.hold.emit(ev);
    ev.stopPropagation();
  }

  onMoreBtnClicked(ev: any) {
    this.moreBtnClick.emit(ev);
    ev.stopPropagation();
  }

  onEditBtnClicked(ev: any) {
    this.edit.emit(ev);
    ev.stopPropagation();
  }

  /**
   * Note: Delete and remove are defined quite similarly, but the main difference between them is that delete means erase
   * (i.e. rendered nonexistent or nonrecoverable), while remove denotes take away and set aside (but kept in existence).
   */
  onDeleteBtnClicked(ev: any) {
    this.delete.emit(ev);
    ev.stopPropagation();
  }

  onToggleVisibilityBtnClicked(ev: any) {
    this.toggleVisibility.emit(ev);
    ev.stopPropagation();
  }

  onStatusBarActionClicked(ev: any, action: string) {
    ev.stopPropagation();
    this.statusBarAction.emit(action);
  }

  onCardImgLoad(ev: any) {
  }

  onCardImgError(ev: any) {
    console.log('onCardImgError:' + this.imgSrc);
    console.log(ev);
  }

  // Retorna a cor de fundo do cartão
  getCardBackground() {
    return this.color;
  }

  // Retorna a cor de fundo do título do cartão
  getCardHeaderBackground() {
    return this.color === 'transparent' ? 'transparent' : 'white';
  }

  // Retorna o box shadow do cartão
  getCardBoxShadow() {
    // No caso da cor branca, retornamos o box shadow padrão do cartão
    if (this.color !== 'transparent') {
      return 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px';
    } else {
      return '0px 0px 2px 4px ' + this.color;
    }
  }

  /**
   * Calculates the responsive breakpoint. Its is a number that relates with the card width and its goal is to
   * set a proper font-size for the card in order to make its title and buttons responsive
   *
   * Card width range [px]        Font-Size         CSS Class
   *
   * (1) <= 74                    10 px             card-xxx-small
   * (2) 74  --- 114              10 px             card-xx-small
   * (3) 114 --- 134              12 px             card-x-small
   * (4) 134 --- 154              14 px             card-small
   * (5) 154 --- 194              16 px             card-medium-small
   * (6) 194 --- 254              18 px             card-large
   * (7) 254 --- 294              20 px             card-x-large
   * (8) 294 --- 374              22 px             card-xx-large
   * (9) >= 374                   26 px             card-xxx-large
   */
  calcResponsiveBreakpoint() {
    // Card width
    const w = this.imageWidth;

    if (w <= 74) {
      this.responsiveBreakpoint = 1;
    } else if (w >= 74 && w < 114) {
      this.responsiveBreakpoint = 2;
    } else if (w >= 114 && w < 134) {
      this.responsiveBreakpoint = 3;
    } else if (w >= 134 && w < 154) {
      this.responsiveBreakpoint = 4;
    } else if (w >= 154 && w < 194) {
      this.responsiveBreakpoint = 5;
    } else if (w >= 194 && w < 254) {
      this.responsiveBreakpoint = 6;
    } else if (w >= 254 && w < 294) {
      this.responsiveBreakpoint = 7;
    } else if (w >= 294 && w < 374) {
      this.responsiveBreakpoint = 8;
    } else if (w >= 374) {
      this.responsiveBreakpoint = 9;
    }
  }
}
