export class UserProfile {
    photo: string;
    occupation: string = '';
    institution: string = '';
    whatsapp: string = '';
    alternativeEmail: string = '';

    constructor(obj = null) {
        if (typeof obj !== 'undefined' && obj !== null) {
            Object.assign(this, obj);
        }
    }
}
