import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InvitesService } from './invites.service';
import { skip, take } from 'rxjs/operators';
import { LanguageService } from '../language/language.service';
import { UserServiceV2 } from '../v2/user/user.service';
import { UtilsService } from '../utils/utils.service';
import { Invite } from './invite/invite.model';
import { InviteRequestPopupComponent } from './invite-request-popup/invite-request-popup.component';
import { AnalyticsService } from '../analytics/analytics.service';
import { NotificationService } from '../notification/notification.service';

@Component({
  selector: 'app-invites',
  templateUrl: './invites.page.html',
  styleUrls: ['./invites.page.scss'],
})
export class InvitesPage implements OnInit, OnDestroy {

  constructor(private navCtrl: NavController, private activatedRoute: ActivatedRoute, private invitesService: InvitesService,
              private loadingController: LoadingController, private alertController: AlertController,
              public lang: LanguageService, private userServiceV2: UserServiceV2, private utils: UtilsService, private modalController: ModalController,
              private analyticsService: AnalyticsService, private notificationService: NotificationService) { }

  authUserSub: Subscription;
  inviteTimeout;
  inviteLinkId = '';
  isWaitingLoginBeforeOpenInvite = false;

  ngOnInit() {
    // Waits the language service figures out the app lang
    this.lang.langObs.pipe(skip(1), take(1)).subscribe(() => {
    });

    this.inviteLinkId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnDestroy(): void {
    if (this.authUserSub) { this.authUserSub.unsubscribe(); }
  }

  ionViewDidEnter() {
    this.openInvite(this.inviteLinkId);
  }

  async openInvite(inviteLinkId: string) {
    try {
      await this.utils.showLoading(this.lang.words.loadings.loadInvite.message);
      const uid = await this.userServiceV2.getUserUid();
      if (uid) {
        const invite = await this.invitesService.getInvite(inviteLinkId);
        await this.utils.closeLoading();
  
        // Shows the invite request only if it's valid. In addition, invites from the logged user itself are ignored.
        if ((invite.isValid) && (uid !== invite.from.uid)) {
          this.showInviteRequest(invite);
        } else if (invite.isValid === false) {
          this.showInvalidInviteAlert();
        } else {
          this.showInvalidInviteAlert();
        }
      } else {
        await this.utils.closeLoading();
        this.showUserNotLoggedAlert();
      }
    } catch (err) {
      console.log(err);
      await this.utils.closeLoading();
      this.onInviteError(err);
    }
  }

  /**
   * Shows an invite request
   * @param invite Invite to be showed
   */
  async showInviteRequest(invite: Invite) {
    const modal = await this.modalController.create({
      component: InviteRequestPopupComponent,
      cssClass: 'app-invite-modal',
      backdropDismiss: false,
      componentProps: {
        // tslint:disable-next-line:object-literal-shorthand
        invite: invite
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data === 'accept') {
      this.onAcceptInvite(invite);
    } else if (data === 'refuse') {
      this.onRefuseInvite(invite);
    }
  }

  async onAcceptInvite(invite: Invite) {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: this.lang.words.loadings.acceptInvite.message,
      spinner: 'dots',
      animated: false
    });

    this.invitesService.acceptInvite(invite)
      .then(res => {
        this.onInviteAccepted(res, invite);
      })
      .catch(err => {
        this.onInviteError(err);
      });

    await loading.present();
  }

  async onRefuseInvite(invite: Invite) {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: this.lang.words.loadings.refuseInvite.message,
      spinner: 'dots',
      animated: false
    });

    this.invitesService.refuseInvite(invite)
      .then(res => {
        this.onInviteRefused(res, invite);
      })
      .catch(err => {
        this.onInviteError(err);
      });

    await loading.present();
  }

  async onInviteAccepted(res: string, invite: Invite) {
    this.analyticsService.logEvent('invite_contact_accept', { id: invite.id });
    this.loadingController.dismiss();

    this.notificationService.createNewContactNotification(invite);

    const modal = await this.modalController.create({
      component: InviteRequestPopupComponent,
      cssClass: 'app-invite-modal',
      backdropDismiss: false,
      componentProps: {
        alreadyInContactList: true,
        // tslint:disable-next-line:object-literal-shorthand
        invite: invite
      }
    });
    await modal.present();
    await modal.onWillDismiss();
    this.navCtrl.navigateRoot('activities?tab=contacts');
  }

  onInviteRefused(res: string, invite: Invite) {
    this.analyticsService.logEvent('invite_contact_refuse', { id: invite.id });
    this.loadingController.dismiss();
    this.utils.showToast(this.lang.words.toasts.inviteRefused.message)
    this.navCtrl.navigateRoot('home');
  }

  /**
   * Shows an alert telling the user that the invite is no longer valid.
   */
  async showInvalidInviteAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.invalidInvite.header,
      subHeader: this.lang.words.alerts.invalidInvite.subHeader,
      message: this.lang.words.alerts.invalidInvite.message,
      buttons: [this.lang.words.common.ok]
    });
    await alert.present();
    await alert.onWillDismiss();
    this.alertController.dismiss();
    this.navCtrl.navigateRoot('home');
  }

  async showUserNotLoggedAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      subHeader: this.lang.words.alerts.inviteUserNotLogged.subHeader,
      backdropDismiss: false,
      buttons: [
        {
          text: this.lang.words.alerts.inviteUserNotLogged.buttonNewAccount,
          handler: () => {
            this.isWaitingLoginBeforeOpenInvite = true;
            this.navCtrl.navigateForward('/sign-up/default?langSelector=true');
          }
        }, {
          text: this.lang.words.alerts.inviteUserNotLogged.buttonAlreadyHaveAnAccount,
          handler: () => {
            this.isWaitingLoginBeforeOpenInvite = true;
            this.navCtrl.navigateForward('/login/default?langSelector=true');
          }
        }
      ]
    });
    await alert.present();
  }

  async onInviteError(err: any) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      subHeader: this.lang.words.alerts.inviteError.subHeader,
      message: this.lang.words.alerts.inviteError.message,
      buttons: [this.lang.words.common.ok]
    });
    await alert.present();
    await alert.onWillDismiss();
    this.alertController.dismiss();
    this.navCtrl.navigateRoot('home');
  }
}
