import { Injectable } from '@angular/core';
import { User } from '../user.model';
import { StorageService } from 'src/app/storage/storage.service';
import { UserDataApiService } from './user-data-api/user-data-api.service';
import { Globals } from 'src/app/globals/globals';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  // User Updates
  userUpdates = new Subject<User>();

  user: User = new User();

  constructor(private database: StorageService, private userDataApi: UserDataApiService, private app: Globals) {
   }

  /**
   * Loads the user document
   * @param lang App language
   * @param uid User uid. It may be empty or null if the user is not logged in
   */
  async loadUser(uid: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('[user-data] Load user started. uid = ', uid);

        // Key to access the user object in database.
        let key = `user-${uid}`;

        // Gets the user from database.
        let user = await this.database.getObject(key);

        let isFirstSync = false;

        // If the user were not found in local database we need aditional steps...
        if (!user) {
          // console.log('[user-data] User not found in local database');
    
          isFirstSync = true;

          // Starts with the default user.
          user = { uid }
        }

        Object.assign(this.user, user);

        try {
          await this.syncUser(key);
        } catch (err) {
        }
        resolve(this.user);
      } catch(err) {
        reject(err);
      }
    });
  }

  /**
   * Gets the user synced. It means that it will get the local data and merge with the current
   * user doc on cloud. It will trows an error if the request for the updated version of the
   * user doc on cloud fails.
   * @param uid 
   * @returns 
   */
  getUserSynced(uid: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        // Key to access the user object in database.
        let key = `user-${uid}`;

        // Gets the user from database.
        let user = await this.database.getObject(key);

        // If the user were not found in local database we need aditional steps...
        if (!user) {
          // Starts with the default user.
          user = new User({ uid })
        }

        // Pull: Get the user doc from cloud
        const response = await this.userDataApi.getUserDoc();
        const userDoc = response.user;
      
        Object.assign(user, userDoc);

        resolve(user);
      } catch(err) {
        reject(err);
      }
    });
  }

  clearCurrentUser() {
    Object.assign(this.user, new User());
  }

  async onUserUpdated(updates: any) {
    // Important limitation: we are considering that all updates regarding the user statistics
    // comes alone, without any other updates in other fields. In addition, we'are considering
    // that all updates will increment by 1. 
    if (updates.statistics) {
      Object.keys(updates.statistics).forEach(key => {
        if (this.user.statistics[key] >= 0) {
          this.user.statistics[key]++;
        } else {
          this.user.statistics[key] = 1;
        }
      })
    } else {
      Object.assign(this.user, updates);
    }

    // Key to access the user object in database.
    let key = `user-${this.user.uid}`;

    // Saves the synced aac boards in the local database.
    await this.database.setObject(key, this.user);

    this.userUpdates.next(this.user);

    // console.log('onUserUpdated => ', this.user);
  }

   /**
   * Syncs the user doc.
   * It makes a "pull" to get the changes from cloud
   */
   private async syncUser(key: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        // Gets the last time the database was synced.
        // It will be considered 0 if the database hasn't being synced yet.
        let lastSync = await this.database.getObject(`last-sync-${key}`);
        if (!lastSync) { lastSync = 0; }
  
        // console.log('[user-data] syncUser Last sync: ', lastSync);
        // console.log('[user-data] syncUser Getting changes from cloud');
  
        // Pull: Get the cards where updateAt is greater than lastSync.
        const response = await this.userDataApi.getUserDoc();
        const userDoc = response.user;
  
        // console.log('[user-data] syncUser gotten from cloud: ', userDoc);
  
        // Updates the card in the local database.
        Object.assign(this.user, userDoc);
  
        // console.log('[user-data] syncUser Getting changes from cloud done!');
        // console.log('[user-data] syncUser Last sync got from server: ', response.serverTimestamp);
  
        // Sets the last sync to server time that the boards were fetched.
        lastSync = response.serverTimestamp;
        await this.database.set(`last-sync-${key}`, lastSync);
  
        // Saves the synced aac boards in the local database.
        await this.database.setObject(key, this.user);
  
        // console.log('[user-data] syncUser All done!');
        
        resolve(this.user);
      } catch (err) {
        console.log('[user-data] syncUser Error');
        console.log('[user-data] ', err);
        reject(err);
      }
    });
  }
}
