import { Globals } from 'src/app/globals/globals';
import { WalkthroughService } from 'src/app/walkthrough/walkthrough.service';
import { Injectable } from '@angular/core';
import { LanguageService } from '../language/language.service';
import { WalkthroughStep } from './walkthrough.model';

@Injectable({
  providedIn: 'root'
})

export class WalkthroughData {

    tooltips: WalkthroughStep[];
    walkthroughSteps: WalkthroughStep[];

    constructor(private lang: LanguageService, public wtservice: WalkthroughService, public app: Globals) {
        this.refresh();
    }
    
    refresh() {
        this.walkthroughSteps = [
            {
                targetElementId: 'activity-card-0',
                text: this.lang.words.walkthrough.openActivity,
                placementDesktop: 'right',
                placementMobile: 'right',
                page: 'activities'
            },
            {
                targetElementId: 'preview-play-button',
                text: this.lang.words.walkthrough.startPreview,
                placementDesktop: 'top',
                placementMobile: 'top',
                page: 'activity-preview'
            },
            {
                targetElementId: 'share-button',
                text: this.lang.words.walkthrough.shareActivity,
                placementDesktop: 'bottom',
                placementMobile: 'bottom',
                page: 'activity-preview'
            },
            // {
            //     targetElementId: 'user-menu-icon',
            //     text: 'Faltam X dias',
            //     placementDesktop: 'bottom',
            //     placementMobile: 'bottom',
            //     page: 'activities',
            //     type: 'conditional',
            //     condition: this.wtservice.daysOfTrialLeftControler,
            //     dynamicText: true,
            //     dynamicTextFunction: this.wtservice.daysOfTrialLeftText,
            //     theme: 'yellow'
            // },
            {
                targetElementId: 'login-facebook-btn',
                text: this.lang.words.walkthrough.loginWithFacebook,
                placementDesktop: 'bottom-left',
                placementMobile: 'bottom-left',
                page: 'login',
                type: 'conditional',
                condition: this.wtservice.loginWithFacebookController,
                theme: 'yellow'
            },
            {
                targetElementId: 'activities-social-counter',
                text: this.lang.words.walkthrough.activitiesSocialCounter,
                placementDesktop: 'bottom-left',
                placementMobile: 'bottom-left',
                page: 'activities',
                theme: 'yellow'
            },
            {
                targetElementId: 'activities-add-button',
                text: this.lang.words.walkthrough.activitiesAdd,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'activities',
                theme: 'red' 
            },
            {
                targetElementId: 'person-add-btn',
                text: this.lang.words.walkthrough.personAdd,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'activities-more-popover',
                theme: 'red'
            },
            {
                targetElementId: 'new-group-btn',
                text: this.lang.words.walkthrough.newGroup,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'activities-more-popover',
                theme: 'red'
            },
            {
                targetElementId: 'activities-shared-add-button',
                text: this.lang.words.walkthrough.sharedAddButton,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'board-shared',
                theme: 'red'
            },
            {
                targetElementId: 'attach-activity-btn',
                text: this.lang.words.walkthrough.attachActivity,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'activities-shared-more-popover',
                theme: 'red'
            },
            {
                targetElementId: 'activity-shared-0', // result 
                text: this.lang.words.walkthrough.resultsActivity,
                placementDesktop: 'right',
                placementMobile: 'right',
                page: 'board-shared',
                theme: 'red'
            },
            {
                targetElementId: 'acc-share-button', 
                text: this.lang.words.walkthrough.accsharebutton,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'acc',
                theme: 'red'
            },
            {
                targetElementId: 'acc-share-board-btn', 
                text: this.lang.words.walkthrough.accshareboardbtn,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'acc-share-popover',
                theme: 'red'
            },
            {
                targetElementId: 'history-sentences-btn', 
                text: this.lang.words.walkthrough.historySentencesBtn,
                placementDesktop: 'left',
                placementMobile: 'left',
                page: 'acc',
                theme: 'red'
            },
            {
                targetElementId: 'star-favorite-btn', 
                text: this.lang.words.walkthrough.starFavoriteBtn,
                placementDesktop: 'right',
                placementMobile: 'right',
                page: 'acc-last-sentences',
                theme: 'red'
            },
            {
                targetElementId: 'favorites-btn', 
                text: this.lang.words.walkthrough.favoritesBtn,
                placementDesktop: 'right',
                placementMobile: 'bottom',
                page: 'acc-last-sentences',
                theme: 'red'
            },
        ];
    }
}
