import { Injectable } from '@angular/core';
import { LanguageWords } from './language.model';
import { StorageService } from '../storage/storage.service';
import { BehaviorSubject } from 'rxjs';
import * as LangPtBrJson from './languages/pt-BR.json';
import * as LangEnGBJson from './languages/en-GB.json';
import * as LangArSAJson from './languages/ar-SA.json';
import * as LangItITJson from './languages/it-IT.json';
import * as LangJaJPJson from './languages/ja-JP.json';
import * as LangHyAMJson from './languages/hy-AM.json';
import * as LangHeILJson from './languages/he-IL.json';
import * as LangZhCNJson from './languages/zh-CN.json';
import * as LangZhTWJson from './languages/zh-TW.json';
import * as LangEsESJson from './languages/es-ES.json';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  words: LanguageWords;
  private langBS = new BehaviorSubject<string>('');
  langObs = this.langBS.asObservable();

  constructor(private storage: StorageService) {
    // Sets the language to default first.
    this.setLang('pt-BR');

    // Reads the last language code the app was using and set the current language.
    this.storage.get('app-language').then((langCode) => {
      if (langCode) {
        this.setLang(langCode);
      } else {
        // If the user hasn't set the language yet, we read the preferred language from the browser
        // and set the app language accordingly.
        const userPreferredLangs = window.navigator.languages || [window.navigator.language];

        if (userPreferredLangs[0].includes('pt')) {
          this.setLang('pt-BR');
        } else if (userPreferredLangs[0].includes('es')) {
          this.setLang('es-ES');
        } else if (userPreferredLangs[0].includes('ar-SA')) {
          this.setLang('ar-SA');
        } else if (userPreferredLangs[0].includes('it')) {
          this.setLang('it-IT');
        } else if (userPreferredLangs[0].includes('ja-JP')) {
          this.setLang('ja-JP');
        } else if (userPreferredLangs[0].includes('hy-AM')) {
          this.setLang('hy-AM');
        } else if (userPreferredLangs[0].includes('he-IL')) {
          this.setLang('he-IL');
        } else if (userPreferredLangs[0].includes('zn-CN')) {
          this.setLang('he-CN');
        } else if (userPreferredLangs[0].includes('zn-TW')) {
          this.setLang('he-TW');
        }else {
          // Falls back to English
          this.setLang('en-GB');
        }
      }
    });

    // console.log(window.navigator.languages || [window.navigator.language]);
  }

  /**
   * Sets the current language that the app is using. This will translate all words in the app layout (not on contents like boards or cards)
   * to the selected language.
   * @param langCode ISO Language code (http://www.lingoes.net/en/translator/langcode.htm)
   */
  setLang(langCode: string) {
    switch (langCode) {
      case 'en-GB': this.words = (LangEnGBJson as any).default; break;
      case 'ar-SA': this.words = (LangArSAJson as any).default; break;
      case 'it-IT': this.words = (LangItITJson as any).default; break;
      case 'ja-JP': this.words = (LangJaJPJson as any).default; break;
      case 'hy-AM': this.words = (LangHyAMJson as any).default; break;
      case 'he-IL': this.words = (LangHeILJson as any).default; break;
      case 'zn-CN': this.words = (LangZhCNJson as any).default; break;
      case 'zn-TW': this.words = (LangZhTWJson as any).default; break;
      case 'es-ES': this.words = (LangEsESJson as any).default; break;
      default: this.words = (LangPtBrJson as any).default; break;
    }

    // Set and save the current language so the next time the app will be presented on that language
    this.langBS.next(langCode);
    this.storage.set('app-language', langCode);
  }
}
