import { EventEmitter, Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AacBoard } from './aac-board/aac-board.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DatabaseService } from '../database/database.service';
import { AacDataService } from './aac-data/aac-data.service';
import { UserServiceV2 } from '../v2/user/user.service';
import { AacDataApiService } from './aac-data/aac-data-api/aac-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class AacService {

  private aacBoardsBS = new BehaviorSubject<AacBoard[]>([]);
  aacBoardsObs = this.aacBoardsBS.asObservable();

  updateBoardRoutesEvent = new EventEmitter();

  sentenceCardsLength = 0;
  aacBoards: AacBoard[] = [];
  aacBoardsOnlineContents: AacBoard[] = [];
  parentBoard: AacBoard = new AacBoard();
  spokenSentences = [];

  aacBoardsSub: Subscription;
  spokenSentencesSub: Subscription;
  aacBoardsChangedSub: Subscription;

  whoShared: string;

  constructor(private app: Globals, private firestore: AngularFirestore,
    private databaseService: DatabaseService, private aacDataService: AacDataService,
    private userService: UserServiceV2, private aacDataApi: AacDataApiService) {}

  initParentBoard(): AacBoard {
    const parentBoard = this.aacBoards.find(o => o.title === 'root');
    // console.log(parentBoard);
    if (parentBoard) {
      this.parentBoard = parentBoard;
    }
    return this.parentBoard;
  }

  async loadAacBoards(): Promise<Array<AacBoard>> {
    return new Promise(async (resolve, reject) => {
      this.clearParentBoard();
      this.userService.getUserUid().then(uid => {
        // console.log('[aac.service] uid => ', uid);
        this.aacDataService.loadBoards(this.app.lang, uid).then(async (boards) => {
          // console.log('[aac.service] boards => ', boards);
          await this.aacBoardsChanged(boards);
          this.getSpokenSentencesFromDB(uid, this.app.langSuffix);
          resolve(boards);
        }).catch(async sampleBoards => {
          // console.log('[aac.service] sample boards => ', sampleBoards);
          await this.aacBoardsChanged(sampleBoards);
          this.getSpokenSentencesFromDB(uid, this.app.langSuffix);
          reject(sampleBoards);
        }).finally(() => {
          if (this.aacBoardsChangedSub) { this.aacBoardsChangedSub.unsubscribe(); }
          this.aacBoardsChangedSub = this.aacDataService.aacBoardsChangedBS.subscribe(boards => {
            this.aacBoardsChanged(boards);
          })
        });
      });
    })
  }

  /**
   * Clears the parent board
   */
  clearParentBoard() {
    this.parentBoard = new AacBoard();
  }

  dispatchUpdateBoardRoutesEvent() {
    this.updateBoardRoutesEvent.emit();
  }

  async aacBoardsChanged(aacBoards: AacBoard[]) {
    this.aacBoards = aacBoards;
    this.refreshParentBoard();
    this.aacBoardsBS.next(aacBoards);
  }

  refreshAacBoard() {
    this.aacBoardsBS.next(this.aacBoards);
  }

  /**
   * Gets the aac root board
   * @returns root board
   */
  getRootBoard() {
    return this.aacBoards.find(board => board.title === 'root');
  }

  refreshParentBoard() {
    if (this.parentBoard?._id !== '') {
      const parentBoard = this.aacBoards.find(board => board._id === this.parentBoard._id);
      Object.assign(this.parentBoard, parentBoard);
    } else {
      this.initParentBoard();
    }

    // console.log(this.parentBoard);
  }

  setParentBoardById(id: string) {
    this.parentBoard = this.aacBoards.find(board => board._id === id);
  }

  getParentBoardId(): string {
    return this.parentBoard._id;
  }

  /**
   * Gets the parent board of a given board. If boardId is not specified, it will return the current parent board.
   * @param boardId Board Id
   * @returns Parent board
   */
  getParentBoard(boardId?: string): AacBoard {
    if (boardId) {
      return this.aacBoards.find(board => board.cards.includes(boardId));
    } else {
      return this.parentBoard;
    }
  }

  getBoardById(boardId: string, deepClone: boolean = false): AacBoard {
    const board = this.aacBoards.find(b => b._id === boardId);
    if (deepClone) {
      return JSON.parse(JSON.stringify(board));
    } else {
      return board;
    }
  }

  getCardsByIds(cardsIds = []) {
    let cardsIdInline = '';
    for (const id of cardsIds) {
      cardsIdInline += id + ' ';
    }
    const cards = this.aacBoards.filter(board => cardsIdInline.includes(board._id));
    return cards;
  }

  getCardsByIdsOrdered(cardsIds = []) {
    let cardsIdInline = '';
    for (const id of cardsIds) {
      cardsIdInline += id + ' ';
    }
    const cards = this.aacBoards.filter(board => cardsIdInline.includes(board._id));

    // const cardsOrdered = [];

    // cardsIds.forEach(cardId => {
    //   const card = cards.find(c => c._id === cardId);
    //   if (card !== undefined) {
    //     cardsOrdered.push(card);
    //   }
    // });

    // return cardsOrdered;

    let cardsOrdered = new Array<AacBoard>(cardsIds.length);
    const cardsWithoutOrder = [];
    cardsIds.forEach(cardId => {
      const card = cards.find(c => c._id === cardId);
      if (card !== undefined) {
        if (this.parentBoard?.cardsOrder) {
          const index = this.parentBoard.cardsOrder.findIndex(id => id === cardId);
          if (index >= 0 && cardsOrdered[index] === undefined) {
            cardsOrdered[index] = card;
          } else {
            cardsWithoutOrder.push(card);
          }
        } else {
          cardsOrdered.push(card);
        }
      }
    });

    // Remove eventuais posicoes vazias do array
    cardsOrdered = cardsOrdered.filter(card => card !== undefined);

    return cardsOrdered.concat(cardsWithoutOrder);
  }

  listAacMedias() {
    const medias = [];
    for (const board of this.aacBoards) {
      if (board.img !== '') { medias.push(board.img); }
      if (board.audio !== '') { medias.push(board.audio); }
    }
    return medias;
  }

  /**
   * Clear the aac boards that is inside the app
   */
  clearAacBoards() {
    if (this.aacBoardsSub) { this.aacBoardsSub.unsubscribe(); }
    if (this.spokenSentencesSub) { this.spokenSentencesSub.unsubscribe(); }

    this.aacBoards = [];
    this.parentBoard = new AacBoard();
    this.aacBoardsBS.next([]);
    this.aacBoardsSub = null;
    this.spokenSentencesSub = null;
  }

  async addSharedAacBoard(sharedAac: Object) {
    return this.firestore.collection('shared-aac').add(sharedAac);
  }

  async loadSharedAacBoard(id: string): Promise<void> {

    return new Promise((resolve, reject) => {
      const sharedAacBoardSub = this.firestore.collection('shared-aac').doc(id).valueChanges().subscribe((data: any) => {
        this.aacBoards = this.aacBoards.concat(data.cards);
        this.aacBoards.push(data.board);
        this.setParentBoardById(data.board._id);
        this.whoShared = data.sharedByName;

        // console.log(data);
        sharedAacBoardSub.unsubscribe();

        resolve();
      });

    });

  }

  discoveryBoards(boardId: string, srcBoards = [], boards = []) {
    const board = srcBoards.find(b => b._id === boardId);

    if (typeof board === 'undefined' || board === null) { return; }

    for (const cardId of board.cards) {
      this.discoveryBoards(cardId, srcBoards, boards);
    }
    boards.push(board);
  }

  discoveryBoardsAndChangeIds(boardId: string, srcBoards = [], boards = []): string {
    let board; 
    try {
      board = JSON.parse(JSON.stringify(srcBoards.find(b => b._id === boardId)));
    } catch (err) {
      return;
    }
    
    if (typeof board === 'undefined' || board === null) { return; }
    
    const newCardsIds = [];
    
    delete board.owner;
    delete board.createdAt;
    delete board.updatedAt;
    delete board.__v;

    board._id = this.firestore.createId();

    for (const cardId of board.cards) {
      const newId = this.discoveryBoardsAndChangeIds(cardId, srcBoards, boards);
      if (newId) {
        newCardsIds.push(newId);
      }
    }

    board.cards = newCardsIds;
    boards.push(board);

    return board._id;
  }

  changeBoardId(board: AacBoard) {
    board._id = this.firestore.createId();
    // console.log(board._id);

    return board._id;
  }

  async saveSharedAacBoard(sharedBoardLinkId: string, langSuffix: string, root: AacBoard = null): Promise<void> {
    return this.aacDataApi.saveSharedBoard(sharedBoardLinkId, langSuffix, root);
  }

  async addSharedAacSentence(sharedSentence: Object) {
    return this.firestore.collection('shared-sentence').add(sharedSentence);
  }

  async loadSharedAacSentence(id: string): Promise<AacBoard[]> {

    return new Promise((resolve, reject) => {
      const sharedAacSentenceSub = this.firestore.collection('shared-sentence').doc(id).valueChanges().subscribe((data: any) => {
        // console.log(data.cards);
        sharedAacSentenceSub.unsubscribe();
        resolve(data.cards);
      });
    });

  }

  /**
   * Gets all aac boards that are actually part of the current user's set of boards.
   * It's known that aacBoards may contain unlinked boards (that is no longer
   * connected to the user set).
   */
  getOnlyLinkedBoards() {
    const aacBoards = [];
    this.discoveryBoards(this.getRootBoard()._id, this.aacBoards, aacBoards);
    return aacBoards;
  }

  /**
   * Moves a card or board (category) into other board (category)
   * @param board Card or Board to be moved
   * @param oldParent Old board's parent (where the movedBoard was in)
   * @param newParent New board's parent (where the movedBoard will move to)
   */
  moveAacBoard(board: AacBoard, oldParent: AacBoard, newParent: AacBoard) {
    this.databaseService.updateAacBoard(newParent, { cards: this.app.firebaseArrayUnion(board._id) })
    this.databaseService.updateAacBoard(oldParent, { cards: this.app.firebaseArrayRemove(board._id) })
  }

  filterSearchBoards(searchText: string, srcBoards, filteredBoards = []) {
    srcBoards = srcBoards.filter((board) => board._id !== this.parentBoard._id);

    let innerCardsCategories = [];

    for (let board of srcBoards) {
      let boardTitle = board.title.normalize("NFD").replace(/[\u0300-\u036f]/g, '');

      let boardSearchText = '';
      if (board.imgSearchText) {
        boardSearchText = board.imgSearchText.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
      }

      if (boardTitle.toUpperCase().match(searchText) || boardSearchText.toUpperCase().match(searchText)) {
        filteredBoards.push(board);
      }
    }

    filteredBoards.sort(compare);
    innerCardsCategories.sort(compare);
    filteredBoards = filteredBoards.concat(innerCardsCategories);
    filteredBoards = filteredBoards.filter((item, pos, self) => self.findIndex(o => o.title === item.title) === pos);

    // console.log(filteredBoards);
    return filteredBoards;

    function compare(a: any, b: any) {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    }
  }

  saveSpokenSentence(sentenceCards: AacBoard[]) {
    return this.databaseService.saveSpokenSentence(sentenceCards);
  }

  updateSpokenSentence(sentence: any) {
    return this.databaseService.updateSpokenSentence(sentence);
  }

  async getSpokenSentencesFromDB(uid: string, langSuffix: string) {
    if (!this.spokenSentencesSub && uid) {
      this.spokenSentencesSub = this.firestore.collection(`users/${uid}/spoken-sentences${langSuffix}`).valueChanges().subscribe(data => {
        this.spokenSentences = data;
      });
    }
  }

  getSpokenSentences() {
    return this.spokenSentences;
  }
}
