import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AacBoard } from 'src/app/aac/aac-board/aac-board.model';
import { ActivitiesService } from 'src/app/activities/activities.service';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-card-more-popover',
  templateUrl: './card-more-popover.component.html',
  styleUrls: ['./card-more-popover.component.scss'],
})
export class CardMorePopoverComponent implements OnInit {

  @Input() board: any;
  canMoveBoard = true;
  canCopyBoard = true;
  scope = 'activities';

  constructor(public app: Globals, private popoverController: PopoverController, public lang: LanguageService, public activitiesService: ActivitiesService) { }

  ngOnInit() {
    if (window.location.pathname.includes('aac')) { this.scope = 'aac'; }

    // if (this.board.isContact || this.board.isGroup || this.board.isSharedSpace || this.activitiesService.getParentBoard().isSharedSpace) this.canMoveBoard = false;
    if (this.board.isContact || this.board.isGroup || this.board.isSharedSpace) this.canCopyBoard = false;
  }

  onEditBtnClicked() {
    this.popoverController.dismiss('edit');
  }

  onDeleteBtnClicked() {
    this.popoverController.dismiss('delete');
  }

  onMoveBtnClicked() {
    this.popoverController.dismiss('move');
  }

  onCopyBtnClicked() {
    this.popoverController.dismiss('copy');
  }
}
