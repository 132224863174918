export class AppSubscription {
    id = '';
    origin = '';
    inAppProduct: any;
    expiryTimeMillis = 0;
    isActive = false;
    cancelWhenExpire = false;

    constructor(obj = null) {
        if (typeof obj !== 'undefined' && obj !== null) {
            Object.assign(this, obj);
        }
    }
}
