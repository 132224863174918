import { Injectable } from '@angular/core';
import { User } from 'src/app/user/user.model';
import { UserApiService } from './user-api/user-api.service';
import { UserDataService } from 'src/app/user/user-data/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserServiceV2 {

  constructor(private userApi: UserApiService, private userDataService: UserDataService) {
  }

  loadUser(uid: string) {
    return this.userDataService.loadUser(uid);
  }

  clearCurrentUser() {
    return this.userDataService.clearCurrentUser();
  }

  /**
   * Gets the current Expressia user
   * @param opts (refresh: true => fetches the user doc from server)
   * @returns Expressia user
   */
  async getUser(opts = { refresh: false }): Promise<User> {
    // First, gets the user stored locally.
    let user = new User(localStorage.getItem('user'));

    // Gets the current logged user's uid
    const uid = await this.userApi.getCurrentUserUid();

    // Should we refresh it?
    if (opts.refresh && uid) {
      try {
        // Gets the user doc
        const userDoc = await this.userApi.getUserDoc(uid);

        // Creates a new instance of user
        user = new User(userDoc);

        // Saves the user locally
        localStorage.setItem('user', JSON.stringify(user));
      } catch (err) {
        // Falied to fetch user doc.
      }
    }
    return user;
  }

  /**
   * Gets the current user's uid
   * return user's uid
   */
  async getUserUid(): Promise<string> {
    return new Promise((resolve, reject) => {
      const uid = localStorage.getItem('user-uid');
      if (uid) {
        resolve(uid);
      } else {
        this.userApi.getCurrentUserUid().then(userUid => {
          resolve(userUid);
        }).catch(err => {
          resolve('');
        });
      }
    });
  }
}
