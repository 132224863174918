import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Globals } from '../globals/globals';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private loadingController: LoadingController, private toastController: ToastController,
    private app: Globals) { }

  formatDate(date: Date, locale: string) {
    const localeTimeArray = date.toLocaleTimeString(locale).split(':');
    return `${date.toLocaleDateString(locale)} ${localeTimeArray[0]}:${localeTimeArray[1]}`;
  }

  formatDuration(duration: number) {
    let seconds = Math.floor(duration / 1000);
    let minutes = 0;
    let hours = 0;

    while (seconds >= 60) {
      minutes++;
      seconds -= 60;
    }

    while (minutes >= 60) {
      hours++;
      minutes -= 60;
    }

    let durationFormated = '';

    if (hours) {
      durationFormated = `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes) {
      durationFormated = `${minutes}m ${seconds}s`;
    } else {
      durationFormated = `${seconds}s`;
    }

    return durationFormated;
  }

  millisToHoursAndMin(millis: number) {
    let minutes = Math.floor(millis / (60 * 1000));
    let hours = 0;

    while (minutes >= 60) {
      hours++;
      minutes -= 60;
    }

    return `${hours}h ${minutes}m`;
  }

  async showLoading(message: string) {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: message,
      spinner: 'dots',
      animated: false
    });
    await loading.present();
  }

  async closeLoading() {
    this.loadingController.getTop().then(top => top?.dismiss());
  }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      cssClass: 'app-toast',
      duration: 2000
    });
    toast.present();
  }

  /* View in fullscreen */
  openFullscreen() {
    if (this.app.isRunningOnBrowser()) {
      const doc = (document as any).documentElement;

      if (doc.requestFullscreen) {
        doc.requestFullscreen();
      } else if (doc.webkitRequestFullscreen) { /* Safari */
        doc.webkitRequestFullscreen();
      } else if (doc.msRequestFullscreen) { /* IE11 */
        doc.msRequestFullscreen();
      }
    } else if (this.app.isRunningOnAndroidApp()) {
      // TODO: Entrar Fullscreen app android
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.app.isRunningOnBrowser()) {
      try {
        const doc = (document as any);
        if (doc.exitFullscreen) {
          doc.exitFullscreen();
        } else if (doc.webkitExitFullscreen) { /* Safari */
          doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) { /* IE11 */
          doc.msExitFullscreen();
        }
      } catch (err) {
        console.log(err);
      }
    } else if (this.app.isRunningOnAndroidApp()) {
      // TODO: sair Fullscreen app android
    }
  }
}
