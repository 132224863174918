import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EditorToolsService } from '../editor-tools.service';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-image-source-more-popover',
  templateUrl: './image-source-more-popover.component.html',
  styleUrls: ['./image-source-more-popover.component.scss'],
})
export class ImageSourceMorePopoverComponent implements OnInit {

  @Input() canDeleteImg = false;
  @Input() webSearchEnabled = true;

  constructor(private editorToolsService: EditorToolsService, private popoverController: PopoverController,
    public lang: LanguageService) { }

  ngOnInit() {}

  loadImageFromDevice(ev: any) {
    this.editorToolsService.loadImageFromDevice2(ev).then(data => {
      this.popoverController.dismiss({ action: 'load-image-from-device', data });
    });
  }

  onSearchImageBtnClicked(ev: any) {
    this.popoverController.dismiss({ action: 'search-image'});
  }

  onDeleteImageBtnClicked(ev: any) {
    this.popoverController.dismiss({ action: 'delete-image'});
  }
}
