import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { GroupAddParticipantsComponent } from 'src/app/groups/group-add-participants/group-add-participants.component';
import { InviteComponent } from 'src/app/invites/invite/invite.component';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-user-shared-spaces-more-popover',
  templateUrl: './user-shared-spaces-more-popover.component.html',
  styleUrls: ['./user-shared-spaces-more-popover.component.scss'],
})
export class UserSharedSpacesMorePopoverComponent implements OnInit {

  constructor(private modalController: ModalController, private popoverController: PopoverController, public lang: LanguageService) { }

  ngOnInit() {}

  async onAddContactBtnClicked() {
    this.popoverController.dismiss();
    const modal = await this.modalController.create({
      component: InviteComponent,
      cssClass: 'app-modal-share-invite',
      backdropDismiss: true,
    });
    await modal.present();
  }

  async onNewGroupBtnClicked() {
    this.popoverController.dismiss();
    const modal = await this.modalController.create({
      component: GroupAddParticipantsComponent,
      cssClass: 'app-box-shadow-none',
      backdropDismiss: true,
      showBackdrop: false,
      componentProps: {
        title: this.lang.words.groups.newGroup,
        subtitle: this.lang.words.groups.addParticipants
      }
    });
    await modal.present();
  }
}
