import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { Globals } from 'src/app/globals/globals';
import { EditorToolsService } from '../editor-tools.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LanguageService } from 'src/app/language/language.service';
import { ImageCropV2Component } from '../image-crop-v2/image-crop-v2.component';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-image-search-v2',
  templateUrl: './image-search-v2.component.html',
  styleUrls: ['./image-search-v2.component.scss'],
})
export class ImageSearchV2Component implements OnInit, AfterViewChecked {

  @ViewChild('searchInput', { static: true }) searchInputIonInput: IonInput;

  theme = 'aac';
  searchText = '';
  isSearching = false;
  isOnline = true;
  forceInputFocus = true;

  imgSearchResults = [];

  constructor(private http: HttpClient, public alertController: AlertController, private editorToolsService: EditorToolsService,
    private app: Globals, public lang: LanguageService, private analyticsService: AnalyticsService,
    public fns: AngularFireFunctions, private modalController: ModalController) { }

  ngOnInit() {
    if (window.location.pathname.includes('activities')) { this.theme = 'activities'; }
  }

  ngAfterViewChecked(): void {
    this.onInputBlur(null);
  }

  onBackBtnClicked(ev: any) {
    this.modalController.dismiss();
  }

  onInputBlur(ev: any) {
    if (this.forceInputFocus) {
      this.setFocusOnSearchInput();
    }
  }

  setFocusOnSearchInput() {
    this.searchInputIonInput.setFocus();
  }

  onInputClicked(ev: any) {
    this.forceInputFocus = true;
  }

  onSearchInfoBtnClicked() {
    this.presentAlert();
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'search-info-alert',
      header: this.lang.words.alerts.searchInfo.header,
      subHeader: '',
      message: this.lang.words.alerts.searchInfo.message,
      buttons: [this.lang.words.alerts.searchInfo.button]
    });

    await alert.present();
  }

  async onImageSelected(src: string, fileFormat: string) {
    this.forceInputFocus = false;
    const imageSearchModal = (await this.modalController.getTop());
    const modal = await this.modalController.create({
      component: ImageCropV2Component,
      componentProps: {
        imgSrc: src,
        imgType: fileFormat,
        imgSearchModal: imageSearchModal
      },
      backdropDismiss: false,
      showBackdrop: false
    });
    await modal.present();
  }

  async onMoreBtnClicked(ev: any) {
  }

  onEnterKeyup() {
    // Pesquisa as imagens
    this.searchImages();
  }

  onSearchBtnClicked() {
    // Pesquisa as imagens
    this.searchImages();
  }


  // Pesquisa as imagens
  searchImages() {
    this.forceInputFocus = false;
    // Se nada foi digitado na pesquisa, não temos o que pesquisar!
    if (this.searchText === '') { return; }

    // Retira o foco do input da pesquisa
    this.removeSearchInputFocus();

    if (!this.isSearching) {
      // V5: new Callable function from Firebase Functions
      this.fns.httpsCallable('imageSearchV5')
        ({ search_text: this.searchText, countryCode: this.app.lang.split('-', 2)[1].toLocaleLowerCase() }).subscribe(
          data => this.imageSearchServerResponse(data),
          err => this.imageSearchServerError(err)
        );

      this.isSearching = true;
    }
  }

  // Retira o foco do input da pesquisa
  removeSearchInputFocus() {
    this.searchInputIonInput.getInputElement().then((input) => input.blur());
  }

  // TODO: Definir um tipo para a resposta que virá do Simplix-Life-Cloud
  imageSearchServerResponse(data: any) {
    console.log(data);
    this.imgSearchResults = data.items;
    console.log(this.imgSearchResults);

    this.isOnline = true;
    this.isSearching = false;
  }

  // Erro ao pesquisar a imagem
  imageSearchServerError(err: any) {
    this.isOnline = false;
    this.isSearching = false;
    console.log(err);
    this.analyticsService.logEvent('image_search_error');
  }
}
