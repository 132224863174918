import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AacBoard } from 'src/app/aac/aac-board/aac-board.model';
import { AacService } from 'src/app/aac/aac.service';
import { ActivitiesService } from 'src/app/activities/activities.service';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { CardSelectEvent } from 'src/app/grid/models/card-select-event.model';

@Component({
  selector: 'app-board-search',
  templateUrl: './board-search.component.html',
  styleUrls: ['./board-search.component.scss'],
})
export class BoardSearchComponent implements OnInit {

  @Input() searchText: string;
  @Input() boards = []; // todas as pranchas existentes na página
  @Input() boardService: AacService | ActivitiesService;
  @Input() parentBoard: AacBoard | ActivityBoard;

  displayedCards = [];

  @Output() select = new EventEmitter<CardSelectEvent>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() moreBtnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.displayedCards = [];

    let parentBoard;
    if (this.parentBoard) {
      parentBoard = this.parentBoard;
    } else {
      parentBoard = this.boardService.getParentBoard();
    }

    let discoveredBoards = [];
    this.boardService.discoveryBoards(parentBoard._id, this.boards, discoveredBoards);

    this.searchText = this.searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    this.displayedCards = this.boardService.filterSearchBoards(this.searchText.toUpperCase().trim(), discoveredBoards);
    console.log(this.displayedCards);
  }

  onBoardSelect(ev: any) {
    this.select.emit(ev);
  }

  onEditBoard(ev: any) {
    this.edit.emit(ev);
  }

  onDeleteBoard(ev: any) {
    this.delete.emit(ev);
  }

  onMoreBtnClicked(ev: any) {
    this.moreBtnClick.emit(ev);
  }

}
