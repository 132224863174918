import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LanguageService } from 'src/app/language/language.service';
import { Globals } from 'src/app/globals/globals';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Component({
  selector: 'app-share-activity-popup',
  templateUrl: './share-activity-popup.component.html',
  styleUrls: ['./share-activity-popup.component.css']
})
export class ShareActivityPopupComponent implements OnInit {
  // Data passed in by componentProps
  @Input() shareUrl: string;
  @Input() imageUrl: string;
  @Input() title: string;

  constructor(private modalController: ModalController, private toastController: ToastController, public lang: LanguageService,
    public app: Globals, public clipboardNative: Clipboard) {
  }

  ngOnInit() { }

  onCloseBtnClicked() {
    this.modalController.dismiss();
  }

  onCopyBtnClicked() {
    if (this.app.isRunningOnBrowser()) {
      console.error('navigator.clipboard');
      navigator.clipboard.writeText(this.shareUrl);
    } else {
      console.error('clipboardNative');
      this.clipboardNative.copy(this.shareUrl);
    }

    this.showCopiedToast();
  }

  async showCopiedToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.linkCopiedToClipboard.message,
      cssClass: 'app-toast',
      duration: 1500
    });
    toast.present();
  }
}
