import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { LanguageService } from 'src/app/language/language.service';
import { UserContact } from 'src/app/user/user-contacts/user-contact/user-contact.model';
import { UserService } from 'src/app/user/user.service';
import { GroupAddSubjectComponent } from '../group-add-subject/group-add-subject.component';
import { GroupsService } from '../groups.service';
import { Globals } from 'src/app/globals/globals';

@Component({
  selector: 'app-group-add-participants',
  templateUrl: './group-add-participants.component.html',
  styleUrls: ['./group-add-participants.component.scss'],
})
export class GroupAddParticipantsComponent implements OnInit, OnDestroy {

  @Input() group: ActivityBoard;
  @Input() participants: UserContact[] = [];
  title = '';
  subtitle = '';
  selectedContacts: UserContact[] = [];
  theme = 'activities';
  private userContactsSub: Subscription;
  contacts: UserContact[] = [];
  hasContacts = false;

  constructor(private modalController: ModalController, private toastController: ToastController, private userService: UserService,
    private groupsService: GroupsService, private loadingController: LoadingController, public lang: LanguageService,
    private alertController: AlertController, public app: Globals) { }

  ngOnInit() {
    setTimeout(() => {
      // Listen to user contacts changes
      this.userContactsSub = this.userService.userContactsObs.subscribe(contacts => {
        // Creates a deep copy of contacts so we can set new properties without modifying the original one.
        this.contacts = JSON.parse(JSON.stringify(contacts));

        this.participants.forEach(participant => {
          const contact = this.contacts.find(c => c.uid === participant.uid);
          if (contact) {
            contact.subtitle = this.lang.words.groups.alreadyAddedToGroup;
            contact.blockSelection = true;
          }
        });

        if (this.contacts.length > 0) this.hasContacts = true;
      });
    });
  }

  ngOnDestroy() {
    if (this.userContactsSub) { this.userContactsSub.unsubscribe(); }
  }

  onBackBtnClicked(ev: any) {
    this.modalController.dismiss();
  }

  async onFabBtnClicked() {
    if (this.group) {
      // If the group already exists, we add the participant to it
      this.addParticipants(this.group, this.selectedContacts);
    } else if (this.selectedContacts.length) {
      const addParticipantsModal = (await this.modalController.getTop());
      const modal = await this.modalController.create({
        component: GroupAddSubjectComponent,
        componentProps: {
          previousModal: addParticipantsModal,
          participants: this.selectedContacts
        },
        showBackdrop: false
      });
      await modal.present();
    } else {
      this.showMustSelectContactToast();
    }
  }

  async showMustSelectContactToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.selectContacts.message,
      cssClass: 'app-toast',
      duration: 3000
    });
    toast.present();
  }

  onAddBtnClicked(ev: any) {
    this.modalController.dismiss('has-to-add');
  }

  onSelectedContactsChange(selectedContacts: UserContact[]) {
    this.selectedContacts = selectedContacts;
  }

  /**
   *  Adds participants to group
   */
  async addParticipants(group: ActivityBoard, participants: UserContact[]) {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: this.lang.words.loadings.addParticipants.message,
      spinner: 'dots',
      animated: false
    });

    this.groupsService.addParticipantsToGroup(group, participants).then(() => {
      this.loadingController.dismiss();
      this.modalController.dismiss();
    }).catch(err => {
      this.onAddParticipantsError();
    }).finally(() => {
      this.loadingController.dismiss();
    });

    await loading.present();
  }

  async onAddParticipantsError() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      subHeader: this.lang.words.alerts.addParticipantsError.subHeader,
      message: this.lang.words.alerts.addParticipantsError.message,
      buttons: [this.lang.words.common.ok]
    });
    await alert.present();
  }
}
