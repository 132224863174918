import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LanguageService } from 'src/app/language/language.service';
import { ToastController } from '@ionic/angular';
import packageInfo from 'package.json';
import { Injectable } from '@angular/core';
import { Globals } from './../../globals/globals';

@Injectable({
  providedIn: 'root'
})
export class UpdaterService {

  constructor(public toastController: ToastController, private lang: LanguageService, private firestore: AngularFirestore,
              private app: Globals) {
    // Does not show the update toast when the app is running natively (app installed on android or iOS)
    if (!this.app.isRunningOnNative()) {
      this.firestore.collection('system').doc('app').valueChanges().subscribe((appDoc: any) => {
        if (appDoc && appDoc.version) {
          this.checkForUpdate(appDoc.version);
        }
      });
    }
  }

  isToastCreated = false;

  checkForUpdate(lastestVersion: string) {
    const currentVersion = packageInfo.version;
    if (currentVersion !== lastestVersion) {
      if (!this.isToastCreated) {
        this.createToastUpdate();
      } else {
        // Toast already created
      }
    } else {
      // App is already on lastest version
    }
  }

  async createToastUpdate() {
    this.isToastCreated = true;
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.updater.message,
      position: 'bottom',
      buttons: [
        {
          side: 'end',
          text: this.lang.words.common.update,
          handler: () => {
            this.isToastCreated = false;
            document.location.reload();
          }
        }, {
          icon:'close',
          role: 'cancel',
          handler: () => {
            this.isToastCreated = false;
          }
        }
      ]
    });
    await toast.present();
  }
}
