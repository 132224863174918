import { SafeUrl } from '@angular/platform-browser';
import { AacPreferences } from './aac-preferences.model';

export class AacBoard {
    // tslint:disable-next-line: variable-name
    _id = '';
    parentId? = ''
    op? = '';
    owner = '';
    users = [];
    sync = false;
    img = '';
    imgSearchText: string;
    title = '';
    link: string;
    audio: SafeUrl = '';
    color = '';
    cards = [];
    cardsOrder? = [];
    isCategory = false;
    isVisible = true;
    preferences: AacPreferences;
    createdAt: number;
    updatedAt: number;

    constructor(obj = null) {
        Object.assign(this, obj);
    }
}
