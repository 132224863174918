import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-group-info-user-more-popover',
  templateUrl: './group-info-user-more-popover.component.html',
  styleUrls: ['./group-info-user-more-popover.component.scss'],
})
export class GroupInfoUserMorePopoverComponent implements OnInit {

  @Input() userName = '';

  constructor(private popoverController: PopoverController, public lang: LanguageService) { }

  ngOnInit() {}

  onRemoveBtnClicked() {
    this.popoverController.dismiss({ action: 'remove' });
  }
}
