import { Plan } from './plan.model';

export class PlanFeatures {
    canAccessAacWeb = true;
    canEditAacWeb = true;
    canCreateAacWeb = true;
    canAccessActivitiesWeb = true;
    canEditActivitiesWeb = true;
    canCreateActivitiesWeb = true;

    canAccessAacApp = true;
    canEditAacApp = true;
    canCreateAacApp = true;
    canAccessActivitiesApp = true;
    canEditActivitiesApp = true;
    canCreateActivitiesApp = true;

    constructor(planType = '') {
        this.updateFeatures(planType);
    }

    /**
     * Creates a plan features corresponding to a plan giving by the subscription id
     *
     * @param subsId Subscription id. It has to do with the plan that the user is subscribed to.
     */
    static fromSubscriptionId(subsId: string): PlanFeatures {
        const planType = Plan.subscriptionIdToPlanType(subsId);
        return new PlanFeatures(planType);
    }

    /**
     * Sets the proper features for a giving plan type
     *
     * @param planType Type of the plan that we want the features
     */
    updateFeatures(planType: string) {
        switch (planType) {
            case 'activities': {
                this.canAccessAacWeb = true;
                this.canEditAacWeb = true;
                this.canCreateAacWeb = true;
                this.canAccessActivitiesWeb = true;
                this.canEditActivitiesWeb = true;
                this.canCreateActivitiesWeb = true;

                this.canAccessAacApp = true;
                this.canEditAacApp = true;
                this.canCreateAacApp = true;
                this.canAccessActivitiesApp = true;
                this.canEditActivitiesApp = true;
                this.canCreateActivitiesApp = true;
                break;
            }
            case 'aac': {
                this.canAccessAacWeb = true;
                this.canEditAacWeb = true;
                this.canCreateAacWeb = true;
                this.canAccessActivitiesWeb = false;
                this.canEditActivitiesWeb = true;
                this.canCreateActivitiesWeb = true;

                this.canAccessAacApp = true;
                this.canEditAacApp = true;
                this.canCreateAacApp = true;
                this.canAccessActivitiesApp = false;
                this.canEditActivitiesApp = true;
                this.canCreateActivitiesApp = true;
                break;
            }
            /**
             * A read-only plan may the useful when the student/patient is not allowed to change or create any contents in the app.
             * Hence, with this plan, the user can only playback the contents.
             */
            case 'read_only': {
                this.canAccessAacWeb = true;
                this.canEditAacWeb = false;
                this.canCreateAacWeb = false;
                this.canAccessActivitiesWeb = true;
                this.canEditActivitiesWeb = false;
                this.canCreateActivitiesWeb = false;

                this.canAccessAacApp = true;
                this.canEditAacApp = false;
                this.canCreateAacApp = false;
                this.canAccessActivitiesApp = true;
                this.canEditActivitiesApp = false;
                this.canCreateActivitiesApp = false;
                break;
            }
            // Free plan
            default: {
                this.canAccessAacWeb = false;
                this.canEditAacWeb = true;
                this.canCreateAacWeb = true;
                this.canAccessActivitiesWeb = false;
                this.canEditActivitiesWeb = true;
                this.canCreateActivitiesWeb = true;

                this.canAccessAacApp = true;
                this.canEditAacApp = true;
                this.canCreateAacApp = true;
                this.canAccessActivitiesApp = false;
                this.canEditActivitiesApp = true;
                this.canCreateActivitiesApp = true;
                break;
            }
        }

    }

    /**
     * Creates a plan with the features for native app
     */
    createPlanForApp(): Plan {
        const plan = new Plan();
        plan.canAccessAac = this.canAccessAacApp;
        plan.canEditAac = this.canEditAacApp;
        plan.canCreateAac = this.canCreateAacApp;
        plan.canAccessActivities = this.canAccessActivitiesApp;
        plan.canEditActivities = this.canEditActivitiesApp;
        plan.canCreateActivities = this.canCreateActivitiesApp;
        return plan;
    }

    /**
     * Creates a plan with the features for web
     */
    createPlanForWeb(): Plan {
        const plan = new Plan();
        plan.canAccessAac = this.canAccessAacWeb;
        plan.canEditAac = this.canEditAacWeb;
        plan.canCreateAac = this.canCreateAacWeb;
        plan.canAccessActivities = this.canAccessActivitiesWeb;
        plan.canEditActivities = this.canEditActivitiesWeb;
        plan.canCreateActivities = this.canCreateActivitiesWeb;
        return plan;
    }

    /**
     * Returns true if the plan features includes the aac module
     */
    includesAacModule() {
        return this.canAccessAacApp && this.canAccessAacWeb;
    }

    /**
     * Returns true if the plan features includes the Activities module
     */
    includesActivitiesModule() {
        return this.canAccessActivitiesApp && this.canAccessActivitiesWeb;
    }
}
