import { SafeUrl } from '@angular/platform-browser'

export class AudioRecord {
    audioUrl: SafeUrl;
    audioBlob: Blob;

    constructor(audioUrl: SafeUrl, audioBlob: Blob) {
        this.audioUrl = audioUrl;
        this.audioBlob = audioBlob;
    }
}
