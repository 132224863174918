import { WalkthroughLog } from './walkthrough.model';
import { Globals } from 'src/app/globals/globals';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '../language/language.service';
import { Tooltip } from 'src/app/tooltip-pro';

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {

  pageChangesBS: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(public lang: LanguageService, private tooltip: Tooltip) { }

  setPage(page: string) {
    this.pageChangesBS.next(page);
  }

  daysOfTrialLeftControler(log: WalkthroughLog, app: Globals) {
    var date = new Date();
    // A cada 36 horas a partir de 4 dias restantes
    return ((app.isUserOnTrial() && (date.getTime() - log.timestamp > 36*60000) && (app.daysLeftOnTrial() <= 4)));
  }

  daysOfTrialLeftText(app: Globals) {
    var text = this.lang.words.walkthrough.freeTrialDaysRemaining; // TODO: traduzir
    return text.replace('<placeholder>', app.daysLeftOnTrial().toFixed(0));
  }

  loginWithFacebookController(log: WalkthroughLog, app: Globals) {
    return (true);
  }

  dismiss() {
    if (this.tooltip) {
      this.tooltip.close();
    }
  }
}
