import { Injectable } from '@angular/core';
import { DatabaseService } from '../../database/database.service';
import { Globals } from 'src/app/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class ExpressiaAnalyticsService {

  constructor(private database: DatabaseService, private app: Globals) { }

  logEvent(eventName: string , eventParams?: any, paramName?: string) {
    this.database.logEvent(eventName, eventParams, paramName);
    this.recordUserStatistics(eventName);
  }

  recordUserStatistics(eventName: string) {
    let updates = null;
    switch(eventName) {
      case 'aac_board_store_add': { updates = { statistics: { aacBoardStoreAdd: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_board_create': { updates = { statistics: { aacBoardsCreated: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_card_create': { updates = { statistics: { aacCardsCreated: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'aacLockAccepted': { updates = { statistics: { aacLockAccepted: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'aacLockDisplayed': { updates = { statistics: { aacLockDisplayed: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_sentence_bar_play': { updates = { statistics: { aacSentencesSpoken: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_shared_board_save': { updates = { statistics: { aacSharedSaved: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_card_create': { updates = { statistics: { activitiesCardsCreated: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_end': { updates = { statistics: { activitiesConcluded: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_create': { updates = { statistics: { activitiesCreated: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressiacase 'activitiesLockAccepted': { updates = { statistics: { activitiesLockAccepted: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'activitiesLockDisplayed': { updates = { statistics: { activitiesLockDisplayed: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'activitiesReceived': { updates = { statistics: { activitiesReceived: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_link_share': { updates = { statistics: { activitiesShared: this.app.firebaseIncrement(1) }}; break; }
      // // Evento não implementado no analytics do Expressia case 'activitiesSharedPlayed': { updates = { statistics: { activitiesSharedPlayed: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_shared_save': { updates = { statistics: { activitiesSharedSaved: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_start': { updates = { statistics: { activitiesStarted: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_store_add': { updates = { statistics: { activityStoreAdd: this.app.firebaseIncrement(1) }}; break; }

    }

    if (updates) {
      this.database.updateUser(updates);
    }
  }
}
