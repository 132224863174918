import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { ActivityBoard } from 'src/app/activities/activity-board/activity-board.model';
import { DatabaseService } from 'src/app/database/database.service';
import { ImageCropV2Component } from 'src/app/editor-tools/image-crop-v2/image-crop-v2.component';
import { ImageSearchV2Component } from 'src/app/editor-tools/image-search-v2/image-search-v2.component';
import { ImageSourceMorePopoverComponent } from 'src/app/editor-tools/image-source-more-popover/image-source-more-popover.component';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';
import { UserContact } from 'src/app/user/user-contacts/user-contact/user-contact.model';
import { GroupsService } from '../groups.service';

@Component({
  selector: 'app-group-add-subject',
  templateUrl: './group-add-subject.component.html',
  styleUrls: ['./group-add-subject.component.scss'],
})
export class GroupAddSubjectComponent implements OnInit {

  @ViewChild('groupNameInput', { read: ElementRef }) groupNameInputRef: ElementRef;

  @Input() group: ActivityBoard;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() previousModal;
  @Input() participants: UserContact[];
  @Input() groupImg = '';
  @Input() groupName = '';
  @Input() action = 'new-group';
  theme = 'activities';
  groupImgSrc = '';

  constructor(private app: Globals, private modalController: ModalController, private toastController: ToastController,
              private loadingController: LoadingController, private alertController: AlertController, public lang: LanguageService,
              private groupsService: GroupsService, private database: DatabaseService, private popoverController: PopoverController,
              private mediaStorageService: MediaStorageService) { }

  ngOnInit() {
    if (this.title === '') {
      this.title = this.lang.words.groups.newGroup;
      this.subtitle = this.lang.words.groups.addName;
    }

    this.groupImgSrc =  this.mediaStorageService.resolveImageSrc(this.groupImg);
  }

  onBackBtnClicked(ev: any) {
    this.modalController.dismiss();
  }

  async onCreateGroupBtnClicked(groupName: string) {
    if (groupName) {
      await this.saveGroupImg();

      if (this.action === 'new-group') {
        const loading = await this.loadingController.create({
          cssClass: 'app-standard-loading',
          message: this.lang.words.loadings.createGroup.message,
          spinner: 'dots',
          animated: false
        });

        const membersUid = this.participants.map(user => user.uid);
        membersUid.push(this.app.user.uid);

        const membersInfo = this.participants.map(({uid, name, photo}) => ({uid, name, photo}));
        membersInfo.push({uid: this.app.user.uid, name: this.app.user.name, photo: this.app.user.profile.photo });

        this.groupsService.createGroup(groupName, this.groupImg, this.app.user.uid, membersUid, membersInfo).then(group => {
          this.previousModal.animated = false;
          this.previousModal.dismiss({ action: 'group-created', group: group });
          this.loadingController.dismiss();
          this.modalController.dismiss();
        }).catch(() => {
          this.loadingController.dismiss();
          this.showCreateGroupErrorAlert();
        });

        await loading.present();
      } else {
        this.database.updateActivityBoardProps(this.group, { title: groupName, img: this.groupImg });
        this.modalController.dismiss();
      }
    } else {
      this.showAddGroupNameToast();
    }
  }

  async showAddGroupNameToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.typeGroupName.message,
      cssClass: 'app-toast',
      duration: 3000
    });
    toast.present();
  }

  async showCreateGroupErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.createGroupError.header,
      subHeader: this.lang.words.alerts.createGroupError.subHeader,
      message: this.lang.words.alerts.createGroupError.message,
      buttons: [this.lang.words.common.ok]
    });
    await alert.present();
  }

  async onGroupImgClicked(ev: any) {
    const popover = await this.popoverController.create({
      component: ImageSourceMorePopoverComponent,
      event: ev,
      translucent: true,
      cssClass: 'app-popover-lg',
      componentProps: {
        canDeleteImg: this.groupImg ? true : false
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data && data.action === 'load-image-from-device') {
      this.openImageCrop(data);
    } else if (data && data.action === 'search-image') {
      this.openImageSearch();
    } else if (data && data.action === 'delete-image') {
      this.groupImgSrc = '';
      this.groupImg = '';
    }
  }

  async openImageSearch() {
    const modal = await this.modalController.create({
      component: ImageSearchV2Component,
      backdropDismiss: false,
      showBackdrop: false
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.groupImgSrc = JSON.stringify(data);
    }
  }

  async openImageCrop(ev: any) {
    const modal = await this.modalController.create({
      component: ImageCropV2Component,
      componentProps: {
        imgSrc: ev.data.imageSrc,
        imgType: ev.data.imageType
      },
      backdropDismiss: false,
      showBackdrop: false
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.groupImgSrc = JSON.stringify(data);
    }
  }

  async saveGroupImg() {
    if (this.groupImgSrc.includes('imageCroppedEvent')) {
      await this.mediaStorageService.saveImage(JSON.parse(this.groupImgSrc))
        .then(saveImageName => {
          this.groupImg = saveImageName;
        })
        // TODO: Handle error
        .catch(err => console.log(err));
    }

    if (this.group && this.groupImg !== this.group.img) {
      this.groupsService.deleteGroupImg(this.group.img);
    }
  }
}
